import type { GalleryFolder } from 'lib/types';
import type {
  TrackingContext,
  UserPresentationRole,
} from '@mentimeter/http-clients';
import type { useRouter } from '@mentimeter/next-navigation';
import type { UserT } from '@mentimeter/user';
import { createContext, useContext } from 'react';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import type { TrackingPlacementT } from '../presentation-view/constants';

export interface ContextMenuCommonProps {
  user: UserT | null;
  trackingContext: TrackingContext;
  trackingPlacement?: TrackingPlacementT;
  planKey?: string | undefined;
}

export interface ContextMenuProps extends ContextMenuCommonProps {
  item: SlideDeckOverview | GalleryFolder;
}

export interface PresentationContextMenuProps extends ContextMenuProps {
  item: SlideDeckOverview;
}

export interface FolderContextMenuProps extends ContextMenuProps {
  item: GalleryFolder;
}

interface ContextMenuContextProps extends ContextMenuCommonProps {
  userPresentationRole: UserPresentationRole;
  router: ReturnType<typeof useRouter>;
}

export const UseContextMenuContext = createContext<
  ContextMenuContextProps | undefined
>(undefined);

export function useContextMenuContext(): ContextMenuContextProps {
  const context = useContext(UseContextMenuContext);

  if (context === undefined) {
    throw new Error(
      'useContextMenuContext can only be called from a component that is a descendant of ContextMenu.',
    );
  }

  return context;
}
