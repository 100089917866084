import { MentiError, captureException } from '@mentimeter/errors/sentry';
import type {
  InviteContinue,
  InviteLanding,
  SendInvitationsResponseT,
  WorkspaceRoleEnum,
} from '@mentimeter/http-clients';
import { InvitationTypeEnum, core } from '@mentimeter/http-clients';
import useSWRMutation from 'swr/mutation';

interface SubmitInviteData {
  checkedGroups?: Array<string>;
  selectedEmails: Array<string>;
  role: WorkspaceRoleEnum | null;
  seriesId?: string;
  customizedLanding?: InviteLanding | undefined;
  customizedContinue?: InviteContinue | undefined;
  workspaceId: number | undefined;
}

const SUBMIT_INVITES_CACHE_KEY = '/submit-invites';

interface SubmitInvitesParams {
  onError: (error: unknown) => void;
  onSuccess: (data: SendInvitationsResponseT) => void;
}

type SubmitInvitesTrigger = (params: SubmitInviteData) => void;

export function useSubmitInvites({ onError, onSuccess }: SubmitInvitesParams) {
  const { data, isMutating, error, trigger } = useSWRMutation(
    SUBMIT_INVITES_CACHE_KEY,
    submitInvitesMutator,
    {
      onSuccess,
      onError: (error) => {
        captureException(
          new MentiError('Invite member to workspace via email', {
            feature: 'managing-organizations',
            cause: error,
          }),
        );
        onError(error);
      },
    },
  );

  if (isMutating) {
    return {
      data,
      isLoading: true,
      error: null,
      submitInvites: trigger as SubmitInvitesTrigger,
    };
  }

  if (error || !data) {
    return {
      data,
      isLoading: false,
      error,
      submitInvites: trigger as SubmitInvitesTrigger,
    };
  }

  return {
    data,
    isLoading: isMutating,
    error,
    submitInvites: trigger as SubmitInvitesTrigger,
  };
}

const submitInvitesMutator = async (
  _key: string,
  {
    arg: {
      workspaceId,
      checkedGroups,
      selectedEmails,
      role,
      seriesId,
      customizedLanding,
      customizedContinue,
    },
  }: { arg: SubmitInviteData },
) => {
  const response = await core().workspaces.pendingMemberships.post(
    workspaceId,
    {
      type: InvitationTypeEnum.INVITED,
      emails: selectedEmails,
      role,
      series_id: seriesId ?? null,
      continue: customizedContinue ?? null,
      landing: customizedLanding ?? null,
      ...(checkedGroups && { group_ids: checkedGroups }),
    },
  );

  return response.data;
};
