import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import type { Pictogram } from '../types';
import { Svg } from '../../svg';
import { COLOR_MAP } from '../colorMap';

export const Piece = ({ userColor, variant = 'coral' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 200 200" aria-hidden="true" className="light">
      <path
        d="M146.027 61.9648C146.995 60.2261 147.546 58.224 147.546 56.0932C147.546 49.4143 142.131 44 135.452 44C128.774 44 123.359 49.4143 123.359 56.0932C123.359 58.224 123.91 60.2261 124.878 61.9648H100.016V138.308H171.521V61.9648H146.027Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.6)
            : COLOR_MAP[variant].pictogramSecondary
        }
      />
      <path
        d="M29.0469 61.9629H100.552V92.2152C101.717 91.6727 102.852 91.0235 103.973 90.3828C104.296 90.1979 104.618 90.0138 104.939 89.8331C106.689 88.8486 108.709 88.2871 110.86 88.2871C117.539 88.2871 122.953 93.7014 122.953 100.38C122.953 107.059 117.539 112.474 110.86 112.474C108.533 112.474 106.359 111.816 104.514 110.677C104.285 110.535 104.056 110.392 103.825 110.249C102.756 109.583 101.67 108.907 100.552 108.326V138.306H75.641C76.8275 140.177 77.5145 142.396 77.5145 144.775C77.5145 151.454 72.1002 156.868 65.4213 156.868C58.7424 156.868 53.3281 151.454 53.3281 144.775C53.3281 142.396 54.0151 140.177 55.2016 138.306H29.0469V61.9629Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramTertiary}
      />
    </Svg>
  );
};
