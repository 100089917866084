import React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { IconButton } from '@mentimeter/ragnar-ui/icon-button';
import { Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { useButtonState } from '@mentimeter/ragnar-ui/button';

export const RemoveCollaboratorCross = ({
  collaboratorUserId,
  collaboratorDisplayName,
  onRemoveEditAccess,
  isInvitee,
}: {
  collaboratorUserId: string;
  collaboratorDisplayName: string;
  onRemoveEditAccess: () => Promise<void>;
  isInvitee: boolean;
}) => {
  const [buttonState, setButtonState] = useButtonState();
  const label = isInvitee
    ? "This user doesn't have an account yet. Remove invite."
    : 'Remove access';

  const ariaLabel = isInvitee
    ? `This user doesn't have an account yet. Remove invite for ${collaboratorDisplayName}.`
    : `Remove access for ${collaboratorDisplayName}`;

  return (
    <Tooltip
      side="right"
      id={`remove${collaboratorUserId}`}
      trigger={
        <IconButton
          variant="secondary"
          borderRadius="full"
          size="compact"
          state={buttonState}
          id={`remove${collaboratorUserId}`}
          data-testid={`remove-access-${collaboratorUserId}`}
          aria-label={ariaLabel}
          onClick={async () => {
            setButtonState('loading');
            await onRemoveEditAccess();
            setButtonState(undefined);
          }}
        >
          <CrossIcon size={2} color="textWeak" />
        </IconButton>
      }
    >
      {label}
    </Tooltip>
  );
};
