/* eslint-disable menti-react/filename-convention--jsx */
import { trackUser } from '@api/tracking/client';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import { isTemplate } from '@mentimeter/core-hooks';
import { core, TrackingContext } from '@mentimeter/http-clients';
import { useRouter } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { Form, TextInput } from '@mentimeter/ragnar-form';
import { useToast } from '@mentimeter/toast';
import { NAME_MAX_CHAR } from 'features/dashboard/presentation-view/constants';
import { useCallback, useMemo } from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { P } from '@mentimeter/ragnar-ui/typography';
import { useSeriesCache } from './use-series-cache';

export interface ShareWithOrgModalProps {
  id: string;
  name: string;
  templateId: null | string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface HandleShareT {
  newName: string;
}

interface IsSharedModalT {
  selectedItems: Array<SlideDeckOverview>;
  context: 'edit' | 'delete';
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
}

export const ModalContentPresentationIsShared = ({
  selectedItems,
  context,
  setOpenModal,
  openModal,
}: IsSharedModalT) => {
  const router = useRouter();
  const amountOfSharedItems = selectedItems.filter((d) => isTemplate(d)).length;
  const [item] = selectedItems;

  const goToEditView = useCallback(async () => {
    if (!item) return {};

    await router.push(
      buildPresentationPath({
        seriesId: item.slideDeckPublicKey,
        mode: 'edit',
      }),
    );

    return null;
  }, [router, item]);

  const hasMoreThanOneShared = amountOfSharedItems > 1;
  const itemName = item?.name || 'this presentation';
  const props = useMemo(
    () => ({
      delete: {
        title: `Cannot delete shared presentation${
          hasMoreThanOneShared ? 's' : ''
        }`,
        content: hasMoreThanOneShared
          ? `You have shared ${amountOfSharedItems} presentations as templates in your
          organization. To delete them, you need to first unshare them.`
          : `You have shared "${itemName}" as a template in your
          organization. To delete it, you need to first unshare it. `,
        actions: [
          {
            children: 'Got it!',
            key: 'cancel',
            onClick: () => setOpenModal(false),
          },
        ],
      },
      edit: {
        title: `Edit shared presentation?`,
        content: `You have shared "${itemName}" as a template in your
          organization. Any changes you make will also update the shared
          template.`,
        actions: [
          {
            children: 'Cancel',
            key: 'cancel',
            onClick: () => setOpenModal(false),
          },
          {
            children: 'Edit presentation',
            key: 'edit',
            onClick: async () => {
              await goToEditView();
            },
          },
        ],
      },
    }),
    [
      amountOfSharedItems,
      goToEditView,
      itemName,
      setOpenModal,
      hasMoreThanOneShared,
    ],
  );
  const {
    [context]: { title, actions, content },
  } = props;
  return (
    <ModalRoot open={openModal} onOpenChange={setOpenModal}>
      <ModalContainer>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          {actions.map((action) => (
            <Button
              key={action.key}
              onClick={action.onClick}
              variant={action.children === 'Cancel' ? 'secondary' : 'primary'}
            >
              {action.children}
            </Button>
          ))}
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export const ModalContentShare = ({
  id,
  templateId,
  name,
  open,
  setOpen,
}: ShareWithOrgModalProps) => {
  const toast = useToast();
  const { invalidate: invalidateSeriesCache } = useSeriesCache();
  const handleShare = async ({ newName }: HandleShareT) => {
    await invalidateSeriesCache();
    // @ts-expect-error-auto TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
    const handleSuccess = async ({ data }) => {
      trackUser({
        event: 'Shared template',
        properties: {
          'to group id': null,
          'share with workspace': true,
          'Template ID': data.id,
          Template: data.description,
          context: TrackingContext.MyPresentations,
        },
      });
      await invalidateSeriesCache();
      toast.displayToast({
        description: `Shared "${name}"`,
        autoDismiss: true,
      });
    };
    const handleError = () => {
      toast.displayToast({
        description: `Could not share "${name}"`,
        autoDismiss: true,
        variant: 'negative',
      });
    };
    if (!templateId) {
      core()
        .templates.post({
          description: newName,
          shared_with_organization: true,
          series: id,
        })
        .then(handleSuccess)
        .catch(handleError);
    } else {
      core()
        .templates.put(templateId, {
          description: newName,
          shared_with_organization: true,
          series: id,
        })
        .then(handleSuccess)
        .catch(handleError);
    }

    setOpen(false);
  };

  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer>
        <Form
          validate={(values) => {
            const errors = {};
            if (values.newName.length <= 0) {
              // @ts-expect-error-auto TS(2339) FIXME: Property 'newName' does not exist on type '{}'.
              errors.newName = 'Enter a descriptive name';
            }
            return errors;
          }}
          validateOnBlur={false}
          initialValues={{ newName: name }}
          onSubmit={handleShare}
        >
          <ModalHeader>Share with organization</ModalHeader>
          <ModalBody>
            <P>
              &quot;{name}&quot; can be used as a template for new presentations
              by any member of your organization. Results will not be shared.
            </P>
            <TextInput
              width="100%"
              autoFocus
              autoComplete="off"
              maxLength={NAME_MAX_CHAR}
              label="Name this template"
              hintText="Give the template a descriptive name so that others know what it can be used for."
              name="newName"
              placeholder="Name"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setOpen(false)} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Share
            </Button>
          </ModalFooter>
        </Form>
      </ModalContainer>
    </ModalRoot>
  );
};
