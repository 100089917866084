import { usePathname, useRouter } from '@mentimeter/next-navigation';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';
import {
  PopoverBody,
  PopoverContainer,
  PopoverTrigger,
  PopoverRoot,
} from '@mentimeter/ragnar-ui/popover';
import { Link } from '@mentimeter/ragnar-ui/link';

export const RemoveFolderCollaboratorHint = ({
  tooltipText,
  collaboratorUserId,
  resourceIdToRemove,
}: {
  tooltipText: string;
  collaboratorUserId: string;
  resourceIdToRemove: string;
}) => {
  const router = useRouter();
  const pathname = usePathname();

  const folderHref = pathname.includes('shared-with-me')
    ? `/app/shared-with-me/folder/${resourceIdToRemove}?open-share-modal=true&redirect=true`
    : `/app/folder/${resourceIdToRemove}?open-share-modal=true`;
  return (
    <PopoverRoot>
      <PopoverTrigger>
        <Clickable
          id={`remove${collaboratorUserId}`}
          type="button"
          aria-label="Remove access"
        >
          <CrossIcon size={3} color="textDisabled" />
        </Clickable>
      </PopoverTrigger>
      <PopoverContainer>
        <PopoverBody width="450px">
          <Text mb={2}>{tooltipText}</Text>
          <Text>
            <Link onClick={() => router.push(folderHref)} href={folderHref}>
              Go to top-level folder
            </Link>{' '}
            to remove access to all its contents.
          </Text>
        </PopoverBody>
      </PopoverContainer>
    </PopoverRoot>
  );
};
