import type { TrackingContext } from '@mentimeter/http-clients';
import {
  TrackingPlacement,
  UserFeaturesTypeEnum,
} from '@mentimeter/http-clients';
import type { ReactNode } from 'react';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { RequestUpgradePopover } from '../../lib/request-upgrade';
import { useFeatureAccess } from '../../lib/hooks/use-feature-access';

export function ShareSeriesPaywallNotificationDataHandler({
  trackingContext,
  children,
}: {
  trackingContext: TrackingContext;
  children: ReactNode;
}) {
  const access = useFeatureAccess(UserFeaturesTypeEnum.PRO);

  if (access === 'not-in-role') {
    return (
      <RequestUpgradePopover
        title="Want to invite others to edit this Menti?"
        description="Your role needs to be upgraded by an admin."
        trackingContext={trackingContext}
        trackingPlacement={TrackingPlacement.CollaborationTab}
      >
        <CalloutCard
          title="Want to invite others to edit this Menti?"
          description="Your role needs to be upgraded by an admin."
          theme="positive"
          my={3}
          actions={[{ children: 'Request upgrade', key: 'request-upgrade' }]}
        />
      </RequestUpgradePopover>
    );
  } else {
    return children;
  }
}
