// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostResourceSharingsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The type of the entity being shared to */
  entityType: 'user';
  /** The type of the resource being shared */
  resourceType: 'folder' | 'series';
  /** The id of the entity being shared to */
  entityIdentifiers: Array<string>;
  /** The id of the resource being shared */
  resourceId: string;
  /** A message to include with the share email */
  message?: string | null;
}

export function postResourceSharingsRequest(
  requestParams: PostResourceSharingsRequest,
): Request {
  const data = {
    entity_identifiers: toSnakeKeys(requestParams.entityIdentifiers),
    resource_id: toSnakeKeys(requestParams.resourceId),
    message: toSnakeKeys(requestParams.message),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/resource-sharings/${requestParams.entityType}/${requestParams.resourceType}`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
