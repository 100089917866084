import type {
  TrackingContext,
  UserPresentationRole,
} from '@mentimeter/http-clients';
import React, { useEffect, type PropsWithChildren } from 'react';
import { MoreIcon } from '@mentimeter/ragnar-visuals';
import { useRouter } from '@mentimeter/next-navigation';
import { useMatch } from '@mentimeter/ragnar-device';
import { trackUser } from '@api/tracking/client';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import type {
  DropdownMenuItemProps,
  DropdownMenuSeparatorProps,
} from '@mentimeter/ragnar-ui/menu';
import {
  DropdownMenuContainer,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';
import { isSlideDeck } from '../presentation-view/types';
import { TRACKING_PLACEMENT } from '../presentation-view/constants';
import { MobileContextMenu } from './mobile-compatibility-layer/MobileContextMenu';
import {
  type ContextMenuProps,
  UseContextMenuContext,
} from './useContextMenuContext';
import { MobileMenuItem } from './mobile-compatibility-layer/MobileMenuItem';

const trackOpened = (trackingContext: TrackingContext, itemId: string) => {
  return trackUser({
    event: 'Opened dashboard row menu',
    properties: {
      context: trackingContext,
      'series id': itemId,
    },
  });
};
export function ContextMenu({
  item,
  user,
  planKey,
  referenceId,
  trackingContext,
  trackingPlacement = TRACKING_PLACEMENT.ACTION_MENU,
  children,
}: PropsWithChildren<ContextMenuProps> & {
  referenceId?: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isItemOwner = item.ownerId === user?.id;
  const userPresentationRole: UserPresentationRole = isItemOwner
    ? 'Owner'
    : 'Collaborator';
  const router = useRouter();

  const id = isSlideDeck(item) ? item.slideDeckPublicKey : item.id;

  useEffect(() => {
    if (isOpen) {
      trackOpened(trackingContext, id);
    }
  }, [isOpen, id, trackingContext]);

  const isMobile = useMatch({ lessThan: 2 });
  if (isMobile) {
    return (
      <MobileContextMenu
        trackingContext={trackingContext}
        trackingPlacement={trackingPlacement}
        item={item}
        user={user}
      >
        {children}
      </MobileContextMenu>
    );
  }

  return (
    <DropdownMenuRoot onOpenChange={setIsOpen}>
      <DropdownMenuTrigger>
        <Clickable
          aria-label={`show action menu for presentation ${item.name}`}
        >
          <MoreIcon size={4} />
        </Clickable>
      </DropdownMenuTrigger>
      <UseContextMenuContext.Provider
        value={{
          user,
          userPresentationRole,
          router,
          trackingContext,
          trackingPlacement,
          planKey,
        }}
      >
        <DropdownMenuContainer id={referenceId} maxWidth="300px" side="left">
          {children}
        </DropdownMenuContainer>
      </UseContextMenuContext.Provider>
    </DropdownMenuRoot>
  );
}

function Label(props: TextT) {
  return <Text color="inherit" {...props} />;
}
function Icon(props: BoxT) {
  return <Box mr={2} {...props} />;
}

type ItemProps = DropdownMenuItemProps;

const Item = React.forwardRef((props: ItemProps, ref) => {
  const isMobile = useMatch({ lessThan: 2 });
  if (isMobile) {
    return <MobileMenuItem {...props} ref={ref} />;
  }
  return <DropdownMenuItem {...props} ref={ref} />;
});

function Separator(props: DropdownMenuSeparatorProps) {
  const isMobile = useMatch({ lessThan: 2 });
  if (isMobile) {
    return <DropdownMenuSeparator {...props} my={1} />;
  }
  return <DropdownMenuSeparator {...props} />;
}

ContextMenu.Item = Item;
ContextMenu.ItemLabel = Label;
ContextMenu.ItemIcon = Icon;
ContextMenu.Separator = Separator;
