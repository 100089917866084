import type { TrackingContext } from '@mentimeter/http-clients';
import {
  ResultsSharingEnum,
  TrackingPlacement,
} from '@mentimeter/http-clients';
import { CheckIcon, ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { ShareModalResultsAccessPaywallDataHandler } from '@mentimeter/paywalls/share-modal-results-access';
import type {
  PaywallSeriesTrackingProperties,
  UpgradeEvent,
} from '@mentimeter/paywalls';
import { useMatch } from '@mentimeter/ragnar-device';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { P, Strong } from '@mentimeter/ragnar-ui/typography';
import {
  DropdownMenuRoot,
  DropdownMenuTrigger,
  DropdownMenuContainer,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuItemIcon,
} from '@mentimeter/ragnar-ui/menu';
import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';

const ResultsSharingMessage: Record<ResultsSharingEnum, string> = {
  on: 'All participants can access',
  open: 'Anyone with this link can access',
  off: 'Only collaborators can access this link',
} as const;

export interface PresentationAccessMenuProps {
  privateResultsEnabled: boolean;
  resultsSharing: ResultsSharingEnum | undefined;
  showTeamRestrictionHint: boolean;
  disableShareResults: boolean;
  onResultsSharingChange: (args: {
    resultsSharing: 'on' | 'open' | 'off';
  }) => Promise<unknown>;
  trackToggledPresentationPrivacy: (resultsSharing: ResultsSharingEnum) => void;
  trackingContext: TrackingContext;
  trackingProperties: PaywallSeriesTrackingProperties;
  upgradeEvent: UpgradeEvent;
}

const ActionToolTip = ({
  description,
  referenceId,
}: {
  description: string;
  referenceId: string;
}) => {
  const isMobile = useMatch({ lessThan: 2 });
  const placement = isMobile ? 'top' : 'left';
  return (
    <TooltipOld
      description={description}
      referenceId={referenceId}
      key={referenceId}
      placement={placement}
    />
  );
};

const menuItems = [
  {
    key: 'presentation-access-menu-on',
    value: ResultsSharingEnum.ON,
    message: ResultsSharingMessage.on,
  },
  {
    key: 'presentation-access-menu-open',
    value: ResultsSharingEnum.OPEN,
    message: ResultsSharingMessage.open,
  },
  {
    key: 'presentation-access-menu-off',
    value: ResultsSharingEnum.OFF,
    message: ResultsSharingMessage.off,
  },
];

export function PresentationAccessMenu({
  privateResultsEnabled,
  resultsSharing,
  showTeamRestrictionHint,
  disableShareResults,
  onResultsSharingChange,
  trackToggledPresentationPrivacy,
  trackingContext,
  trackingProperties,
  upgradeEvent,
}: PresentationAccessMenuProps) {
  const resultsSharingMessage = resultsSharing
    ? ResultsSharingMessage[resultsSharing]
    : undefined;

  if (!privateResultsEnabled) {
    return (
      <ShareModalResultsAccessPaywallDataHandler
        trackingContext={trackingContext}
        trackingPlacement={TrackingPlacement.ResultsTab}
        trackingProperties={trackingProperties}
        upgradeEvent={upgradeEvent}
        upgradeContext="Presentation privacy"
      >
        <Clickable
          id="presentation-access-menu-paywall"
          aria-label="Presentation access menu paywall"
          flexDirection="row"
          alignItems="center"
        >
          <P color="primary" mb={0} mr={1} textAlign="left">
            {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
            <Strong color="inherit">{resultsSharingMessage}</Strong>
          </P>
          <Badge compact type="upgrade" />
        </Clickable>
      </ShareModalResultsAccessPaywallDataHandler>
    );
  }

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <Clickable
          id="presentation-access-menu"
          aria-label="Presentation access menu"
          flexDirection="row"
          alignItems="center"
        >
          <P color="primary" mb={0} mr={1} textAlign="left">
            {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
            <Strong color="inherit">{resultsSharingMessage}</Strong>
          </P>
          <ChevronDownIcon color="primary" />
        </Clickable>
      </DropdownMenuTrigger>
      <DropdownMenuContainer>
        <DropdownMenuRadioGroup value={resultsSharing || ResultsSharingEnum.ON}>
          {menuItems.map((item) => (
            <DropdownMenuRadioItem
              key={item.key}
              value={item.value}
              id={item.key}
              aria-label={item.message}
              aria-selected={resultsSharing === item.value}
              disabled={disableShareResults}
              onSelect={() => {
                trackToggledPresentationPrivacy(item.value);
                onResultsSharingChange({
                  resultsSharing: item.value,
                });
              }}
            >
              <DropdownMenuItemIcon>
                {resultsSharing === item.value ? (
                  <CheckIcon size={2} color="currentColor" />
                ) : null}
              </DropdownMenuItemIcon>
              <Text truncate color="currentColor">
                {item.message}
              </Text>
              {showTeamRestrictionHint && (
                <ActionToolTip
                  description="This feature is turned off for your team by team admin."
                  referenceId={`action-${item.key}`}
                />
              )}
              {showTeamRestrictionHint && <Badge ml={2} type="admin" compact />}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContainer>
    </DropdownMenuRoot>
  );
}
