import React, { useState } from 'react';
import { LinkClickable } from '@mentimeter/next-navigation';
import {
  patchMoveRequest,
  type PatchMoveRequest,
} from '@core-api/presentation-collection/presentation-collection/{series_id}/move';
import { userCache } from '@mentimeter/user';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { useToast } from '@mentimeter/toast';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button, useButtonState } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TextEllipsis } from '@mentimeter/ragnar-ui/text-ellipsis';
import type { TrackingContext } from '../../http-clients/src/tracking/constants';
import { hasNoValidDestinations, trackMovedPresentation } from './utils';
import { useFoldersMoveInfo } from './useFoldersMoveInfo';
import { type FolderNodeType } from './types';
import { FolderTree } from './FolderTree';

export interface MoveModalProps {
  seriesId: string;
  itemToMoveName: string;
  currentLocationId: number | 'me' | 'sharedWithMe' | 'workspace';
  open: boolean;
  trackingDetails: { context: TrackingContext | 'Editor'; placement: string };
  onOpenChange: () => void;
  invalidateCache: () => void;
}

export const MoveModal = ({
  seriesId,
  itemToMoveName,
  currentLocationId,
  open,
  trackingDetails,
  onOpenChange,
  invalidateCache,
}: MoveModalProps) => {
  const [selectedDestination, setSelectedDestination] =
    useState<FolderNodeType>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [buttonState, setButtonState] = useButtonState();
  const { data: workspace } = useWorkspace();
  const toast = useToast();

  const {
    isLoading,
    folders,
    initialPath,
    error: folderInfoError,
  } = useFoldersMoveInfo(open, seriesId, String(currentLocationId));

  const handleMove = async () => {
    if (!selectedDestination) {
      onOpenChange();
      return;
    }

    setButtonState('loading');
    setErrorMessage(undefined);

    const isDashboard = ['me', 'workspace'].includes(
      selectedDestination.id.toString(),
    );

    const region = userCache.region;
    const sessionToken = userCache.getToken();
    const workspaceId = selectedDestination.path.includes('workspace')
      ? workspace?.id
      : null;
    const folderId = isDashboard ? null : (selectedDestination.id as number);

    const params: PatchMoveRequest = {
      region,
      userAuth: sessionToken,
      seriesId,
      workspaceId: workspaceId ?? null,
      folderId: folderId ?? null,
    };

    const handleError = (errorMessage: string, cause?: unknown) => {
      const err = new MentiError(errorMessage, {
        feature: 'move-modal',
        cause,
      });
      captureException(err, {
        extra: {
          region,
          seriesId,
          workspaceId: workspaceId ?? null,
          folderId: folderId ?? null,
        },
      });
      setButtonState('error');
      setErrorMessage('Something went wrong, please try again.');
    };

    let response;
    try {
      response = await fetch(patchMoveRequest(params));
    } catch (error) {
      handleError('Fetch failed when moving presentation', error);
      return;
    }

    if (!response.ok && ![401, 404].includes(response.status)) {
      handleError('Error moving presentation');
      return;
    }
    const isSharedDestination = Boolean(
      selectedDestination.path.includes('sharedWithMe') ||
        workspaceId ||
        selectedDestination.shared,
    );

    trackMovedPresentation(
      trackingDetails,
      selectedDestination.path,
      isSharedDestination,
    );

    setButtonState('success');
    invalidateCache();
    onOpenChange();

    const gotoName =
      typeof selectedDestination.id === 'number'
        ? 'folder'
        : selectedDestination.name;
    const gotoText = `Go to ${gotoName}`;
    toast.displayToast({
      variant: 'info',
      description: (
        <Box
          display="flex"
          flexDirection="row"
          alignContent="space-between"
          alignItems="center"
        >
          <Text>
            <Text fontWeight="bold">{itemToMoveName}</Text>
            <Text> was moved to </Text>
            <Text fontWeight="bold">{selectedDestination.name}</Text>
          </Text>
          <Box ml="space4" maxWidth="40%">
            <LinkClickable
              href={selectedDestination.link}
              aria-label={`Go to folder ${selectedDestination.name}`}
              data-testid={`navigate-to-folder-from-toast-${selectedDestination.id}`}
            >
              {' '}
              <TextEllipsis
                id="move-to-folder-name"
                tooltipDescription={gotoText}
                text={gotoText}
              />
            </LinkClickable>
          </Box>
        </Box>
      ),
      autoDismiss: true,
    });
  };

  const hasLoaded = !isLoading && !folderInfoError;
  const foldersHaveNoValidDestinations = hasNoValidDestinations(folders);
  const showNoValidDestination = hasLoaded && foldersHaveNoValidDestinations;
  const showValidDestination = hasLoaded && !foldersHaveNoValidDestinations;
  const showError = errorMessage || folderInfoError;
  const moveButtonDisabled =
    !selectedDestination || !!selectedDestination?.selectedMessage;

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalContainer topAligned>
        <ModalHeader>{`Move "${itemToMoveName}"`}</ModalHeader>
        <ModalBody px="space10">
          {isLoading && (
            <Box
              display="flex"
              flexDirection="column"
              gap="space4"
              width="100%"
            >
              <Skeleton variant="text" width="45%" />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="45%" />
            </Box>
          )}
          {showValidDestination && (
            <FolderTree
              onSelect={(newDestination: FolderNodeType) =>
                setSelectedDestination(newDestination)
              }
              folders={folders}
              currentLocationId={String(currentLocationId)}
              initialPath={initialPath}
            />
          )}
          {showNoValidDestination && (
            <CalloutCard theme="info">
              There&apos;s nowhere to move{' '}
              <Text fontWeight="bold">{itemToMoveName}</Text>.
            </CalloutCard>
          )}
        </ModalBody>
        <ModalFooter px="space10">
          {selectedDestination?.selectedMessage && (
            <CalloutCard theme="notice" mb="space4">
              {selectedDestination?.selectedMessage}
            </CalloutCard>
          )}
          {selectedDestination?.shared && (
            <Text
              mb="space4"
              width="100%"
              flexDirection="row"
              alignItems="center"
              color="textWeaker"
            >
              Everyone in
              <Text mx="space1" fontWeight="bold" color="textWeaker">
                {selectedDestination.name}
              </Text>
              will be able to edit
              <Text ml="space1" fontWeight="bold" color="textWeaker">
                {itemToMoveName}
              </Text>
              .
            </Text>
          )}
          {showError && (
            <CalloutCard theme="negative" mb="space4">
              {(folderInfoError && 'Error fetching folders.') || errorMessage}
            </CalloutCard>
          )}
          <ModalDismiss>
            <Button variant="secondary" disabled={buttonState === 'loading'}>
              Cancel
            </Button>
          </ModalDismiss>
          <Button
            variant="primary"
            onClick={handleMove}
            state={buttonState}
            disabled={moveButtonDisabled}
          >
            Move
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};
