import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button, type RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { ChipsInput } from '@mentimeter/ragnar-ui/chips-input';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Textarea } from '@mentimeter/ragnar-ui/input/textarea';
import type { Entity } from '@core-api/collaboration/types/response';
import { isValidEmail, ListItem } from './_utils';
import { usePotentialCollaboratorsPaginated } from './usePotentialCollaboratorsPaginated';

const MESSAGE_MAX_LENGTH = 100;

const getSuggestedInviteItems = (
  members: Entity[],
  selectedItems: InviteItem[],
  collaboratorEmails: string[],
) => {
  const selectedItemsEmails = selectedItems.map((item) => item.email);
  return members
    .filter(
      (member) =>
        !(
          selectedItemsEmails.includes(member.email) ||
          collaboratorEmails.includes(member.email)
        ),
    )
    .map((member) => ({
      id: member.id,
      email: member.email,
      value: member.email,
      label: member.name || '',
      profilePictureUrl: member.profilePictureUrl,
      status: member.membershipStatus,
    }));
};

export interface InviteItem {
  id: string;
  value: string;
  label?: string;
  profilePictureUrl?: string | null;
  isValid?: boolean;
  invalidMessage?: string;
  status?: string | null;
  email?: string;
}

export const AddCollaborators = ({
  selectedItems,
  validateCollaboratorWorkspaceMembership,
  showSendInvite,
  buttonState,
  collaboratorEmails,
  onAddCollaborators,
  setSelectedItems,
  setShowSendInvite,
}: {
  selectedItems: InviteItem[];
  validateCollaboratorWorkspaceMembership: boolean;
  showSendInvite: boolean;
  buttonState: RagnarButtonState | undefined;
  collaboratorEmails: string[];
  onAddCollaborators: (message: string) => Promise<boolean>;
  setSelectedItems: (value: InviteItem[]) => void;
  setShowSendInvite: (value: boolean) => void;
}) => {
  const [message, setMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSetSearchQuery = useMemo(
    () => debounce((value) => setSearchQuery(value), 300),
    [],
  );

  const { members, loadMoreMembers } =
    usePotentialCollaboratorsPaginated(searchQuery);

  const suggestedInviteItems = getSuggestedInviteItems(
    members,
    selectedItems,
    collaboratorEmails,
  );

  const onItemsRendered = ({
    visibleStopIndex,
  }: {
    visibleStopIndex: number;
  }) => {
    if (suggestedInviteItems.length - 1 === visibleStopIndex) loadMoreMembers();
  };

  const handleCancelModal = async () => {
    setSearchQuery('');
    setShowSendInvite(false);
    setMessage('');
  };

  const suggestedInviteItemsIds = suggestedInviteItems.map((item) => item.id);
  const displayHintError = selectedItems.some((item) => !item.isValid);
  const hintErrorText = [...selectedItems]
    .reverse()
    .find((item) => !item.isValid)?.invalidMessage;

  return (
    <>
      <ChipsInput
        label="Email"
        id="invite-collaborators-input"
        selectedOptions={selectedItems}
        setSelectedOptions={(selectedOptions) => {
          setSelectedItems(selectedOptions);
          setSearchQuery('');
        }}
        onChange={(event) => {
          const targetElement = event.target as HTMLInputElement;
          if (!targetElement) return;

          setShowSendInvite(true);
          debouncedSetSearchQuery(targetElement.value);
        }}
        onClick={() => setShowSendInvite(true)}
        autoComplete={true}
        showOptionsOnFocus={true}
        autoCompleteOptions={suggestedInviteItems}
        maxAutocompleteOptionsVisible={3}
        placeholder="Separate emails with space"
        onItemsRendered={onItemsRendered}
        transformOption={(option) => {
          const isValidFormat = isValidEmail(option.value);
          const forbiddenCollaborator =
            validateCollaboratorWorkspaceMembership &&
            !suggestedInviteItemsIds.includes(String(option.id));
          const forbiddenCollaboratorHint = forbiddenCollaborator
            ? 'To add collaborators, they must be part of your workspace.'
            : '';

          const invalidFormatMessage = isValidFormat
            ? ''
            : 'Please make sure the format of your email address matches example@email.com.';

          return {
            ...option,
            isValid: isValidFormat && !forbiddenCollaborator,
            invalidMessage: invalidFormatMessage || forbiddenCollaboratorHint,
          };
        }}
        renderAutocompleteOption={(option) => (
          <ListItem key={option.id} item={option} />
        )}
        hintError={displayHintError}
        hintErrorText={hintErrorText}
      />
      {showSendInvite && (
        <Form
          mt="space4"
          width="100%"
          onSubmit={async (event) => {
            event.preventDefault();
            const ok = await onAddCollaborators(message);
            if (ok) handleCancelModal();
          }}
        >
          <Textarea
            p="space4"
            inputSize="compact"
            id="invite-message-textarea"
            minHeight="100px"
            value={message}
            maxLength={MESSAGE_MAX_LENGTH}
            placeholder="Add your message (optional)"
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
          />

          <Box
            mt="space4"
            width="100%"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              onClick={() => {
                setSelectedItems([]);
                handleCancelModal();
              }}
              variant="secondary"
              mr="space2"
            >
              Cancel
            </Button>
            <Button
              state={buttonState}
              type="submit"
              variant="primary"
              disabled={selectedItems.length < 1 || displayHintError}
            >
              Send
            </Button>
          </Box>
        </Form>
      )}
    </>
  );
};
