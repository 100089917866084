import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { fullMentimeterUrl } from '@mentimeter/next-navigation';

const embedHtml = (url: string): string => {
  return `<div style='position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden;'><iframe sandbox='allow-scripts allow-same-origin allow-presentation' allowfullscreen='true' allowtransparency='true' frameborder='0' height='315' src='${url}' style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;' width='420'></iframe></div>`;
};

export const getEmbedUrl = (seriesId: string) =>
  embedHtml(
    fullMentimeterUrl(buildPresentationPath({ seriesId, mode: 'embed' })),
  );

export const getEditViewUrl = (seriesId: string, source?: string) => {
  const url = fullMentimeterUrl(
    buildPresentationPath({ seriesId, mode: 'edit' }),
  );
  if (!source) return url;

  const urlWithSource = new URL(url);
  urlWithSource.searchParams.set('source', source);
  return urlWithSource.toString();
};

export const getVoteUrl = (voteKey: string, votingUrl: string) =>
  `${votingUrl}/${voteKey}`;

export const shareUrls = (
  seriesId: string,
  voteKey: string,
  votingUrl: string,
  source?: string,
) => {
  return {
    embedUrl: getEmbedUrl(seriesId),
    voteUrl: getVoteUrl(voteKey, votingUrl),
    editViewUrl: getEditViewUrl(seriesId, source),
  };
};
