import { SlidePreview } from '@mentimeter/slide-preview/dynamic';
import {
  RevealWhenFirstVisible,
  SlidePreviewBoundary,
} from '@mentimeter/slide-preview/components';
import { usePresentationCollectionSlideDeck } from 'data-hooks/use-presentation-collection-slide-deck';

const RUNNING_IN_UNIT_TEST = process?.env?.['JEST_WORKER_ID'] !== undefined;

export function DashboardSlidePreview({ seriesId }: { seriesId: string }) {
  if (RUNNING_IN_UNIT_TEST) return null;

  return (
    <RevealWhenFirstVisible>
      <SlidePreviewBoundary context="recent-presentations">
        <DashboardSlidePreviewDataHandler seriesId={seriesId} />
      </SlidePreviewBoundary>
    </RevealWhenFirstVisible>
  );
}

function DashboardSlidePreviewDataHandler({ seriesId }: { seriesId: string }) {
  const { slideDeck } = usePresentationCollectionSlideDeck(seriesId, {
    swrConfig: { suspense: true, revalidateOnFocus: false },
  });

  return (
    <SlidePreview
      // Suspense is enabled, so slideDeck is guaranteed to be defined
      data={slideDeck!}
      exampleResultsMode="on-hover-when-no-results"
      enableNavigation
      enableTestVotesIndicator
      insetBorderRadius={2}
    />
  );
}
