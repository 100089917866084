// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetFoldersRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The dashboard to list folders for */
  dashboardType: 'shared-with-me' | 'me' | 'workspace';
}

export function getFoldersRequest(requestParams: GetFoldersRequest): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/folders/${requestParams.dashboardType}`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
