import { type UpdateSeries } from '@mentimeter/core-hooks';
import type { Series } from '@mentimeter/http-clients';
import { TrackingContext } from '@mentimeter/http-clients';
import { getIsMemberLite, useUser } from '@mentimeter/user';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { userTypes, useTeamShare } from '@mentimeter/workspace-features';
import type { PutResultsSharingRequest } from '@core-api/presentation-collection/presentation-collection/slide-deck/{slide_deck_id}/results-sharing';
import { DashboardShareAndInviteDataHandler } from 'features/share-and-invite/DashboardShareAndInviteDataHandler';
import { useState } from 'react';
import { trackUser } from '@api/tracking/client';
import { TabSelector, TabType } from './components/TabSelector';
import { CollaborationDataHandler } from './data-handlers/CollaborationDataHandler';
import { ParticipationDataHandler } from './data-handlers/ParticipationDataHandler';
import { SlidesDataHandler } from './data-handlers/SlidesDataHandler';

interface Props {
  series: Series;
  defaultTab?: TabType | undefined;
  trackingContext: TrackingContext;
  onUpdateActiveSeries: UpdateSeries;
  onResultsSharingChange: (props: {
    resultsSharing: PutResultsSharingRequest['resultsSharing'];
  }) => Promise<unknown>;
}

export const ShareModalTabs = ({
  series,
  defaultTab,
  trackingContext,
  onUpdateActiveSeries,
  onResultsSharingChange,
}: Props) => {
  const [activeTab, setActiveTab] = useState(
    defaultTab ?? TabType.Participants,
  );

  const { user } = useUser();
  const { data: workspace } = useWorkspace();
  const userType = userTypes(user, workspace);

  const isMemberLite = getIsMemberLite(user);

  const isSeriesInWorkspace = series.workspace_id !== null;

  const { enabled: teamShareEnabled } = useTeamShare();

  const userDoesNotHaveCollaborationFeature =
    userType.isFreeUser || userType.isBasicUser || isMemberLite;
  const showBadgeOnEditors =
    userDoesNotHaveCollaborationFeature && !teamShareEnabled;

  return (
    <>
      <TabSelector
        activeTab={activeTab}
        onClickTab={handleUpdateTab}
        showEditorsTabUpgradeBadge={showBadgeOnEditors}
      />
      {activeTab === TabType.Participants && (
        <ParticipationDataHandler
          series={series}
          onUpdateActiveSeries={onUpdateActiveSeries}
        />
      )}
      {activeTab === TabType.Editors && !teamShareEnabled && (
        <CollaborationDataHandler
          series={series}
          trackingContext={trackingContext}
        />
      )}
      {activeTab === TabType.Editors && teamShareEnabled && (
        <DashboardShareAndInviteDataHandler
          seriesId={series.id}
          customizedLanding="teaminvite"
          customizedContinue="editor"
          trackingContext={trackingContext}
          trackingPlacement="Share modal"
        />
      )}
      {activeTab === TabType.Slides && (
        <SlidesDataHandler
          series={series}
          onResultsSharingChange={onResultsSharingChange}
        />
      )}
    </>
  );

  function handleUpdateTab(tab: TabType) {
    setActiveTab(tab);
    trackClickedTab(tab);

    if (tab === TabType.Editors) {
      trackUser({
        event: 'Viewed collaboration tab',
        properties: {
          context: trackingContext,
          placement: 'Share modal',
          'shared presentation': isSeriesInWorkspace,
          'series id': series.id,
        },
      });
    }
  }

  function trackClickedTab(tab: TabType) {
    trackUser({
      event: 'Clicked share menu tab',
      properties: {
        'series id': series.id,
        pace: series.pace.mode,
        tab,
        context: TrackingContext.MyPresentations,
      },
    });
  }
};
