import type { PictogramVariants } from './types';

export const COLOR_MAP: PictogramVariants = {
  blue: {
    pictogramPrimary: 'var(--palette-blue-500)',
    pictogramSecondary: 'var(--palette-blue-800)',
    pictogramTertiary: 'var(--palette-blue-300)',
  },
  purple: {
    pictogramPrimary: 'var(--palette-purple-500)',
    pictogramSecondary: 'var(--palette-purple-800)',
    pictogramTertiary: 'var(--palette-purple-400)',
  },
  coral: {
    pictogramPrimary: 'var(--palette-coral-500)',
    pictogramSecondary: 'var(--palette-coral-800)',
    pictogramTertiary: 'var(--palette-coral-300)',
  },
};
