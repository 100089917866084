import React from 'react';
import { Box } from '../../box';
import { toggleIconContainer } from './ToggleIconContainer.variants';

interface ToggleIconContainer {
  checked: boolean;
  disabled: boolean;
  size?: 'default' | 'compact' | undefined;
  children: React.ReactNode;
}

export const ToggleIconContainer: React.FC<ToggleIconContainer> = ({
  checked,
  disabled,
  size,
  children,
}: ToggleIconContainer) => {
  const color =
    checked && !disabled ? 'checked' : disabled ? 'disabled' : 'default';

  return (
    <Box as="span" className={toggleIconContainer({ size, color })}>
      {children}
    </Box>
  );
};
