// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface DeletePresentationCollectionRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** Public keys of slide decks to delete */
  slideDecksPublicKeys: Array<string>;
}

export function deletePresentationCollectionRequest(
  requestParams: DeletePresentationCollectionRequest,
): Request {
  const data = {
    slide_decks_public_keys: toSnakeKeys(requestParams.slideDecksPublicKeys),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation-collection`;
  return new Request(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
