import { Device } from '@mentimeter/ragnar-device';
import { SelectItem } from '@mentimeter/ragnar-ui/input/select-item';
import { Tabs as RagnarTabs } from '@mentimeter/ragnar-ui/tabs';

export enum TabType {
  Participants = 'Participants',
  Editors = 'Editors',
  Slides = 'Slides',
}

const tabs: TabType[] = [TabType.Participants, TabType.Editors, TabType.Slides];

interface TabSelectorProps {
  activeTab: TabType;
  onClickTab: (tab: TabType) => void;
  showEditorsTabUpgradeBadge: boolean;
}

export function TabSelector({
  activeTab,
  onClickTab,
  showEditorsTabUpgradeBadge,
}: TabSelectorProps) {
  return (
    <>
      <Device.Match lessThan={2}>
        <SelectItem
          mb={[3, 4]}
          mt={2}
          value={activeTab}
          name="share-tabs"
          onChange={({ currentTarget }) => {
            onClickTab(currentTarget.value as TabType);
          }}
        >
          {tabs.map((tab) => (
            <SelectItem.Option key={tab} value={tab}>
              {tab}
            </SelectItem.Option>
          ))}
        </SelectItem>
      </Device.Match>
      <Device.Match greaterThan={1}>
        <RagnarTabs
          mb={4}
          fullWidth
          mt={2}
          actions={tabs.map((title) => ({
            active: activeTab === title,
            onClick: () => onClickTab(title),
            title,
            key: title,
            badge:
              title === TabType.Editors
                ? showEditorsTabUpgradeBadge
                  ? 'upgrade'
                  : undefined
                : undefined,
          }))}
        />
      </Device.Match>
    </>
  );
}
