import { Link } from '@mentimeter/next-navigation';
import { trackUser } from '@api/tracking/client';
import { Text } from '@mentimeter/ragnar-ui/text';

export const IndividualCollaborationUpgradePrompt = ({
  trackingEvent,
}: {
  trackingEvent: 'Plans from homeview' | 'Plans from editor';
}) => {
  return (
    <Text mt={4}>
      <Link
        underline={false}
        href="/plans"
        target="_blank"
        fontWeight="semiBold"
        onClick={() => {
          trackUser({
            event: trackingEvent,
            properties: {
              context: 'Individual collaboration upgrade prompt',
            },
          });
        }}
      >
        Upgrade to Pro
      </Link>{' '}
      <Text color="textWeaker">
        to control individual access to this presentation
      </Text>
    </Text>
  );
};
