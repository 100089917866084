// This file is automatically generated. DO NOT EDIT.

import { type Entity } from '../../types/response/Entity';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface GetPotentialCollaboratorsResponse {
  data: Array<Entity>;
}

export async function getPotentialCollaboratorsResponse(
  response: Response,
  options?: GetJSONResponseOptions<GetPotentialCollaboratorsResponse>,
): Promise<GetPotentialCollaboratorsResponse> {
  return getJSONResponse<GetPotentialCollaboratorsResponse>(
    response,
    'getPotentialCollaboratorsResponse',
    options,
  );
}
