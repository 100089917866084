import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { styled } from '@mentimeter/ragnar-styled';
import { RadioItem } from '@radix-ui/react-dropdown-menu';
import {
  menuItemVariants,
  type MenuItemVariantProps,
} from '../MenuItem.variants';

const DropdownMenuRadioItemRadixStyled = styled(
  RadioItem,
  {
    displayName: 'DropdownMenuRadioItemRadixStyled',
  },
  true,
  ['asChild', 'disabled', 'textValue'],
)('color', 'kosmosSpacing', 'layout', 'flexbox');

type DropdownMenuRadioItemProps = React.ComponentProps<
  typeof DropdownMenuRadioItemRadixStyled
> & {
  variant?: MenuItemVariantProps['variant'];
};

export const DropdownMenuRadioItem = React.forwardRef(
  (props: DropdownMenuRadioItemProps, ref) => {
    const { className, variant, ...rest } = props;
    return (
      <DropdownMenuRadioItemRadixStyled
        className={cn(menuItemVariants({ variant }), className)}
        ref={ref}
        {...rest}
      />
    );
  },
);
