import React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';

export function ShareModalText({
  children,
  ...rest
}: Parameters<typeof Text>[0]) {
  return (
    <Text fontSize={2} width={1} color="textWeaker" {...rest}>
      {children}
    </Text>
  );
}
