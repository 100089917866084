import type { ReactNode } from 'react';
import type { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { useFeatureAccess } from '../hooks/use-feature-access';

/**
 * This type of paywall is a stopgap solution before entitlements have been implemented for all features.
 */
interface LegacyPlanPaywallProps {
  enabled: boolean;
  requiredPlan: UserFeaturesTypeEnum;
  requestUpgradeContent: ReactNode;
  selfUpgradeContent: ReactNode;
  children: ReactNode;
}

/*
 * This is the new type of paywall that will be used for entitlements, where the entitlement value is used
 * for the enabled prop and the entitlement reason is used to determine which paywall to pass in.
 */
interface EntitlementPaywallProps {
  enabled: boolean;
  paywall: ReactNode;
  children: ReactNode;
}

type PaywallProps = LegacyPlanPaywallProps | EntitlementPaywallProps;

export function Paywall(props: PaywallProps) {
  if ('paywall' in props) {
    const { enabled, paywall, children } = props;

    return enabled ? paywall : children;
  }

  return <LegacyPlanPaywall {...props} />;
}

function LegacyPlanPaywall({
  enabled,
  requiredPlan,
  requestUpgradeContent,
  selfUpgradeContent,
  children,
}: LegacyPlanPaywallProps) {
  const accessLevel = useFeatureAccess(requiredPlan);
  if (!enabled || accessLevel === 'has-access') return children;

  if (accessLevel === 'not-in-role') {
    return requestUpgradeContent;
  }

  return selfUpgradeContent;
}
