import type { TrackingContext, Series } from '@mentimeter/http-clients';
import { CollaborationSeriesDataHandler } from '@mentimeter/share-modal';
import { useUser } from '@mentimeter/user';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { userTypes } from '@mentimeter/workspace-features';
import { useSeriesCache } from 'features/dashboard/use-series-cache';

interface CollaborationDataHandlerProps {
  series: Series;
  trackingContext: TrackingContext;
}

export function CollaborationDataHandler({
  series,
  trackingContext,
}: CollaborationDataHandlerProps) {
  const { user } = useUser();
  const { data: workspace } = useWorkspace();
  const userType = userTypes(user, workspace);

  const { invalidate: invalidateSeries } = useSeriesCache();

  if (!user) return null;

  const collaborationRights = userType.isFreeUser
    ? 'no_one'
    : workspace?.collaborationRights;

  const isSeriesInWorkspace = Boolean(series.workspace_id);

  return (
    <CollaborationSeriesDataHandler
      hideMembers={Boolean(workspace?.hideUsersFromMembers)}
      seriesId={series.id}
      ownerId={series.owner_id}
      userFeatures={user.features}
      userId={user.id}
      collaborationRights={collaborationRights || ''}
      context={trackingContext}
      isSeriesInWorkspace={isSeriesInWorkspace}
      invalidateSeries={invalidateSeries}
    />
  );
}
