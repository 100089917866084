// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostRenewResponse {
  /** The vote code ID */
  voteCodeId: number;
  renewAfter: number;
  /** The vote code time to live left in milliseconds */
  expiresIn: number;
  /** The vote code time to live left in seconds */
  expiresInSeconds: number;
}

export async function postRenewResponse(
  response: Response,
): Promise<PostRenewResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostRenewResponse;
}
