import React, { type SyntheticEvent } from 'react';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { IconButton } from '@mentimeter/ragnar-ui/icon-button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';

export const SelectorRow = ({
  id,
  badgeText,
  canExpand,
  checked,
  disabled,
  disabledTooltipText,
  icon,
  isOpen,
  label,
  level,
  onExpand,
  onSelect,
  showChevron,
}: {
  id: string;
  badgeText: string | undefined;
  canExpand: boolean;
  checked: boolean;
  disabled: boolean;
  disabledTooltipText?: string | undefined;
  icon: React.ReactNode;
  isOpen: boolean;
  label: string;
  level: number;
  onExpand: () => void;
  onSelect: () => void;
  showChevron: boolean;
}) => {
  const handleSelectClick = (e: SyntheticEvent) => {
    if (disabled) return;
    onSelect();
  };

  const bg = disabled
    ? 'disabledStrong'
    : checked
      ? 'secondaryWeakest'
      : 'transparent';

  const tooltipId = `selector-row-${id}`;

  return (
    <>
      {disabledTooltipText && (
        <TooltipOld referenceId={tooltipId} description={disabledTooltipText} />
      )}
      <Box
        width="100%"
        id={tooltipId}
        display="flex"
        flexDirection="row"
        alignItems="stretch"
      >
        <Clickable
          alignItems="center"
          borderColor={checked ? 'secondary' : 'transparent'}
          borderRadius="lg"
          borderStyle="solid"
          borderWidth="1px"
          color="text"
          disabled={disabled}
          display="flex"
          flex="1 1 auto"
          flexDirection="row"
          onClick={handleSelectClick}
          pl={`space${level * 2}`}
          pr="space3"
          bg={bg}
          extend={({ theme }) => ({
            cursor: !disabled ? 'pointer' : 'default',
            '@media (hover: hover)': {
              ':hover': checked
                ? {
                    background: theme.colors.secondaryWeak,
                    borderColor: theme.colors.secondary,
                    opacity: 1,
                  }
                : {
                    background: theme.colors.surfaceHover,
                    opacity: 1,
                  },
            },
          })}
        >
          {icon}
          <Box
            flex="1"
            ml="space2"
            flexDirection="row"
            alignItems="center"
            py="3px"
          >
            <Text truncate lineHeight="200">
              {label}
            </Text>
            {badgeText && (
              <Badge
                ml="space2"
                type="text"
                text={badgeText}
                bg="infoWeakest"
                color={disabled ? 'onDisabledStrong' : undefined}
                textTransform="none"
              />
            )}
          </Box>
        </Clickable>
        <Box
          flex="0 0"
          extend={({ theme }) => ({
            flexBasis: theme.kosmosSpacing.space10 + 'px',
          })}
        >
          {showChevron && (
            <IconButton
              onClick={onExpand}
              disabled={!canExpand}
              variant="secondary"
              mx="space2"
            >
              <Box
                extend={({ theme }) => ({
                  transition: `transform ${theme.durations[1]}s ease`,
                  transform: `rotate(${isOpen ? 180 : 0}deg)`,
                })}
              >
                <ChevronDownIcon color="inherit" />
              </Box>
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};
