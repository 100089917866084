// This file is automatically generated. DO NOT EDIT.

import { type Folder } from '../../types/response/Folder';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface GetFoldersResponse {
  data: Array<Folder>;
}

export async function getFoldersResponse(
  response: Response,
  options?: GetJSONResponseOptions<GetFoldersResponse>,
): Promise<GetFoldersResponse> {
  return getJSONResponse<GetFoldersResponse>(
    response,
    'getFoldersResponse',
    options,
  );
}
