// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PutNameRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The folder to rename */
  id: number;
  /** The new name of the folder */
  name: string;
}

export function putNameRequest(requestParams: PutNameRequest): Request {
  const data = {
    name: toSnakeKeys(requestParams.name),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/folders/${requestParams.id}/name`;
  return new Request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
