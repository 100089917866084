import {
  // eslint-disable-next-line no-restricted-imports
  useSeries,
  // eslint-disable-next-line no-restricted-imports
  useSeriesMutate,
} from '@mentimeter/core-hooks';
import React, { useCallback } from 'react';
import {
  ShareAndInvite,
  type ShareAndInvitePlacement,
} from '@mentimeter/invite-modal';
import type { SharedStatus } from '@mentimeter/workspace';
import {
  type InviteContinue,
  type InviteLanding,
  type TrackingContext,
} from '@mentimeter/http-clients';
import { useSeriesCache } from 'features/dashboard/use-series-cache';
import { getIsMemberLite, useUser } from '@mentimeter/user';
import {
  useWorkspace,
  useOrganizationMembersUnpaginated,
  mutateWorkspace,
  useCurrentUserWorkspace,
} from '@mentimeter/workspace-data-hooks';
import { movePresentation } from '@mentimeter/move-modal';
import { trackUser } from '@api/tracking/client';

export interface DashboardShareAndInviteDataHandlerProps {
  seriesId: string;
  customizedLanding?: InviteLanding;
  customizedContinue?: InviteContinue;
  trackingContext: TrackingContext;
  trackingPlacement: ShareAndInvitePlacement;
}

export function DashboardShareAndInviteDataHandler({
  seriesId,
  trackingContext,
  trackingPlacement,
  customizedLanding,
  customizedContinue,
}: DashboardShareAndInviteDataHandlerProps) {
  const { user } = useUser();
  const { data: workspace } = useCurrentUserWorkspace();
  const { create: createWorkspace } = useWorkspace();
  const { data: series } = useSeries(seriesId);
  const mutateSeries = useSeriesMutate(seriesId);
  const { invalidate: invalidateSeriesCache } = useSeriesCache();
  const { members } = useOrganizationMembersUnpaginated({
    skip: !workspace,
    revalidateOnFocus: false,
  });

  const handleClickShareAndInviteCopyLink = useCallback(() => {
    trackUser({
      event: 'Clicked share and invite copy link',

      properties: {
        context: trackingContext,
        placement: trackingPlacement,
      },
    });
  }, [trackingContext, trackingPlacement]);

  if (!series || !user) return;

  const shareAndInviteWorkspace = workspace
    ? { name: workspace.name, ownerName: workspace.ownerName, members }
    : null;

  return (
    <ShareAndInvite
      compact
      seriesId={seriesId}
      seriesName={series.name}
      seriesOwnerId={series.owner_id}
      seriesWorkspaceId={series.workspace_id}
      userId={user.id}
      userIsWorkspaceOwnerOrAdmin={user.ownerOrAdmin}
      userIsMemberLite={getIsMemberLite(user)}
      workspaceInfo={shareAndInviteWorkspace}
      onSharedChange={handleShareChange}
      onInviteSuccess={invalidateSeriesCache}
      onClickShareAndInviteCopyLink={handleClickShareAndInviteCopyLink}
      customizedLanding={customizedLanding}
      customizedContinue={customizedContinue}
      trackingPlacement={trackingPlacement}
      trackingContext={trackingContext}
      upgradeTrackingEvent="Plans from homeview"
      onRemoveMemberFromTeam={(memberId) => {
        window.open(`/app/manage-members?remove-member=${memberId}`, '_blank');
      }}
      onManageMemberInvitation={() => {
        window.open(`/app/manage-members`, '_blank');
      }}
    />
  );

  async function handleShareChange(sharedStatus: SharedStatus) {
    if (!user) return;

    trackUser({
      event: 'Changed presentation access',
      properties: {
        context: trackingContext,
        placement: trackingPlacement,
        'active team members': workspace?.memberStats?.active || 0,
        'current plan': user.features.type,
      },
    });

    if (!series) {
      await invalidateSeriesCache();
    } else if (sharedStatus === 'shared') {
      if (workspace) {
        await movePresentation(
          seriesId,
          workspace.id,
          'dashboard-share-and-invite',
        );
      } else {
        await createWorkspace({}).then((workspaceId) => {
          movePresentation(seriesId, workspaceId, 'dashboard-share-and-invite');
        });
      }
      await mutateWorkspace();
      await invalidateSeriesCache();
    } else if (sharedStatus === 'private') {
      await movePresentation(series.id, null, 'dashboard-share-and-invite');
      mutateSeries({ ...series, workspace_id: null }, { revalidate: true });
    }
  }
}
