import {
  MentiError,
  type MentiErrorOptions,
} from '@mentimeter/error-utils/error';
import { fromSnakeKeys } from './snake-keys';
import type { FeatureOwner } from '@mentimeter/error-utils/feature-owners';

export interface GetJSONResponseOptions<T> {
  /**
   * Skip `response.ok` check.
   *
   * Default: `false`
   */
  enableStatusCheck?: boolean;
  /**
   * Specify a feature owner.
   */
  feature?: FeatureOwner | undefined;
  /**
   * Extra tags to be added to a possible error.
   */
  tags?: Record<string, string | number | boolean>;
  /**
   * Custom response transformer.
   * If not provided, the response will be camel cased. 🐪
   */
  handleTransform?: ((json: unknown) => T) | undefined;
  /**
   * Custom error handling.
   * If not provided, a generic error will be thrown. 🔄
   *
   * This is ignored if `enableStatusCheck` is `false`.
   *
   * @example
   * "[getExampleResponse] Request failed with status 401"
   */
  handleFailure?: ((response: Response) => Promise<MentiError>) | undefined;
}

export async function getJSONResponse<T>(
  response: Response,
  responseName: string,
  {
    enableStatusCheck = false,
    feature,
    tags: customTags,
    handleTransform,
    handleFailure,
  }: GetJSONResponseOptions<T> = { enableStatusCheck: false },
): Promise<T> {
  if (!enableStatusCheck) {
    const json = await response.json();
    if (typeof handleTransform === 'function') {
      return handleTransform(json);
    }
    return fromSnakeKeys(json);
  }

  const contentType = response.headers.get('Content-Type');
  const contentTypeIsUnknown = !contentType;
  const contentTypeIsNotJSON = contentTypeIsUnknown
    ? false // When the content type is not provided, treat is as a JSON response.
    : !contentType.includes('application/json');
  const tags = { 'error.type': 'API', ...customTags };

  if (!response.ok) {
    if (typeof handleFailure === 'function') {
      const customError = await handleFailure(response);
      throw customError;
    }

    let cause: Error | MentiError | unknown;
    try {
      if (contentTypeIsNotJSON) {
        const text = await response.text();
        cause = new Error(text);
      } else {
        const json = await response.json();
        const errorMessage = json?.error || JSON.stringify(json);
        cause = new Error(errorMessage);
      }
    } catch (ex) {
      cause = ex;
    }

    const errorMessage = `[${responseName}] Request failed with status ${response.status}`;
    let error;
    if (feature) {
      error = new MentiError(errorMessage, {
        cause,
        feature,
        tags,
      });
    } else {
      error = new ApiResponseError(errorMessage, {
        cause,
        tags,
      });
    }
    throw error;
  }

  if (contentTypeIsNotJSON) {
    let cause: Error | MentiError | unknown;
    try {
      const text = await response.text();
      cause = new Error(text);
    } catch (ex) {
      cause = ex;
    }

    const errorMessage = `[${responseName}] Unexpected response with content type: ${contentType || 'unknown'}`;
    let error;
    if (feature) {
      error = new MentiError(errorMessage, {
        cause,
        feature,
        tags,
      });
    } else {
      error = new ApiResponseError(errorMessage, {
        cause,
        tags,
      });
    }
    throw error;
  }

  try {
    const json = await response.json();
    if (typeof handleTransform === 'function') {
      return handleTransform(json);
    }
    return fromSnakeKeys(json);
  } catch (cause) {
    const errorMessage = `[${responseName}] Malformed JSON response`;
    let error;
    if (feature) {
      error = new MentiError(errorMessage, {
        cause,
        feature,
        tags,
      });
    } else {
      error = new ApiResponseError(errorMessage, {
        cause,
        tags,
      });
    }
    throw error;
  }
}

class ApiResponseError extends Error {
  tags?: Record<string, string | number | boolean>;

  constructor(
    errorMessage: string,
    options: Omit<MentiErrorOptions, 'feature'>,
  ) {
    super(errorMessage, options);

    if (options.tags) {
      this.tags = options.tags;
    }
  }
}
