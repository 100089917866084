import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const toggleIcon = cva(
  [
    'rounded-full',
    'transition-[background-color,transform] duration-100 ease-in-out',
  ],
  {
    variants: {
      size: {
        default: 'w-[20px] h-[20px]',
        compact: 'w-[14px] h-[14px]',
      },
      color: {
        default: 'bg-[--palette-white-base]',
        checked: 'bg-on-secondary',
        disabled: 'bg-on-disabled-strong',
      },
      checked: {
        true: 'translate-x-full',
        false: '',
      },
    },
    defaultVariants: {
      size: 'default',
      color: 'default',
      checked: false,
    },
  },
);

export type ToggleIconVariantProps = VariantProps<typeof toggleIcon>;
