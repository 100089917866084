// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostRenewRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The presentation publicKey */
  slideDeckPublicKey: string;
  /** Time To Live of the vote_code in seconds */
  ttl?: '172800' | '604800' | '1209600' | null;
}

export function postRenewRequest(requestParams: PostRenewRequest): Request {
  const data = {
    ttl: toSnakeKeys(requestParams.ttl),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/vote-code/${requestParams.slideDeckPublicKey}/renew`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
