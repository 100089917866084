import { Fragment, useState, type ReactNode } from 'react';
import { trackUser } from '@api/tracking/client';
import userCache from '@mentimeter/user';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { putAccessRequest } from '@core-api/collaboration/collaboration/series/access';
import {
  ChevronDownIcon,
  CommentIcon,
  CrossIcon,
  EyeIcon,
  PencilIcon,
  type FunctionalIconT,
} from '@mentimeter/ragnar-visuals';
import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { mutateSeriesCollaborators } from '@mentimeter/core-hooks';
import { TrackingContext } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { Button, useButtonState } from '@mentimeter/ragnar-ui/button';
import {
  DropdownMenuContainer,
  DropdownMenuItemIcon,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import { Text } from '@mentimeter/ragnar-ui/text';
import { DisabledAccessTooltip } from './DisabledAccessTooltip';

type AccessType = SeriesCollaborator['access'];

const ACCESS_LABELS = {
  edit: 'Edit',
  view: 'View',
  comment: 'Comment',
};

const ChangeAccessTrigger = ({
  buttonState,
  collaboratorId,
  collaboratorUserName,
  access,
  isOpen,
}: {
  buttonState: RagnarButtonState | undefined;
  collaboratorId: string;
  collaboratorUserName: string;
  access: keyof typeof ACCESS_LABELS;
  isOpen: boolean;
}) => {
  return (
    <DropdownMenuTrigger>
      <Button
        id={`access-dropdown-${collaboratorId}`}
        aria-label={`change access for ${collaboratorUserName}`}
        variant="subtle"
        state={buttonState}
      >
        <Box flexDirection="row" alignItems="center">
          <Text fontWeight="semiBold" fontSize="87.5" mx="space1">
            {ACCESS_LABELS[access]}
          </Text>
          <Box
            extend={({ theme }) => ({
              transition: `transform ${theme.durations[1]}s ease`,
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            })}
          >
            <ChevronDownIcon size={2} />
          </Box>
        </Box>
      </Button>
    </DropdownMenuTrigger>
  );
};

const accessOptionsMap: {
  id: AccessType;
  Icon: (arg: FunctionalIconT) => ReactNode;
}[] = [
  {
    id: 'edit',
    Icon: PencilIcon,
  },
  {
    id: 'comment',
    Icon: CommentIcon,
  },
  {
    id: 'view',
    Icon: EyeIcon,
  },
];

const stringToHtmlId = (str: string) => str.replace(/[^a-zA-Z0-9_:.]/g, '-');

export const CollaboratorAccessDropdown = ({
  collaborator,
  onRemoveAccess,
  collaboratorIsCurrentUser,
}: {
  collaborator: SeriesCollaborator;
  onRemoveAccess: () => Promise<void>;
  collaboratorIsCurrentUser: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonState, setButtonState] = useButtonState();
  const access = collaborator.access;

  const handleChangeAccess = async (value: string) => {
    setButtonState('loading');
    try {
      const fromAccess = collaborator.access;
      const allowedAccess = collaborator.allowedAccess;
      const seriesId = collaborator.seriesId;

      await fetch(
        putAccessRequest({
          region: userCache.region,
          userAuth: userCache.getToken(),
          seriesId: collaborator.seriesId,
          collaboratorId: Number(collaborator.entity.id),
          access: value as AccessType,
        }),
      );

      if (fromAccess === 'edit' && collaboratorIsCurrentUser) {
        window.location.reload();
      }

      await mutateSeriesCollaborators(collaborator.seriesId);

      trackUser({
        event: 'Clicked change access',
        properties: {
          context: TrackingContext.Editview,
          placement: 'Share modal',
          'series id': seriesId,
          'from access': fromAccess,
          'to access': value,
          'allowed access': allowedAccess,
        },
      });

      setButtonState(undefined);
    } catch (error) {
      captureException(
        new MentiError(`Failed to update collaborator access`, {
          feature: 'collaboration',
          cause: error,
        }),
      );
      setButtonState('error');
    }
  };

  return (
    <DropdownMenuRoot open={isOpen} onOpenChange={setIsOpen}>
      <ChangeAccessTrigger
        buttonState={buttonState}
        collaboratorId={collaborator.entity.id}
        collaboratorUserName={collaborator.entity.name}
        access={access}
        isOpen={isOpen}
      />
      <DropdownMenuContainer side="right">
        <DropdownMenuRadioGroup
          value={access}
          onValueChange={handleChangeAccess}
        >
          {accessOptionsMap.map(({ id, Icon }) => {
            const itemId = `change-access-level-${stringToHtmlId(collaborator.entity.id)}`;
            const disabled = !collaborator.allowedAccess.includes(id);
            return (
              <Fragment key={id}>
                <DropdownMenuRadioItem
                  value={id}
                  id={itemId}
                  disabled={disabled}
                >
                  <DropdownMenuItemIcon>
                    <Icon
                      size={2}
                      color={disabled ? 'textDisabled' : 'text'}
                      aria-hidden
                    />
                  </DropdownMenuItemIcon>
                  {ACCESS_LABELS[id]}
                </DropdownMenuRadioItem>
                {id === 'edit' && disabled && (
                  <DisabledAccessTooltip referenceId={itemId} />
                )}
              </Fragment>
            );
          })}
        </DropdownMenuRadioGroup>
        <DropdownMenuRadioGroup
          onValueChange={async () => {
            setButtonState('loading');
            try {
              await onRemoveAccess();
              setButtonState(undefined);
            } catch (e) {
              setButtonState('error');
            }
          }}
        >
          <DropdownMenuRadioItem
            value={`remove-${collaborator.entity.id}`}
            id={`remove-${collaborator.entity.id}`}
            variant="negative"
          >
            <DropdownMenuItemIcon>
              <CrossIcon size={2} aria-hidden color="inherit" />
            </DropdownMenuItemIcon>
            <Text fontSize="87.5" color="inherit">
              Remove access
            </Text>
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContainer>
    </DropdownMenuRoot>
  );
};
