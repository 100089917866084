import { Badge } from '@mentimeter/ragnar-ui/badge';
import { ACCESS_TOOLTIP_DESCRIPTION } from '../_utils';

export const ViewPermissionTooltip = ({
  collaboratorUserId,
}: {
  collaboratorUserId: string;
}) => {
  const elementId = `has-view-permission-${collaboratorUserId}`;

  return (
    <Badge
      id={elementId}
      type="view-only-icon"
      compact
      description={ACCESS_TOOLTIP_DESCRIPTION}
    />
  );
};
