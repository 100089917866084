import { useCallback, useState } from 'react';
import {
  getHasPrivateResultsEnabled,
  getIsProUser,
  useUser,
} from '@mentimeter/user';
import type {
  ResultsSharingEnum,
  Series,
  UserPresentationRole,
} from '@mentimeter/http-clients';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { SHARE_MODAL_SECTION_GAP } from '../../../constants';
import { EmbedResultsSection } from './EmbedResultsSection';
import { LinkToSlidesSection } from './LinkToSlidesSection';

interface SharingProps {
  isWorkspacePresentation: boolean;
  role: UserPresentationRole;
  editViewUrl: string;
  embedUrl: string;
  series: Series;
  onResultsSharingChange: (props: {
    resultsSharing: 'on' | 'open' | 'off';
  }) => Promise<unknown>;
  context: TrackingContext;
}

export function ShareModalSlidesTab({
  isWorkspacePresentation,
  role,
  series,
  onResultsSharingChange,
  editViewUrl,
  embedUrl,
  context,
}: SharingProps) {
  const { user } = useUser();
  const hasPrivateResultsEnabled = getHasPrivateResultsEnabled(user?.features);
  const isProUser = getIsProUser(user?.features);
  const {
    disable_share_results,
    id: seriesId,
    pace,
    results_sharing,
    participation_authentication_mode,
  } = series;

  const showTeamRestrictionHint = disable_share_results && isProUser;

  const [embedResultsState, setEmbedResultsState] = useState<
    'idle' | 'copying' | 'copied'
  >('idle');

  const [linkResultsState, setLinkResultsState] = useState<
    'idle' | 'copying' | 'copied'
  >('idle');

  function trackToggledPresentationPrivacy(resultsSharing: ResultsSharingEnum) {
    const option = { on: 'Audience', open: 'Results link', off: 'Private' }[
      resultsSharing
    ];
    trackUser({
      event: 'Toggled presentation privacy',
      properties: {
        option,
      },
    });
  }

  const CHANGE_BUTTON_STATE_DELAY = 2500;

  const copyUrl = async (
    url: string,
    setState: (state: 'idle' | 'copying' | 'copied') => void,
  ) => {
    if (navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(url);
        setState('copied');
      } catch (error) {
        captureException(
          new MentiError('Sharing: navigator.clipboard.writeText', {
            cause: error,
            feature: 'creation-experience',
          }),
        );
      } finally {
        setTimeout(() => {
          setState('idle');
        }, CHANGE_BUTTON_STATE_DELAY);
      }
    }
  };

  const { mode } = pace;

  const onClickLinkResults = useCallback(() => {
    setLinkResultsState('copying');
    copyUrl(editViewUrl, setLinkResultsState);

    trackUser({
      event: 'Copied live results link',
      properties: {
        'series id': seriesId,
        pace: mode,
        context,
      },
    });
  }, [editViewUrl, seriesId, mode, context]);

  const onClickEmbedResults = useCallback(() => {
    setEmbedResultsState('copying');
    copyUrl(embedUrl, setEmbedResultsState);

    trackUser({
      event: 'Copied live results embed code',
      properties: {
        'series id': seriesId,
        pace: mode,
        context,
      },
    });
  }, [embedUrl, seriesId, mode, context]);

  return (
    <Box
      extend={() => ({
        gap: SHARE_MODAL_SECTION_GAP,
      })}
    >
      <LinkToSlidesSection
        state={linkResultsState}
        resultsSharing={results_sharing as ResultsSharingEnum}
        privateResultsEnabled={hasPrivateResultsEnabled}
        showTeamRestrictionHint={showTeamRestrictionHint}
        disableShareResults={
          disable_share_results ||
          participation_authentication_mode === 'authenticated'
        }
        onResultsSharingChange={onResultsSharingChange}
        trackToggledPresentationPrivacy={trackToggledPresentationPrivacy}
        onCopy={onClickLinkResults}
        trackingContext={context}
        trackingProperties={{
          role,
          'presentation owner': role === 'Owner',
          'shared presentation': isWorkspacePresentation,
        }}
        upgradeEvent={
          context === TrackingContext.Editview
            ? 'Plans from editor'
            : 'Plans from homeview'
        }
      />
      <EmbedResultsSection
        state={embedResultsState}
        onClick={onClickEmbedResults}
      />
    </Box>
  );
}
