import { NetworkIcon } from '@mentimeter/ragnar-visuals';
import { TabType } from 'features/dashboard/share/components/TabSelector';
import type { Dispatch, SetStateAction } from 'react';
import { trackUser } from '@api/tracking/client';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import { ContextMenu } from '../ContextMenu';
import { useContextMenuContext } from '../useContextMenuContext';

interface ShareProps {
  item: SlideDeckOverview;
  setDefaultShareTab: Dispatch<SetStateAction<TabType>>;
  setShareModalOpen: Dispatch<SetStateAction<boolean>>;
}
export function Share({
  item,
  setDefaultShareTab,
  setShareModalOpen,
}: ShareProps) {
  const { trackingContext } = useContextMenuContext();

  const handleSelect = () => {
    setDefaultShareTab(TabType.Editors);
    setShareModalOpen(true);

    trackUser({
      event: 'Opened share menu',
      properties: {
        context: trackingContext,
        'shared presentation': item.workspaceId !== null,
        'series id': item.slideDeckPublicKey,
      },
    });
  };
  return (
    <ContextMenu.Item onSelect={handleSelect}>
      <ContextMenu.ItemIcon>
        <NetworkIcon />
      </ContextMenu.ItemIcon>
      <ContextMenu.ItemLabel color="text">Share</ContextMenu.ItemLabel>
    </ContextMenu.Item>
  );
}
