import { useState, useCallback } from 'react';
import type { TrackingContext, Series } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { SHARE_MODAL_SECTION_GAP } from '../../../constants';
import type { VoteCode } from './types';
import {
  AccessCodeExpirationSection,
  type TTLDaysT,
} from './AccessCodeExpirationSection';
import { QRCodeSection } from './QRCodeSection';
import { InviteLinkSection } from './InviteLinkSection';
import { VotingAccessSwitch } from './VotingAccessSwitch';

interface ParticipationProps {
  series: Series;
  voteCode: VoteCode;
  update: (args: { closed_for_voting?: boolean }) => void;
  voteUrl: string;
  context: TrackingContext;
}
export function Participation({
  series,
  voteCode,
  update,
  voteUrl,
  context,
}: ParticipationProps) {
  const { name, id, closed_for_voting, pace } = series;
  const isOpenForVoting = !closed_for_voting;
  const [feedback, setFeedback] = useState<TTLDaysT | undefined>(undefined);

  const onChangeVoteExpiration = useCallback(
    (value: TTLDaysT) => {
      setFeedback(value);
      voteCode.setTTLInDays(parseInt(value, 10));

      trackUser({
        event: 'Prolonged vote id',
        properties: {
          'Days prolonged': value,
          'Series name': name,
          'Series id': id,
          context,
        },
      });
    },

    [context, id, name, voteCode],
  );

  const onChangeVotingAccess = useCallback(
    (isOpenForVoting?: boolean) => {
      // TODO [MX]: migrate to use migration aware hook
      update({
        closed_for_voting: !isOpenForVoting,
      });

      trackUser({
        event: 'Toggled audience access',
        properties: {
          enabled: !!isOpenForVoting,
        },
      });
    },
    [update],
  );

  return (
    <Box width={1} gap={SHARE_MODAL_SECTION_GAP}>
      <VotingAccessSwitch
        checked={isOpenForVoting}
        onChange={onChangeVotingAccess}
      />
      <InviteLinkSection
        id={id}
        pace={pace}
        voteUrl={voteUrl}
        context={context}
      />
      <QRCodeSection id={id} pace={pace} voteUrl={voteUrl} context={context} />
      <AccessCodeExpirationSection
        voteCode={voteCode}
        feedback={feedback}
        onChange={onChangeVoteExpiration}
      />
    </Box>
  );
}
