import React, { Suspense, useMemo } from 'react';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import {
  // eslint-disable-next-line no-restricted-imports
  useSeries,
  // eslint-disable-next-line no-restricted-imports
  useSeriesMutate,
  type UpdateSeries,
  CoreHooksProvider,
} from '@mentimeter/core-hooks';
import {
  core,
  type Series,
  type TrackingContext,
} from '@mentimeter/http-clients';
import { usePresentationCollectionSlideDeck } from 'data-hooks/use-presentation-collection-slide-deck';
import { usePresentationCollectionSlideDeckUpdateResultsSharingCompatibility } from 'data-hooks/compatibility/slide-deck/use-presentation-collection-slide-deck-update-results-sharing-compatibility';
import { seriesCacheKey } from 'data-hooks/cache-keys';
import { MigrationContainer } from 'components/MigrationContainer';
import type { SeriesWithSlideDeck } from '@mentimeter/presentation-collection-schema/api-types-overrides';
import {
  ShareModalLayout,
  type ShareModalLayoutProps,
} from './components/ShareModalLayout';
import { SkeletonLoader } from './components/SkeletonLoader';
import type { TabType } from './components/TabSelector';
import { ShareModalTabs } from './ShareModalTabs';

export interface ShareProps {
  defaultTab?: TabType;
  trackingContext: TrackingContext;
  seriesId: string;
}

export interface ShareModalDataLoaderProps extends ShareProps {
  slideDeck: SeriesWithSlideDeck;
  onUpdateActiveSeries: UpdateSeries;
}

function ShareModalDataLoader({
  seriesId,
  slideDeck,
  trackingContext,
  defaultTab,
  onUpdateActiveSeries,
}: ShareModalDataLoaderProps) {
  const handleResultsSharingChange =
    usePresentationCollectionSlideDeckUpdateResultsSharingCompatibility({
      seriesId,
      getSeriesCacheKey: seriesCacheKey,
    });

  return (
    <ShareModalTabs
      series={slideDeck}
      onUpdateActiveSeries={onUpdateActiveSeries}
      onResultsSharingChange={handleResultsSharingChange}
      defaultTab={defaultTab}
      trackingContext={trackingContext}
    />
  );
}

function SlideDeckDataLoader({
  seriesId,
  ...props
}: ShareProps & {
  series: Series;
  onUpdateActiveSeries: UpdateSeries;
}) {
  const { slideDeck } = usePresentationCollectionSlideDeck(seriesId, {
    getSeriesCacheKey: seriesCacheKey,
    swrConfig: { suspense: true },
  });

  const isMigrated = useMemo(() => {
    return Boolean(slideDeck && (slideDeck as any).__target.slideDeckId);
  }, [slideDeck]);

  return (
    <MigrationContainer isMigrated={isMigrated}>
      <ShareModalDataLoader
        seriesId={seriesId}
        slideDeck={slideDeck!} // Fetching inside a suspense boundary, so we can assume slideDeck is defined
        {...props}
      />
    </MigrationContainer>
  );
}

// TODO [MX]: The SeriesDeckDataLoader should be removed and replaced with usage of migration aware hooks. Add a new update function like usePresentationCollectionSlideDeckUpdateResultsSharingCompatibility in ShareModalDataLoader and remove this SeriesDataLoader component
// Setup dedicated edpoint to update closed_for_voting equivalent in new domain model
function SeriesDataLoader({ seriesId, ...props }: ShareProps) {
  const { data: series } = useSeries(seriesId, { suspense: true });

  const mutateSeries = useSeriesMutate(seriesId);
  const onUpdateActiveSeries: UpdateSeries = async (newData) => {
    try {
      mutateSeries({ ...series!, ...newData } as Series);
      await core().series.put(seriesId, newData);
    } catch {
      mutateSeries({ ...series! }, { revalidate: true });
    }
  };

  return (
    <Suspense fallback={<SkeletonLoader />}>
      <SlideDeckDataLoader
        {...props}
        seriesId={seriesId}
        series={series!} // Fetching inside a suspense boundary, so we can assume series is defined
        onUpdateActiveSeries={onUpdateActiveSeries}
      />
    </Suspense>
  );
}

export function ShareModal({
  open,
  setOpen,
  ...props
}: ShareProps & ShareModalLayoutProps) {
  return (
    <ShareModalLayout open={open} setOpen={setOpen}>
      <ErrorBoundary
        feature="paid-user-growth"
        errorMessage="Could not load share modal"
        fallback={<SkeletonLoader />}
      >
        <Suspense fallback={<SkeletonLoader />}>
          <CoreHooksProvider
            seriesId={props.seriesId}
            device_id="share"
            shouldSubscribe={false}
          >
            <SeriesDataLoader {...props} />
          </CoreHooksProvider>
        </Suspense>
      </ErrorBoundary>
    </ShareModalLayout>
  );
}
