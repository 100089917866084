import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export const CollaboratorsListLayout = React.forwardRef<
  HTMLDivElement,
  {
    id: string;
    children: React.ReactNode;
    highlight: boolean;
  }
>(({ id, children, highlight }, ref) => {
  return (
    <Box
      data-testid={id}
      width="100%"
      flexDirection="row"
      alignItems="center"
      ref={ref}
      my="space2"
      borderRadius="lg"
      bg={highlight ? 'infoWeakest' : 'transparent'}
      extend={({ theme }) => ({
        outlineColor: highlight ? theme.colors.infoWeakest : 'transparent',
        outlineWidth: highlight ? '8px' : '0px',
        outlineStyle: 'solid',
        transition: highlight
          ? 'background-color 0.2s ease, outline-color 0.2s ease, outline-width 0.2s ease'
          : 'background-color 1s ease, outline-color 1s ease, outline-width 1s ease',
      })}
    >
      {children}
    </Box>
  );
});

CollaboratorsListLayout.displayName = 'CollaboratorsListLayout';
