import {
  putResultsSharingRequest,
  putResultsSharingResponse,
  type PutResultsSharingRequest,
} from '@core-api/presentation-collection/presentation-collection/slide-deck/{slide_deck_id}/results-sharing';
import update from 'immutability-helper';
import type { SlideDeck } from '@mentimeter/presentation-collection-schema/api-types-overrides';
import { useCallback } from 'react';
import useSWRMutation, { type MutationFetcher } from 'swr/mutation';
import {
  makeRequest,
  type ApiRequestParams,
} from '../request-wrapper/make-request';

type MutationArguments = Omit<
  ApiRequestParams<PutResultsSharingRequest>,
  'region'
>;

interface UpdateSlideDeckResultsSharingCallbackProps {
  slideDeckId: string;
  resultsSharing: PutResultsSharingRequest['resultsSharing'];
}

const request: MutationFetcher<SlideDeck, string, MutationArguments> = async (
  _,
  { arg: { slideDeckId, resultsSharing } },
) => {
  const response = await fetch(
    makeRequest(putResultsSharingRequest, {
      slideDeckId,
      resultsSharing,
    }),
  );
  const slideDeck = await putResultsSharingResponse(response, {
    enableStatusCheck: true,
  });
  return slideDeck as SlideDeck;
};

export const usePresentationCollectionSlideDeckUpdateResultsSharing = (props: {
  seriesId: string;
  getSeriesCacheKey: (seriesId: string) => string | Array<string>;
}) => {
  const { seriesId, getSeriesCacheKey } = props;

  const { trigger } = useSWRMutation(getSeriesCacheKey(seriesId), request, {
    populateCache: true,
  });

  return useCallback(
    async (props: UpdateSlideDeckResultsSharingCallbackProps) => {
      return trigger<SlideDeck | undefined>(
        {
          slideDeckId: props.slideDeckId,
          resultsSharing: props.resultsSharing,
        },
        {
          optimisticData: (currentData) => {
            const { resultsSharing } = props;
            const updated = update(currentData, {
              ownershipSettings: { $merge: { resultsSharing } },
            });

            return updated;
          },
        },
      );
    },
    [trigger],
  );
};
