import type { PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import { Tooltip, TriggerDescriptive } from '@mentimeter/ragnar-ui/tooltip';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { SHARE_MODAL_TITLE_GAP } from '../constants';

interface ShareModalTitleProps extends TextT {
  id?: string | undefined;
  description?: string | undefined;
  isMobile?: boolean;
}

export function ShareModalTitle({
  id,
  description,
  isMobile,
  children,
  mb,
  ...rest
}: PropsWithChildren<ShareModalTitleProps>) {
  return (
    <Box
      flexDirection={description ? 'row' : undefined}
      mb={mb ?? SHARE_MODAL_TITLE_GAP}
    >
      <Heading
        id={id ? `${id}-label` : undefined}
        as="h2"
        mb={0}
        fontSize={2}
        fontWeight="semiBold"
        lineHeight="body"
        {...rest}
      >
        {children}
      </Heading>

      {description && id && (
        <Tooltip id={id} trigger={<TriggerDescriptive />} className="w-[250px]">
          {description}
        </Tooltip>
      )}
    </Box>
  );
}
