import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../box';
import { Label } from '../label';

export type RadioGroupItem = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | 'value'
  | 'disabled'
  | 'name'
  | 'checked'
  | 'readOnly'
  | 'onChange'
  | 'aria-label'
> &
  Omit<BoxT, 'onChange'>;

export const RadioGroupItem = React.forwardRef(
  (
    {
      value,
      disabled = false,
      checked,
      readOnly,
      name,
      onChange,
      extend,
      children,
      'aria-label': ariaLabel,
      className,
      ...boxProps
    }: RadioGroupItem,
    ref,
  ) => {
    const radioId = `${name}-${value}`;

    const defaultClasses = [
      'relative',
      'items-center',
      'justify-center',
      'p-1',
      'mr-2',
      'min-h-[32px]',
      'min-w-[32px]',
      'border-solid',
      'border-2',
      'rounded-lg',
      'border-default',
      'text',
      'cursor-pointer',
      'transition-colors duration-100 ease-in-out',
      'hover:z-[1]',
      'has-[:disabled]:bg-disabled',
      'has-[:disabled]:border-disabled',
      'has-[:disabled]:cursor-not-allowed',
      'has-[:disabled]:text-on-disabled',
      'has-[:checked]:border-secondary',
      'has-[:checked]:bg-secondary-weakest',
      'has-[:checked]:text-on-secondary-weakest',
      'has-[:checked]:z-[2]',
      '[&:has(input:not(:disabled))&:has(input:not(:checked))]:hover:border-strong',
      '[&:has(input:not(:disabled))&:has(input:not(:checked))]:hover:bg-input',
      '[&:has(input:not(:disabled):checked)]:hover:bg-secondary-weak',
      'has-[:focus-visible]:outline-4',
      'has-[:focus-visible]:outline',
      'has-[:focus-visible]:outline-interactive-focused',
      'has-[:focus-visible]:outline-offset-2',
    ];

    return (
      <Box
        ref={ref}
        extend={extend}
        className={clsx(defaultClasses, className)}
        {...boxProps}
      >
        <input
          id={radioId}
          name={name}
          type="radio"
          checked={checked}
          aria-label={ariaLabel}
          disabled={disabled}
          onChange={onChange}
          readOnly={readOnly}
          value={value}
          className={clsx([
            'absolute',
            'top-0',
            'right-0',
            'w-full',
            'h-full',
            'opacity-0',
            'cursor-pointer',
            'disabled:cursor-not-allowed',
          ])}
        />
        <Label
          htmlFor={radioId}
          disabled={disabled}
          className={clsx([
            'w-full',
            'mb-0',
            'font-normal',
            'text-center',
            'leading-normal',
            'z-[1]',
            'text-inherit',
          ])}
        >
          {children}
        </Label>
      </Box>
    );
  },
);
