import { format } from 'date-fns/format';
import { formatDistance } from 'date-fns/formatDistance';
import { isAfter } from 'date-fns/isAfter';
import { parseISO } from 'date-fns/parseISO';
import { subDays } from 'date-fns/subDays';

export const fromNow = (time: Date) =>
  formatDistance(time, Date.now(), { addSuffix: true });

export const formatRelativeDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }
  const time = parseISO(dateString);
  return isAfter(time, subDays(Date.now(), 2))
    ? fromNow(time)
    : formatDate(dateString);
};

export const formatDate = (dateString?: string | null) =>
  dateString ? format(parseISO(dateString), 'LLL d, yyyy') : '';

export function getRelativeTimeText(time: string) {
  return formatRelativeDate(time).replace('about', '');
}
