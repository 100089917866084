import type { PutResultsSharingRequest } from '@core-api/presentation-collection/presentation-collection/slide-deck/{slide_deck_id}/results-sharing';
import { useIsSlideDeckMigrated } from '@mentimeter/compatibility/migration-status';
import {
  // eslint-disable-next-line no-restricted-imports
  useSeries,
  // eslint-disable-next-line no-restricted-imports
  useSeriesMutate,
  type UpdateSeries,
} from '@mentimeter/core-hooks';
import { core, type Series } from '@mentimeter/http-clients';
import { MentiError } from '@mentimeter/errors/sentry';
import { useCallback } from 'react';
import { usePresentationCollectionSlideDeckUpdateResultsSharing } from '../../slide-deck/use-presentation-collection-slide-deck-update-results-sharing';
import { usePresentationCollectionSlideDeck } from '../../use-presentation-collection-slide-deck';

interface UpdateSlideDeckResultsSharingCallbackArgs {
  resultsSharing: PutResultsSharingRequest['resultsSharing'];
}

export const usePresentationCollectionSlideDeckUpdateResultsSharingCompatibility =
  (config: {
    seriesId: string;
    getSeriesCacheKey: (seriesId: string) => string | Array<string>;
  }) => {
    const { lazyGetSeries } = usePresentationCollectionSlideDeck(
      config.seriesId,
      {
        getSeriesCacheKey: config.getSeriesCacheKey,
      },
    );
    const isMigrated = useIsSlideDeckMigrated();

    const { data: legacySeries } = useSeries(config.seriesId, {
      suspense: true,
    });

    const mutateSeries = useSeriesMutate(config.seriesId, {
      getSeriesCacheKey: config.getSeriesCacheKey,
    });

    const legacyUpdateSeries: UpdateSeries = useCallback(
      async (newData) => {
        const series = lazyGetSeries();
        try {
          mutateSeries({ ...legacySeries!, ...newData } as Series);
          await core().series.put(config.seriesId, newData);
        } catch {
          mutateSeries({ ...series! }, { revalidate: true });
        }
      },
      [config.seriesId, lazyGetSeries, legacySeries, mutateSeries],
    );

    const updateResultsSharing =
      usePresentationCollectionSlideDeckUpdateResultsSharing(config);

    return useCallback(
      async (props: UpdateSlideDeckResultsSharingCallbackArgs) => {
        const series = lazyGetSeries();

        if (!series) {
          throw new MentiError('Series not found', {
            feature: 'compatibility-layer',
          });
        }
        if (!isMigrated) {
          return legacyUpdateSeries({ results_sharing: props.resultsSharing });
        } else {
          return updateResultsSharing({
            ...props,
            slideDeckId: series.slideDeckId,
          });
        }
      },
      [isMigrated, lazyGetSeries, legacyUpdateSeries, updateResultsSharing],
    );
  };
