import { useMatch } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ShareModalSection } from '../../ShareModalSection';
import { ShareModalTitle } from '../../ShareModalTitle';
import { ShareModalNotification } from '../../ShareModalNotification';
import { SHARE_MODAL_TITLE_GAP } from '../../../constants';
import {
  PresentationAccessMenu,
  type PresentationAccessMenuProps,
} from './PresentationAccessMenu';

interface LinkResultsSectionProps extends PresentationAccessMenuProps {
  state: 'idle' | 'copying' | 'copied';
  onCopy: () => void;
}

export function LinkToSlidesSection({
  state,
  resultsSharing,
  onCopy,
  ...presentationAccessProps
}: LinkResultsSectionProps) {
  const isMobile = useMatch({ lessThan: 3 });

  return (
    <ShareModalSection>
      <Box extend={() => ({ flex: '1 1 auto' })}>
        <ShareModalTitle
          id="live-results"
          isMobile={isMobile}
          description="Use this link to let people see all slides and live results."
        >
          Link to slides
        </ShareModalTitle>

        <PresentationAccessMenu
          resultsSharing={resultsSharing}
          {...presentationAccessProps}
        />

        {resultsSharing === 'off' && (
          <ShareModalNotification
            theme="neutral"
            description="The live results link will show a closed presentation."
            mt={SHARE_MODAL_TITLE_GAP}
          />
        )}
      </Box>
      <Box minWidth="100px" alignItems="end">
        <Button
          variant="subtle"
          state={state === 'copying' ? 'loading' : undefined}
          onClick={onCopy}
        >
          {state === 'idle' ? 'Copy link' : 'Copied link!'}
        </Button>
      </Box>
    </ShareModalSection>
  );
}
