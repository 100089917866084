import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { CollaboratorAccessDropdown } from './CollaboratorAccessDropdown';
import { OwnerDetails } from './CollaboratorDetails';
import { RemoveCollaborator } from './RemoveCollaborator';

export const IndividualPermissionDetails = ({
  isOwner,
  collaboratorIsCurrentUser,
  allowRemoveCollaborator,
  collaborator,
  onRemoveAccess,
  displayIndividualPermissionWithModal,
}: {
  isOwner: boolean;
  collaboratorIsCurrentUser: boolean;
  allowRemoveCollaborator: boolean;
  collaborator: SeriesCollaborator;
  onRemoveAccess: () => Promise<void>;
  displayIndividualPermissionWithModal: boolean;
}) => {
  if (isOwner) return <OwnerDetails />;

  if (displayIndividualPermissionWithModal)
    return (
      <CollaboratorAccessDropdown
        collaborator={collaborator}
        onRemoveAccess={onRemoveAccess}
        collaboratorIsCurrentUser={collaboratorIsCurrentUser}
      />
    );

  if (allowRemoveCollaborator)
    return (
      <RemoveCollaborator
        collaborator={collaborator}
        onRemoveEditAccess={onRemoveAccess}
      />
    );

  return null;
};
