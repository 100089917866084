import { PencilIcon } from '@mentimeter/ragnar-visuals';
import type { Dispatch, SetStateAction } from 'react';
import { ContextMenu } from '../ContextMenu';

interface RenameProps {
  setRenameModalOpen: Dispatch<SetStateAction<boolean>>;
}
export function Rename({ setRenameModalOpen }: RenameProps) {
  const handleSelect = () => setRenameModalOpen(true);
  return (
    <ContextMenu.Item onSelect={handleSelect}>
      <ContextMenu.ItemIcon>
        <PencilIcon />
      </ContextMenu.ItemIcon>
      <ContextMenu.ItemLabel color="text">Rename</ContextMenu.ItemLabel>
    </ContextMenu.Item>
  );
}
