import type { CoreClient, Series } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { getRegionBySeriesId } from '@mentimeter/region';
import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSWRConfig } from 'swr';
import { seriesCacheKey } from './cache-keys';
import { useCoreHookContext } from './core-context';
import type { UseSeries, WrappedMutator } from './types';
import { useData } from './use-data';

type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

/**
 * @deprecated This hook is deprecated because it exclusively targets our legacy series API. Either make use of an existing migration-aware API hook (such as the authenticated-only {@link https://github.com/mentimeter/mm-js/blob/cf2d58caf5ce3849268c5585ce342a16f2cc6ee4/packages/editor/editor-data-hooks/src/use-editor-slide-deck.ts#L28 useEditorSlideDeck} or the public {@link https://github.com/mentimeter/mm-js/blob/15dcb00c5942954bd36e967a29696ad94b7e01c8/packages/presentation/presentation-data-hooks/src/slide-deck/use-presentation-slide-deck.ts#L14 usePresentationSlideDeck}), or implement your own.
 */
export const useSeries: UseSeries = (seriesId, config) => {
  const cacheKey = seriesId ? seriesCacheKey(seriesId) : undefined;
  const { device_id } = useCoreHookContext();
  const { data, mutate, error, revalidate, lazyData } = useData<Series>(
    {
      cacheKey,
      seriesId,
      fetcher: async () => {
        const { data } = await core({
          region: getRegionBySeriesId(seriesId as string),
        }).series.get(seriesId as string);
        return data;
      },
    },
    config,
  );

  const apiCall: OmitFirstArg<CoreClient['series']['put']> = React.useCallback(
    (newData, config) =>
      core().series.put(
        // @ts-expect-error TS(2345)
        seriesId,
        {
          ...newData,
          device_id,
        },
        config,
      ),
    [device_id, seriesId],
  );

  const update: ReturnType<UseSeries>['update'] = React.useCallback(
    async (newData, batchedStateUpdate, config) => {
      const { data: response } = await apiCall(newData, config);

      unstable_batchedUpdates(() => {
        mutate<Series>(response, { revalidate: false });
        if (batchedStateUpdate) {
          batchedStateUpdate();
        }
      });

      return response;
    },
    [apiCall, mutate],
  );

  return {
    data,
    lazyData,
    revalidate,
    error,
    update,
  };
};

type UseSeriesMutate = (
  seriesId: string,
  options?: { getSeriesCacheKey: (seriesId: string) => string | Array<string> },
) => WrappedMutator<Series>;

/**
 * @deprecated This hook is deprecated because it exclusively targets our legacy series API.
 * More so, this all-encompassing hook should be replaced with task-specific hooks
 * (such as {@link https://github.com/mentimeter/mm-js/blob/bdaa126612d03d22838435acb7a23746c40efd52/applications/editor/src/features/core/presentation/shared/compatibility/slide-deck/use-presentation-update-qa-settings-compatibility.ts#L19 usePresentationUpdateQaSettingsCompatibility}).
 */
export const useSeriesMutate: UseSeriesMutate = (seriesId, options) => {
  const getCacheKey = options?.getSeriesCacheKey ?? seriesCacheKey;
  const { mutate } = useSWRConfig();
  return React.useCallback<WrappedMutator<Series>>(
    (data, options) => {
      mutate(getCacheKey(seriesId), data, {
        revalidate: false,
        ...options,
      });
    },
    [mutate, getCacheKey, seriesId],
  );
};
