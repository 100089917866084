// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface PostDuplicateRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The Id of the source Series */
  slideDeckPublicKey: string;
}

export function postDuplicateRequest(
  requestParams: PostDuplicateRequest,
): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation-collection/${requestParams.slideDeckPublicKey}/duplicate`;
  return new Request(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
