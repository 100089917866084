import type { PropsWithChildren } from 'react';
import { type BoxT, Box } from '@mentimeter/ragnar-ui/box';

export function ShareModalSection({
  children,
  ...props
}: PropsWithChildren<BoxT>) {
  return (
    <Box
      as="section"
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      {children}
    </Box>
  );
}
