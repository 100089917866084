import { useMatch } from '@mentimeter/ragnar-device';
import { LinkButton } from '@mentimeter/next-navigation';
import { useCallback } from 'react';
import type { TrackingContext, Pace } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ShareModalSection } from '../../ShareModalSection';
import { ShareModalTitle } from '../../ShareModalTitle';
import { ShareModalText } from '../../ShareModalText';

interface QRCodeSectionProps {
  id: string;
  pace: Pace;
  voteUrl: string;
  context: TrackingContext;
}

export function QRCodeSection({
  id,
  pace,
  voteUrl,
  context,
}: QRCodeSectionProps) {
  // Convert vote link to base64 for easier route parsing
  const QR_CODE_PAGE = `/app/presentation/qr-code/${btoa(voteUrl)}`;
  const { mode } = pace;
  const isMobile = useMatch({ lessThan: 3 });

  const onDownloadClick = useCallback(() => {
    trackUser({
      event: 'Downloaded QR code',
      properties: {
        'series id': id,
        pace: mode,
        placement: 'Participation tab',
        context,
      },
    });
  }, [context, id, mode]);

  return (
    <ShareModalSection title="QR code">
      <Box extend={() => ({ flex: '1 1 auto' })}>
        <ShareModalTitle id="voting-code" isMobile={isMobile}>
          QR code
        </ShareModalTitle>
        <ShareModalText>
          Add participants to your Menti via QR code
        </ShareModalText>
      </Box>
      <Box>
        <LinkButton
          variant="subtle"
          width={['100%', 'auto']}
          href={QR_CODE_PAGE}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onDownloadClick}
        >
          Download
        </LinkButton>
      </Box>
    </ShareModalSection>
  );
}
