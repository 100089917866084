import React, { useState, useMemo, forwardRef } from 'react';
import { TrackingContext } from '@mentimeter/http-clients';
import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { RemoveCollaboratorModal } from './RemoveCollaboratorModal';
import { CollaboratorDetails } from './collaborators-list/CollaboratorDetails';
import { CollaboratorsListLayout } from './collaborators-list/CollaboratorsListLayout';
import { hasViewPermission } from './_utils';
import { IndividualPermissionDetails } from './collaborators-list/IndividualPermissionDetails';
import { handleRemoveSeriesCollaborator } from './removeCollaboratorsRequest';
import type { UserCollaborationRole } from './CollaborationFolderDataHandler';
import type { CollaborationResourceType } from './ManageCollaboratorsUi';

interface PropsT {
  resourceType: CollaborationResourceType;
  ownerId: number;
  currentUserId: number;
  collaborators: SeriesCollaborator[];
  updateCollaborators: () => Promise<void>;
  setError: (errorMsg: string | null) => void;
  userCollaborationRole: UserCollaborationRole;
  context: TrackingContext;
  placement: string;
  newlyAddedCollaboratorEmails: string[];
  collaboratorRefs: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
}

interface CollaboratorItemT {
  collaborator: SeriesCollaborator;
  removeCollaborator: (collaborator: SeriesCollaborator) => Promise<any>;
  collaboratorIsCurrentUser: boolean;
  isOwner: boolean;
  allowRemoveCollaborator: boolean;
  isCurrentUserOwner: boolean;
  resourceType: CollaborationResourceType;
  highlight: boolean;
}

const CollaboratorItem = forwardRef<HTMLDivElement, CollaboratorItemT>(
  (
    {
      collaborator,
      removeCollaborator,
      isOwner,
      collaboratorIsCurrentUser,
      allowRemoveCollaborator,
      isCurrentUserOwner,
      resourceType,
      highlight,
    },
    ref,
  ) => {
    const [show, setShowModal] = useState(false);

    const handleRemoveAccess = async () => {
      if (collaboratorIsCurrentUser && !isOwner) setShowModal(true);
      else await removeCollaborator(collaborator);
    };

    const displayIndividualPermissionWithModal = collaborator.type === 'user';

    const isUserOrFolderCollaborator =
      collaborator.type === 'user' ||
      collaborator.type === 'folder-collaborator';

    const showViewPermissionTooltip =
      isCurrentUserOwner &&
      !displayIndividualPermissionWithModal &&
      isUserOrFolderCollaborator &&
      hasViewPermission(collaborator.access, isOwner);

    return (
      <>
        <CollaboratorsListLayout
          ref={ref}
          highlight={highlight}
          id={`collaborator-${collaborator.entity.id}`}
        >
          <CollaboratorDetails
            collaboratorUserName={collaborator.entity.name}
            collaboratorUserId={collaborator.entity.id}
            collaboratorUserEmail={collaborator.entity.email}
            collaboratorProfilePictureUrl={
              collaborator.entity.profilePictureUrl
            }
            collaboratorType={collaborator.type}
            collaboratorIsCurrentUser={collaboratorIsCurrentUser}
            showViewPermissionTooltip={showViewPermissionTooltip}
          />

          <IndividualPermissionDetails
            isOwner={isOwner}
            collaboratorIsCurrentUser={collaboratorIsCurrentUser}
            allowRemoveCollaborator={allowRemoveCollaborator}
            collaborator={collaborator}
            onRemoveAccess={handleRemoveAccess}
            displayIndividualPermissionWithModal={
              displayIndividualPermissionWithModal
            }
          />
        </CollaboratorsListLayout>
        <RemoveCollaboratorModal
          resourceType={resourceType}
          show={show}
          onDismiss={() => setShowModal(false)}
          email={collaborator.entity.email}
          onRemoveCollaborator={async () => {
            await removeCollaborator(collaborator);
          }}
        />
      </>
    );
  },
);

export const CollaboratorsList = ({
  collaboratorRefs,
  resourceType,
  currentUserId,
  updateCollaborators,
  collaborators,
  ownerId,
  setError,
  userCollaborationRole,
  context,
  placement,
  newlyAddedCollaboratorEmails,
}: PropsT) => {
  const collaboratorItems = useMemo(
    () =>
      collaborators.map((c) => {
        const collaboratorIsCurrentUser = String(currentUserId) === c.entity.id;
        const isCurrentUserOwner = currentUserId === ownerId;
        const collaboratorIsPresentationOwner = c.entity.id === String(ownerId);

        const removeSelfWhenFolderOwner =
          context === TrackingContext.MyPresentations &&
          collaboratorIsCurrentUser;
        const allowRemoveCollaborator =
          !collaboratorIsPresentationOwner && !removeSelfWhenFolderOwner;

        const highlight = newlyAddedCollaboratorEmails.includes(c.entity.email);

        return (
          <CollaboratorItem
            ref={(el: HTMLDivElement | null) => {
              collaboratorRefs.current[c.entity.email] = el;
            }}
            highlight={highlight}
            key={c.entity.email}
            collaborator={c}
            removeCollaborator={async (collaborator) =>
              await handleRemoveSeriesCollaborator({
                collaborator,
                placement,
                trackingContext: context,
                userCollaborationRole,
                updateCollaborators,
                onError: () =>
                  setError(
                    "This person's edit access couldn't be removed. Try again.",
                  ),
              })
            }
            isOwner={collaboratorIsPresentationOwner}
            collaboratorIsCurrentUser={collaboratorIsCurrentUser}
            allowRemoveCollaborator={allowRemoveCollaborator}
            isCurrentUserOwner={isCurrentUserOwner}
            resourceType={resourceType}
          />
        );
      }),
    [
      collaboratorRefs,
      collaborators,
      context,
      currentUserId,
      newlyAddedCollaboratorEmails,
      ownerId,
      placement,
      userCollaborationRole,
      resourceType,
      setError,
      updateCollaborators,
    ],
  );

  return collaboratorItems;
};
