import type { ColorsEnum } from '@mentimeter/ragnar-dsc';
import { CheckIcon, ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import {
  DropdownMenuContainer,
  DropdownMenuItemIcon,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';

export interface BaseOption<Value> {
  id: string;
  value: Value;
  label: string;
  paywall?: string;
  subtitle?: string;
}

interface SelectPopoverProps<Value> {
  id: string;
  selectedOption: BaseOption<Value>;
  options: BaseOption<Value>[];
  onChange: (selectedOption: Value) => void;
  disabled?: boolean;
  color?: ColorsEnum;
  fontWeight?: TextT['fontWeight'];
}

export function SelectPopover<Value>({
  id,
  selectedOption,
  options,
  onChange,
  disabled = false,
  color = 'primary',
  fontWeight = 'regular',
}: SelectPopoverProps<Value>) {
  return (
    <DropdownMenuRoot aria-labelledby={`${id}-label`}>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Clickable
          id={id}
          disabled={disabled}
          alignItems="center"
          flexDirection="row"
          aria-haspopup="true"
          aria-controls={`${id}-popover`}
        >
          <Text
            fontSize={2}
            color={disabled ? 'text' : color}
            mr={1}
            id={`${id}-label`}
            fontWeight={fontWeight}
          >
            {selectedOption.label}
          </Text>
          <ChevronDownIcon color={disabled ? 'textWeaker' : color} size={2} />
        </Clickable>
      </DropdownMenuTrigger>
      <DropdownMenuContainer id={`${id}-popover`}>
        <DropdownMenuRadioGroup
          value={selectedOption.value as string}
          onValueChange={(value) => {
            onChange(value as Value);
          }}
        >
          {options.map((option) => (
            <DropdownMenuRadioItem
              key={option.id}
              value={option.value as string}
              id={`${option.id}-paywall`}
              extend={() => ({ userSelect: 'none' })}
              onClick={(e) => {
                if (option.paywall) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <DropdownMenuItemIcon>
                {option.value === selectedOption.value && (
                  <CheckIcon size={2} color="inherit" />
                )}
              </DropdownMenuItemIcon>
              <Box justifyContent="center">
                <Text color="inherit" fontSize={2}>
                  {option.label}
                </Text>
                {option.subtitle && (
                  <Text color="textWeaker" fontSize="12px">
                    {option.subtitle}
                  </Text>
                )}
              </Box>
              {option.paywall && (
                <>
                  <TooltipOld
                    id={`${option.id}-paywall-tooltip`}
                    referenceId={`${option.id}-paywall`}
                    description={option.paywall}
                  />
                  <Badge type="upgrade" compact />
                </>
              )}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContainer>
    </DropdownMenuRoot>
  );
}
