import React from 'react';
import type { Extend } from '@mentimeter/ragnar-dsc';
import type { ClickableT } from '@mentimeter/ragnar-ui/clickable';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';

const buttonOverrideProps: Extend = ({ theme }) => ({
  borderRadius: `${theme.radii[2]}px`,
  padding: 0,
  border: 0,
});

const cardProps: Extend = () => ({
  width: '100%',
  scrollSnapAlign: 'start',
  '@media (hover: hover)': {
    ':hover': {
      opacity: 1,
    },
  },
});

const previewCardProps: Extend = (extendProps) => ({
  ...cardProps(extendProps),
  '& a:hover': {
    opacity: 1,
  },

  /*
    Note 2023-04-12:
      When clicking on buttons inside the card it will trigger the active state of the parent button/anchor.
      This disables the cards active effect.
  */
  '& a:active': {
    opacity: 1,
  },
});

export function HoverPreviewCard({
  onClick = () => {},
  children,
  ...rest
}: BoxT) {
  return (
    <Box onClick={onClick} extend={previewCardProps} {...rest}>
      {children}
    </Box>
  );
}

export function HoverButtonPreviewCard({
  onClick = () => {},
  children,
  ...rest
}: ClickableT) {
  return (
    <Clickable onClick={onClick} extend={previewCardProps} {...rest}>
      {children}
    </Clickable>
  );
}

export function HoverButtonCard({
  onClick = () => {},
  children,
  ...rest
}: ButtonT) {
  return (
    <Button
      onClick={onClick}
      {...rest}
      extend={({ theme }) => ({
        ...buttonOverrideProps({ theme }),
        ...cardProps({ theme }),
      })}
    >
      {children}
    </Button>
  );
}

export function HoverClickableCard({
  onClick = () => {},
  children,
  ...rest
}: ClickableT) {
  return (
    <Clickable onClick={onClick} extend={cardProps} {...rest}>
      {children}
    </Clickable>
  );
}
