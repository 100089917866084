import { forwardRef, useMemo, useState } from 'react';
import {
  type TrackingContext,
  type CollaboratorType,
} from '@mentimeter/http-clients';
import type { ResourceEntity } from '@core-api/collaboration/types/response';
import {
  CollaboratorDetails,
  OwnerDetails,
} from './collaborators-list/CollaboratorDetails';
import { CollaboratorsListLayout } from './collaborators-list/CollaboratorsListLayout';
import { RemoveCollaboratorCross } from './collaborators-list/RemoveCollaboratorCross';
import { RemoveCollaboratorModal } from './RemoveCollaboratorModal';
import { RemoveFolderCollaboratorHint } from './collaborators-list/RemoveFolderCollaboratorHint';
import { hasViewPermission } from './_utils';
import { handleRemoveFolderCollaborator } from './removeCollaboratorsRequest';
import type { CollaborationResourceType } from './ManageCollaboratorsUi';

interface CollaboratorItem {
  collaborator: ResourceEntity;
  collaboratorIsCurrentUser: boolean;
  isOwner: boolean;
  isCurrentUserOwner: boolean;
  updateCollaborators: () => Promise<void>;
  sharedViaParentFolder: boolean;
  resourceType: CollaborationResourceType;
  trackingContext: TrackingContext;
  highlight: boolean;
}

const CollaboratorItem = forwardRef<HTMLDivElement, CollaboratorItem>(
  (
    {
      collaborator,
      isOwner,
      collaboratorIsCurrentUser,
      isCurrentUserOwner,
      updateCollaborators,
      sharedViaParentFolder,
      resourceType,
      trackingContext,
      highlight,
    },
    ref,
  ) => {
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const {
      id,
      access,
      entityId,
      entityType,
      entity: { name, email, profilePictureUrl },
      resourceId,
    } = collaborator;

    const allowChanges = !isOwner;

    const isUserOrFolderCollaborator =
      entityType === 'user' || entityType === 'folder-collaborator';

    const showViewPermissionTooltip =
      isUserOrFolderCollaborator && hasViewPermission(access, isOwner);

    const handleRemoveCollaborator = async () =>
      handleRemoveFolderCollaborator({
        id,
        entityType,
        trackingContext,
        role: isCurrentUserOwner ? 'Owner' : 'Collaborator',
        updateCollaborators,
      });

    const handleRemoveEditAccess = async () => {
      if (collaboratorIsCurrentUser && !isOwner) setShowRemoveModal(true);
      else await handleRemoveCollaborator();
    };

    const unshare = sharedViaParentFolder ? (
      <RemoveFolderCollaboratorHint
        tooltipText="You can remove access in the top-level folder containing this folder"
        collaboratorUserId={entityId}
        resourceIdToRemove={resourceId}
      />
    ) : (
      <RemoveCollaboratorCross
        collaboratorUserId={entityId}
        collaboratorDisplayName={name}
        onRemoveEditAccess={handleRemoveEditAccess}
        isInvitee={entityType === 'invitee' || entityType === 'folder-invitee'}
      />
    );

    return (
      <>
        <CollaboratorsListLayout
          ref={ref}
          highlight={highlight}
          id={`collaborator-${entityId}`}
        >
          <CollaboratorDetails
            collaboratorUserName={name}
            collaboratorUserId={entityId}
            collaboratorUserEmail={email}
            collaboratorProfilePictureUrl={profilePictureUrl}
            collaboratorType={entityType as CollaboratorType}
            collaboratorIsCurrentUser={collaboratorIsCurrentUser}
            showViewPermissionTooltip={showViewPermissionTooltip}
          />
          {isOwner && <OwnerDetails />}
          {allowChanges && unshare}
        </CollaboratorsListLayout>
        <RemoveCollaboratorModal
          show={showRemoveModal}
          onDismiss={() => setShowRemoveModal(false)}
          email={email}
          onRemoveCollaborator={handleRemoveCollaborator}
          resourceType={resourceType}
        />
      </>
    );
  },
);

interface Props {
  resourceType: CollaborationResourceType;
  ownerId: number;
  currentUserId: number;
  collaborators: ResourceEntity[];
  updateCollaborators: () => Promise<void>;
  currentFolderId: string;
  trackingContext: TrackingContext;
  newlyAddedCollaboratorEmails: string[];
  collaboratorRefs: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
}

export const FolderCollaboratorsList = ({
  resourceType,
  currentUserId,
  collaborators,
  ownerId,
  updateCollaborators,
  currentFolderId,
  trackingContext,
  newlyAddedCollaboratorEmails,
  collaboratorRefs,
}: Props) => {
  const collaboratorItems = useMemo(
    () =>
      collaborators.map((c) => {
        const highlight = newlyAddedCollaboratorEmails.includes(
          String(c.entity.email),
        );

        return (
          <CollaboratorItem
            ref={(el: HTMLDivElement | null) => {
              collaboratorRefs.current[c.entity.email] = el;
            }}
            highlight={highlight}
            key={c.id}
            collaborator={c}
            isOwner={c.entityId === String(ownerId)}
            collaboratorIsCurrentUser={c.entityId === String(currentUserId)}
            isCurrentUserOwner={ownerId === currentUserId}
            updateCollaborators={updateCollaborators}
            sharedViaParentFolder={currentFolderId !== c.resourceId}
            resourceType={resourceType}
            trackingContext={trackingContext}
          />
        );
      }),
    [
      collaborators,
      ownerId,
      currentUserId,
      updateCollaborators,
      currentFolderId,
      trackingContext,
      newlyAddedCollaboratorEmails,
      resourceType,
      collaboratorRefs,
    ],
  );

  return collaboratorItems;
};
