import { postResourceSharingsRequest } from '@core-api/collaboration/resource-sharings/{entity_type}/{resource_type}';
import userCache from '@mentimeter/user';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { TrackingContext } from '@mentimeter/http-clients';
import { CROSS_REGION_ERROR } from './_utils';
import { trackAddedCollaborators } from './trackAddedCollaborators';
import type { InviteItem } from './AddCollaborators';
import type { UserCollaborationRole } from './CollaborationFolderDataHandler';
import type { CollaborationResourceType } from './ManageCollaboratorsUi';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';
interface AddCollaboratorsRequestParams {
  setButtonState: (state: RagnarButtonState | undefined) => void;
  selectedItems: InviteItem[];
  resourceId: string;
  updateCollaborators: () => Promise<void>;
  setError: (error: string | null) => void;
  userCollaborationRole: UserCollaborationRole;
  trackingContext: TrackingContext;
  userPlan: string;
  resourceType: CollaborationResourceType;
  message: string | null;
}

export const addCollaboratorsRequest = async ({
  setButtonState,
  selectedItems,
  resourceId,
  updateCollaborators,
  setError,
  userCollaborationRole,
  trackingContext,
  userPlan,
  resourceType,
  message,
}: AddCollaboratorsRequestParams): Promise<boolean> => {
  setError(null);
  setButtonState('loading');

  if (selectedItems?.length === 0 || selectedItems[0] === undefined) {
    return true;
  }

  try {
    const region = userCache.region;
    const sessionToken = userCache.getToken();
    const entityIdentifiers = selectedItems.map((item) => item.value);

    const response = await fetch(
      postResourceSharingsRequest({
        region,
        userAuth: sessionToken,
        entityType: 'user',
        resourceType,
        entityIdentifiers,
        resourceId,
        message,
      }),
    );

    if (response.ok) {
      await updateCollaborators();
      setButtonState('success');

      trackAddedCollaborators({
        user_emails: entityIdentifiers,
        context: trackingContext,
        role: userCollaborationRole,
        folder: resourceType === 'folder',
        plan: userPlan,
        placement: TrackingContext.Results
          ? 'Invite modal'
          : 'Collaboration tab',
      });

      return true;
    }

    let errorMessage = DEFAULT_ERROR_MESSAGE;

    if (![401, 403].includes(response.status)) {
      const { code, message, error } = await response.json();

      if (code === 'region_mismatch') {
        errorMessage = CROSS_REGION_ERROR;
      } else if (error) {
        errorMessage = error;
      } else if (message) {
        errorMessage = message;
      }

      captureException(
        new MentiError(`Failed to add collaborator to ${resourceType}`, {
          feature: 'collaboration',
        }),
        {
          tags: {
            add_collaborator_error_reason: message,
          },
        },
      );
    }

    setButtonState('error');
    setError(errorMessage);
    return false;
  } catch (error) {
    setButtonState('error');
    setError(DEFAULT_ERROR_MESSAGE);

    captureException(
      new MentiError('Failed to add collaborator', {
        feature: 'collaboration',
        cause: error,
      }),
    );

    return false;
  }
};
