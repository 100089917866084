import { addSeconds } from 'date-fns/addSeconds';
import useSWR from 'swr';
import {
  postRenewRequest,
  postRenewResponse,
  type PostRenewRequest,
  type PostRenewResponse,
} from '@core-api/vote-code/vote-code/{slideDeckPublicKey}/renew';
import userCache from '@mentimeter/user';
import { formatDistance } from 'date-fns/formatDistance';
import { MentiError } from '@mentimeter/errors/sentry';
import { formatVoteCode } from './formatVoteCode';

const fromNow = (time: Date) =>
  formatDistance(time, Date.now(), { addSuffix: true });

async function fetchVoteCode(
  slideDeckPublicKey: PostRenewRequest['slideDeckPublicKey'],
  ttlInDays?: 2 | 7 | 14 | null,
) {
  const region = userCache.region;
  const userAuth = userCache.getToken();

  if (!region || !userAuth) {
    throw new MentiError('User not authenticated to renew the vote code', {
      feature: 'vote-code',
    });
  }
  const ttl = ttlInDays ? (ttlInDays * 60 * 60 * 24).toString() : null;

  const response = await fetch(
    postRenewRequest({
      region,
      userAuth,
      slideDeckPublicKey,
      ttl: (ttl as PostRenewRequest['ttl']) ?? null,
    }),
  );

  return await postRenewResponse(response);
}

export interface VoteCode {
  formatted: string;
  expirationText: string;
  hasLongValidity: boolean;
  error: MentiError | null;
  setTTLInDays: (
    days: 2 | 7 | 14,
  ) => Promise<PostRenewResponse | void | undefined>;
}

export function useVoteCode(slideDeckPublicKey: string): VoteCode {
  const { data, error, mutate } = useSWR(
    `/series/${slideDeckPublicKey}/vote-code`,
    () => fetchVoteCode(slideDeckPublicKey),
    {
      revalidateIfStale: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      errorRetryInterval: 4000,
      refreshInterval: (currentData) => currentData?.expiresIn ?? 0,
      refreshWhenHidden: true,
    },
  );

  const formatted = data ? formatVoteCode(data.voteCodeId) : '';
  const expirationText = data
    ? fromNow(addSeconds(Date.now(), data.expiresInSeconds))
    : '';
  const hasLongValidity = data ? data.expiresInSeconds > 60 * 60 * 24 : false; // More than one day

  return {
    formatted,
    expirationText,
    hasLongValidity,
    error,
    async setTTLInDays(days) {
      return mutate(fetchVoteCode(slideDeckPublicKey, days), {
        revalidate: false,
      });
    },
  };
}
