// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PutAccessRequest {
  region: 'eu' | 'us';
  userAuth: string;
  seriesId: string;
  collaboratorId: number;
  /** The access level to grant to the user */
  access: 'view' | 'edit' | 'comment';
}

export function putAccessRequest(requestParams: PutAccessRequest): Request {
  const data = {
    series_id: toSnakeKeys(requestParams.seriesId),
    collaborator_id: toSnakeKeys(requestParams.collaboratorId),
    access: toSnakeKeys(requestParams.access),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/collaboration/series/access`;
  return new Request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
