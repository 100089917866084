import { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';

export const useTeamShare = () => {
  const { user } = useUser();

  if (!user) return { enabled: false };

  return {
    enabled:
      user.isFreeUser || user.features.type === UserFeaturesTypeEnum.BASIC,
  };
};
