import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

export function ErrorFallback() {
  return (
    <Box width="100%" justifyContent="center" alignItems="center">
      <Text>Something went wrong on our end, please reload the page</Text>
    </Box>
  );
}
