// This file is automatically generated. DO NOT EDIT.

import { type DashboardNode } from '../../types/response/DashboardNode';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface GetMoveInfoResponse {
  /** List of dashboards containing folders */
  data: Array<DashboardNode>;
}

export async function getMoveInfoResponse(
  response: Response,
  options?: GetJSONResponseOptions<GetMoveInfoResponse>,
): Promise<GetMoveInfoResponse> {
  return getJSONResponse<GetMoveInfoResponse>(
    response,
    'getMoveInfoResponse',
    options,
  );
}
