import type { GetV2Response } from '@core-api/presentation-collection/presentation-collection/v2/{dashboard_type}';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import type {
  PresentationQueryParamsBase,
  PresentationResponse,
  PresentationsResponse,
  PrivatePresentationsQueryParams,
} from '@mentimeter/http-clients';
import type { SWRConfiguration } from 'swr';
import type { SWRInfiniteConfiguration } from 'swr/infinite';

export interface PresentationsBaseProps {
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  folderId?: number | null;
}

export const swrPresentationPageFolderConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  keepPreviousData: true,
};

export const swrPresentationPageConfig: SWRInfiniteConfiguration = {
  ...swrPresentationPageFolderConfig,
  revalidateAll: true,
};

const getQueryString = (params: PresentationsBaseProps) =>
  Object.entries(params)
    .map(([key, value]) => key + '=' + encodeURIComponent(value))
    .join('&');

export const getKey = (
  pageIndex: number,
  previousPageData: PresentationsResponse | GetV2Response | null,
  params: PresentationsBaseProps | PrivatePresentationsQueryParams,
  path: 'me' | 'shared-with-me' | 'workspace',
) => {
  if (previousPageData?.data && !previousPageData.data?.length) return null;

  params.page = pageIndex + 1;
  return `/presentations/${path}?${getQueryString(params)}`;
};

export const isTemplate = (presentation: SlideDeckOverview) => {
  return Boolean(
    presentation.isSharedWithOrganization ||
      Boolean(
        presentation.sharedWithGroups &&
          presentation.sharedWithGroups.length > 0,
      ),
  );
};

export const convertKeysCamelToSnake = (
  obj: PresentationsBaseProps,
): PresentationQueryParamsBase => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const snakeCaseKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`,
      );
      return [snakeCaseKey, value];
    }),
  );
};

export const calculateHasReachedLastPage = (
  data: PresentationsResponse[] | GetV2Response[] | undefined,
) => {
  if (!data) return false;

  const currentPage = data[data.length - 1]?.page;
  const totalPages = data[data.length - 1]?.pageCount;
  return data.length === 0 || currentPage === totalPages;
};

export const calculateIsLoadingMoreData = (
  page: number,
  data: PresentationsResponse[] | GetV2Response[] | undefined,
) => {
  return page > 0 && Boolean(data && typeof data[page - 1] === 'undefined');
};

export const createPresentationsArray = (
  data: PresentationsResponse[] | undefined,
): PresentationResponse[] =>
  data?.flatMap(
    ({ data }: { data: PresentationResponse[] | undefined }) =>
      data?.map((presentation) => presentation) || [],
  ) || [];
