import type { PutResultsSharingRequest } from '@core-api/presentation-collection/presentation-collection/slide-deck/{slide_deck_id}/results-sharing';
import { TrackingContext, type Series } from '@mentimeter/http-clients';
import {
  ShareModalSlidesTab,
  getEmbedUrl,
  getEditViewUrl,
} from '@mentimeter/share-modal';
import { useUser } from '@mentimeter/user';

interface SlidesDataHandlerProps {
  series: Series;
  onResultsSharingChange: (props: {
    resultsSharing: PutResultsSharingRequest['resultsSharing'];
  }) => Promise<unknown>;
}

export function SlidesDataHandler({
  series,
  onResultsSharingChange,
}: SlidesDataHandlerProps) {
  const { user } = useUser();

  const editViewUrl = getEditViewUrl(series.id, 'share-modal');
  const embedUrl = getEmbedUrl(series.id);

  const isSeriesInWorkspace = series.workspace_id !== null;

  return (
    <ShareModalSlidesTab
      isWorkspacePresentation={isSeriesInWorkspace}
      role={series.owner_id === user?.id ? 'Owner' : 'Collaborator'}
      editViewUrl={editViewUrl}
      embedUrl={embedUrl}
      series={series}
      context={TrackingContext.MyPresentations}
      onResultsSharingChange={onResultsSharingChange}
    />
  );
}
