import { Box } from '@mentimeter/ragnar-ui/box';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';

export function SkeletonLoader() {
  return (
    <Box width="100%">
      <Skeleton variant="rectangle" height="100px" mb={4} />
      <Skeleton variant="text" mb={2} />
      <Skeleton variant="rectangle" mb={4} />
      <Skeleton variant="text" mb={2} />
      <Skeleton variant="rectangle" mb={4} />
      <Skeleton variant="text" mb={2} />
      <Skeleton variant="rectangle" />
    </Box>
  );
}
