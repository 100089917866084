import type { TrackingContext } from '@mentimeter/http-clients';
import type { UserT } from '@mentimeter/user';
import { trackUser } from '@api/tracking/client';

export function trackMovePresentation({
  user,
  context,
  placement,
  seriesId,
  presentationHasResults,
  isWorkspacePresentation,
}: {
  user: UserT;
  context: TrackingContext;
  placement: string;
  seriesId: string;
  presentationHasResults: boolean;
  isWorkspacePresentation: boolean;
}) {
  trackUser({
    event: 'Clicked move presentation',
    properties: {
      context,
      placement,
      'workspace id': user.workspaceId,
      'series id': seriesId,
      'has results': presentationHasResults,
      'shared presentation': isWorkspacePresentation,
      'current plan': user.features.type,
      'role in workspace': user.role,
      'is bulk action': false,
      'number of presentations': 1,
    },
  });
}
