import { WorkspaceMemberStatusEnum } from '@mentimeter/http-clients';
import { useMatch } from '@mentimeter/ragnar-device';
import { CheckIcon, ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import {
  DropdownMenuContainer,
  DropdownMenuItemIcon,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import { P, Strong } from '@mentimeter/ragnar-ui/typography';
import { Text } from '@mentimeter/ragnar-ui/text';
import { ShareModalTitle } from '../../ShareModalTitle';
import { ShareModalText } from '../../ShareModalText';
import { ShareModalSection } from '../../ShareModalSection';
import type { VoteCode } from './types';

export enum TTLDaysT {
  TWO = '2',
  SEVEN = '7',
  FOURTEEN = '14',
}

interface ExpirationMenuOption {
  key: string;
  value: TTLDaysT | undefined;
  label: string;
  plan?: WorkspaceMemberStatusEnum;
}

const VOTING_EXPIRATION_MENU_OPTIONS: Record<TTLDaysT, ExpirationMenuOption> = {
  [TTLDaysT.TWO]: {
    key: 'voting-expiration-menu-2',
    value: TTLDaysT.TWO,
    label: '2 days',
  },
  [TTLDaysT.SEVEN]: {
    key: 'voting-expiration-menu-7',
    value: TTLDaysT.SEVEN,
    label: '7 days',
  },
  [TTLDaysT.FOURTEEN]: {
    key: 'voting-expiration-menu-14',
    value: TTLDaysT.FOURTEEN,
    label: '14 days',
    plan: WorkspaceMemberStatusEnum.PAYING,
  },
};

function trimExpirationText(str: string) {
  return str.substring(0, 3) === 'in ' ? str.substring(3) : str;
}

interface AccessCodeExpirationSectionProps {
  voteCode: VoteCode;
  feedback: TTLDaysT | undefined;
  onChange: (value: TTLDaysT) => void;
}

export function AccessCodeExpirationSection({
  voteCode,
  feedback: initialFeedback,
  onChange,
}: AccessCodeExpirationSectionProps) {
  const isMobile = useMatch({ lessThan: 3 });

  const shouldAddEmptyOption =
    voteCode.expirationText &&
    !Object.values(VOTING_EXPIRATION_MENU_OPTIONS).some((item) => {
      return voteCode.expirationText.includes(item.label);
    });

  const emptyMenuOption: ExpirationMenuOption | undefined = shouldAddEmptyOption
    ? {
        key: 'voting-expiration-menu-default',
        value: undefined,
        label: trimExpirationText(voteCode.expirationText),
      }
    : undefined;

  const feedback: TTLDaysT | undefined = (() => {
    if (initialFeedback || shouldAddEmptyOption) {
      return initialFeedback;
    }

    const selected = Object.values(VOTING_EXPIRATION_MENU_OPTIONS).find(
      (item) => {
        return voteCode.expirationText.includes(item.label);
      },
    );

    return selected ? selected.value : undefined;
  })();

  const selected =
    (emptyMenuOption === undefined &&
    feedback &&
    feedback in VOTING_EXPIRATION_MENU_OPTIONS
      ? VOTING_EXPIRATION_MENU_OPTIONS[feedback]
      : emptyMenuOption) ??
    VOTING_EXPIRATION_MENU_OPTIONS[feedback ?? TTLDaysT.TWO];

  const menuItems = Object.values(VOTING_EXPIRATION_MENU_OPTIONS);

  return (
    <ShareModalSection>
      <Box extend={() => ({ flex: '1 1 auto' })}>
        <ShareModalTitle id="voting-access-menu" isMobile={isMobile}>
          Access code expiration
        </ShareModalTitle>
        <ShareModalText>
          Choose how long the code for your Menti is active. The code{' '}
          <Strong>{voteCode.formatted}</Strong> expires{' '}
          <Strong>{voteCode.expirationText}</Strong>.
        </ShareModalText>
      </Box>
      <Box pl={[2, 3]}>
        <DropdownMenuRoot>
          <DropdownMenuTrigger>
            <Clickable
              id="voting-expiration-menu"
              aria-label="Toggle menu"
              alignItems="center"
              flexDirection="row"
            >
              <P color="primary" mb={0} mr={1} textAlign="left">
                <Strong color="inherit">{selected.label}</Strong>
              </P>
              <ChevronDownIcon color="primary" />
            </Clickable>
          </DropdownMenuTrigger>
          <DropdownMenuContainer>
            <DropdownMenuRadioGroup
              value={selected.value ? selected.value : 'empty'}
            >
              {emptyMenuOption && (
                <DropdownMenuRadioItem
                  key={emptyMenuOption.key}
                  value="empty"
                  id={emptyMenuOption.key}
                  aria-label={emptyMenuOption.label}
                  aria-selected={true}
                  onSelect={() => {
                    return;
                  }}
                >
                  <DropdownMenuItemIcon>
                    <CheckIcon size={2} color="currentColor" />
                  </DropdownMenuItemIcon>
                  <Text truncate color="currentColor">
                    {emptyMenuOption.label}
                  </Text>
                </DropdownMenuRadioItem>
              )}
              {menuItems.map((item) => (
                <DropdownMenuRadioItem
                  key={item.key}
                  value={item.value!}
                  id={item.key}
                  aria-label={item.label}
                  aria-selected={item.value === selected.value}
                  onSelect={() => {
                    onChange(item.value!);
                  }}
                >
                  <DropdownMenuItemIcon>
                    {item.value === selected?.value ? (
                      <CheckIcon size={2} color="currentColor" />
                    ) : null}
                  </DropdownMenuItemIcon>
                  <Text truncate color="currentColor">
                    {item.label}
                  </Text>
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContainer>
        </DropdownMenuRoot>
      </Box>
    </ShareModalSection>
  );
}
