import React from 'react';
import { Icon, type Size } from '@mentimeter/ragnar-visuals';

// Folders Users 16 Duo
export const FolderSharedIcon = ({
  size = 2,
  fill,
  fillContent,
}: {
  size?: Size | string;
  fill: string | undefined;
  fillContent: string | undefined;
}) => {
  return (
    <Icon size={size} aria-label="Shared Folder" viewBox="0 0 16 16">
      <path d="M1 2H7.41676L8.55312 4H15V14H1V2Z" fill={fill} />
      <path
        d="M6.1208 5.42139C5.32595 5.42139 4.68161 6.06574 4.68161 6.86059C4.68161 7.65543 5.32595 8.29978 6.1208 8.29978C6.91564 8.29978 7.55999 7.65543 7.55999 6.86059C7.55999 6.06574 6.91564 5.42139 6.1208 5.42139Z"
        fill={fillContent}
      />
      <path
        d="M6.12064 9.09668C4.81999 9.09668 3.76559 10.1511 3.76559 11.4517V12.4812H8.47568V11.4517C8.47568 10.1511 7.42129 9.09668 6.12064 9.09668Z"
        fill={fillContent}
      />
      <path
        d="M8.68844 6.86059C8.68844 6.06574 9.33279 5.42139 10.1276 5.42139C10.9225 5.42139 11.5668 6.06574 11.5668 6.86059C11.5668 7.65543 10.9225 8.29978 10.1276 8.29978C9.33279 8.29978 8.68844 7.65543 8.68844 6.86059Z"
        fill={fillContent}
      />
      <path
        d="M9.47347 11.4517V12.4812H12.4827V11.4517C12.4827 10.1511 11.4283 9.09668 10.1277 9.09668C9.62404 9.09668 9.15754 9.25476 8.77487 9.52379C9.21101 10.0459 9.47347 10.7182 9.47347 11.4517Z"
        fill={fillContent}
      />
    </Icon>
  );
};
