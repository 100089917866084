import React from 'react';
import type { TrackingContext, UserFeaturesT } from '@mentimeter/http-clients';
import {
  mutateSeriesCollaborators,
  useSeriesCollaborators,
} from '@mentimeter/core-hooks';
import { ManageCollaboratorsUi } from './ManageCollaboratorsUi';

interface Props {
  hideMembers: boolean;
  seriesId: string;
  ownerId: number;
  userFeatures: UserFeaturesT;
  userId: number;
  collaborationRights: string;
  context: TrackingContext;
  isSeriesInWorkspace?: boolean | undefined;
  invalidateSeries?: () => Promise<any[]>;
}

export const CollaborationSeriesDataHandler = ({
  hideMembers,
  seriesId,
  ownerId,
  userId,
  userFeatures,
  collaborationRights,
  context,
  isSeriesInWorkspace = false,
  invalidateSeries,
}: Props) => {
  const { collaborators, isLoading: isLoadingCollaborators } =
    useSeriesCollaborators(seriesId);

  const updateCollaborators = async () => {
    await mutateSeriesCollaborators(seriesId);
    await invalidateSeries?.();
  };

  return (
    <ManageCollaboratorsUi
      hideMembers={hideMembers}
      isWorkspaceResource={isSeriesInWorkspace}
      resourceId={seriesId}
      resourceType="series"
      userFeatures={userFeatures}
      collaborationRights={collaborationRights}
      collaborators={collaborators}
      trackingContext={context}
      isLoadingCollaborators={isLoadingCollaborators}
      currentUserId={userId}
      resourceOwnerId={ownerId}
      updateCollaborators={updateCollaborators}
    />
  );
};
