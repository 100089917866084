// This file is automatically generated. DO NOT EDIT.

import { type Slide } from '../../../../types/response/Slide';
import { type Segmentation } from '../../../../types/response/Segmentation';
import { type QaSettings } from '../../../../types/response/QaSettings';
import { type LiveChatSettings } from '../../../../types/response/LiveChatSettings';
import { type ReactionSettings } from '../../../../types/response/ReactionSettings';
import { type LanguageSettings } from '../../../../types/response/LanguageSettings';
import { type ProfanitySettings } from '../../../../types/response/ProfanitySettings';
import { type ParticipationSettings } from '../../../../types/response/ParticipationSettings';
import { type MusicSettings } from '../../../../types/response/MusicSettings';
import { type OwnershipSettings } from '../../../../types/response/OwnershipSettings';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface PutResultsSharingResponse {
  /** The ID of the slide deck. */
  slideDeckId: string;
  /** The public key of the slide deck */
  slideDeckPublicKey: string;
  /** The slides in the slide deck. */
  slides: Array<Slide>;
  /** The name of the slide deck. */
  name: string;
  /** The segmentations in the slide deck. */
  segmentations: Array<Segmentation>;
  /** The ID of the theme used for the slide deck */
  themeId: number;
  /** The theme used for the slide deck */
  legacyThemeSettings: unknown;
  /** The Q&A settings of the slide deck. */
  qaSettings: QaSettings;
  /** The Live Chat settings of the slide deck. */
  liveChatSettings: LiveChatSettings;
  /** The Reactions settings of the slide deck. */
  reactionSettings: ReactionSettings;
  /** The Language settings of the slide deck. */
  languageSettings: LanguageSettings;
  /** The Profanity settings of the slide deck. */
  profanitySettings: ProfanitySettings;
  /** The Participation settings of the slide deck. */
  participationSettings: ParticipationSettings;
  /** The Music settings of the slide deck. */
  musicSettings: MusicSettings;
  /** The ownership settings of the slide deck. */
  ownershipSettings: OwnershipSettings;
}

export async function putResultsSharingResponse(
  response: Response,
  options?: GetJSONResponseOptions<PutResultsSharingResponse>,
): Promise<PutResultsSharingResponse> {
  return getJSONResponse<PutResultsSharingResponse>(
    response,
    'putResultsSharingResponse',
    options,
  );
}
