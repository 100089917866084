import userCache from '@mentimeter/user';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { core, type TrackingContext } from '@mentimeter/http-clients';
import { deleteInviteRequest } from '@core-api/collaboration/resource-sharings/invite/{invite_id}';
import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { deleteResourceSharingsRequest } from '@core-api/collaboration/resource-sharings/{sharing_id}';
import { trackUser } from '@api/tracking/client';
import type { UserCollaborationRole } from './CollaborationFolderDataHandler';

export const handleRemoveFolderCollaborator = async ({
  id,
  entityType,
  trackingContext,
  role,
  updateCollaborators,
}: {
  id: string;
  entityType: string;
  trackingContext: TrackingContext;
  role: UserCollaborationRole;
  updateCollaborators: () => Promise<void>;
}) => {
  try {
    const region = userCache.region;
    const userAuth = userCache.getToken();
    let request;

    if (entityType === 'invitee') {
      request = deleteInviteRequest({
        region,
        userAuth,
        inviteId: id,
      });
    } else {
      request = deleteResourceSharingsRequest({
        region,
        userAuth,
        sharingId: id,
      });
    }

    const response = await fetch(request);

    if (!response.ok) throw new Error('Failed to remove collaborator');

    await updateCollaborators();
    trackUser({
      event: 'Removed collaborator',
      properties: {
        placement: 'Folder share modal',
        context: trackingContext,
        role,
        'resource type': 'Folder',
      },
    });
  } catch (error) {
    const message = (error as Error).message;
    const me = new MentiError(
      `Error removing folder collaborators: ${message}`,
      {
        feature: 'collaboration',
      },
    );
    captureException(me);
  }
};

export const handleRemoveSeriesCollaborator = async ({
  collaborator,
  placement,
  trackingContext,
  userCollaborationRole,
  onError,
  updateCollaborators,
}: {
  collaborator: SeriesCollaborator;
  placement: string;
  trackingContext: TrackingContext;
  userCollaborationRole: UserCollaborationRole;
  onError: () => void;
  updateCollaborators: () => Promise<void>;
}) => {
  try {
    if (collaborator.type === 'invitee') {
      await core().series.collaborators.delete(
        collaborator.seriesId,
        undefined,
        collaborator.entity.email,
      );
    } else {
      await core().series.collaborators.delete(
        collaborator.seriesId,
        Number(collaborator.entity.id),
      );
    }
    await updateCollaborators();
    trackUser({
      event: 'Removed collaborator',
      properties: {
        placement,
        emails: [collaborator.entity.email],
        context: trackingContext,
        role: userCollaborationRole,
        'resource type': 'Presentation',
      },
    });
  } catch (e) {
    onError();
  }
};
