import { useCallback } from 'react';
import type { TrackingContext, Pace } from '@mentimeter/http-clients';
import { useMatch } from '@mentimeter/ragnar-device';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ShareModalTitle } from '../../ShareModalTitle';
import { ShareModalSection } from '../../ShareModalSection';
import { ReadOnlyInput } from '../ReadOnlyInput';

interface InviteLinkSectionProps {
  id: string;
  pace: Pace;
  voteUrl: string;
  context: TrackingContext;
}

export function InviteLinkSection({
  id,
  pace,
  voteUrl,
  context,
}: InviteLinkSectionProps) {
  const { mode } = pace;
  const isMobile = useMatch({ lessThan: 3 });

  const onCopiedLink = useCallback(() => {
    trackUser({
      event: 'Copied voting link',
      properties: {
        'series id': id,
        pace: mode,
        placement: 'Participation tab',
        context,
      },
    });
  }, [id, mode, context]);

  return (
    <ShareModalSection flexDirection="column">
      <Box flexDirection="column" width={1}>
        <ShareModalTitle id="voting-link" isMobile={isMobile}>
          Participation link
        </ShareModalTitle>
      </Box>
      <Box width={1} flexDirection={['column', 'row']}>
        <ReadOnlyInput
          value={voteUrl}
          onCopiedLink={onCopiedLink}
          ariaLabel="Copy participation link"
        />
      </Box>
    </ShareModalSection>
  );
}
