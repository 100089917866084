import type { ReactNode } from 'react';
import { useState } from 'react';
import { useOnce } from '@mentimeter/react-hooks';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Dark } from '@mentimeter/ragnar-ui/themes';
import {
  PopoverBody,
  PopoverContainer,
  PopoverFooter,
  PopoverHeader,
  PopoverRoot,
} from '@mentimeter/ragnar-ui/popover';
import { Text } from '@mentimeter/ragnar-ui/text';
import { PaywallPopoverTrigger } from '../../triggers/PaywallPopoverTrigger';

interface Props {
  title: string;
  description: string;
  width?: number;
  children: ReactNode;
  trackClickUpgrade: () => void;
  onOpen?: () => void;
}

export const SelfUpgradePopoverContent = ({
  trackClickUpgrade,
  onOpen,
  title,
  width = 300,
  description,
  children,
}: Props) => {
  const [open, setOpen] = useState(false);

  useOnce(open, () => {
    onOpen?.();
  });

  return (
    <PopoverRoot modal open={open} onOpenChange={setOpen}>
      <PaywallPopoverTrigger trigger={() => setOpen(true)}>
        {children}
      </PaywallPopoverTrigger>
      <PopoverContainer width={width}>
        <Dark>
          <Box bg="surface">
            <PopoverHeader>{title}</PopoverHeader>
            <PopoverBody>
              <Text>{description}</Text>
            </PopoverBody>
            <PopoverFooter>
              <Button
                href="/plans"
                target="_blank"
                variant="positive"
                onClick={trackClickUpgrade}
                size="compact"
              >
                See plans
              </Button>
            </PopoverFooter>
          </Box>
        </Dark>
      </PopoverContainer>
    </PopoverRoot>
  );
};
