import React from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import type { CollaborationResourceType } from './ManageCollaboratorsUi';

interface RemoveCollaboratorModal {
  show: boolean;
  onDismiss: () => void;
  email: string;
  onRemoveCollaborator: () => Promise<void>;
  resourceType: CollaborationResourceType;
}

export const RemoveCollaboratorModal = ({
  show,
  onDismiss,
  email,
  onRemoveCollaborator,
  resourceType,
}: RemoveCollaboratorModal) => {
  const resourceName = resourceType === 'folder' ? 'folder' : 'Menti';
  const title = `Remove access for this ${resourceName}`;

  return (
    <ModalRoot open={show} onOpenChange={onDismiss}>
      <ModalContainer>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {email} can be reinvited back to access this {resourceName}.
        </ModalBody>
        <ModalFooter>
          <ModalDismiss>
            <Button variant="secondary">Cancel</Button>
          </ModalDismiss>
          <Button variant="negative" onClick={onRemoveCollaborator}>
            Remove
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};
