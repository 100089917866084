import * as React from 'react';
import type {
  CommonAccessibilityT,
  InputAccessibilityT,
  LabelAccessibilityT,
} from '@mentimeter/ragnar-styled';
import type { ComponentProps } from 'react';
import { ToggleIcon } from './ToggleIcon';
import { ToggleSwitch } from './ToggleSwitch';
import { ToggleIconContainer } from './ToggleIconContainer';
import { ToggleLabel } from './ToggleLabel';

interface ToggleItem
  extends CommonAccessibilityT,
    LabelAccessibilityT,
    InputAccessibilityT {
  checked?: boolean;
  size?: 'default' | 'compact' | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  'data-testid'?: string | undefined;
  className?: string | undefined;
}

export const ToggleItem = ({
  id = '',
  name,
  disabled = false,
  checked = false,
  size,
  onChange,
  onBlur,
  onFocus,
  'data-testid': dataTestId,
  className,
  ...rest
}: ToggleItem) => {
  return (
    <ToggleLabel
      htmlFor={id}
      size={size}
      disabled={disabled}
      className={className}
      {...rest}
    >
      <ToggleSwitch
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        data-testid={dataTestId}
      />

      <ToggleIconContainer
        checked={Boolean(checked)}
        disabled={disabled}
        size={size}
      >
        <ToggleIcon
          disabled={disabled}
          checked={Boolean(checked)}
          size={size}
        />
      </ToggleIconContainer>
    </ToggleLabel>
  );
};

export type ToggleItemProps = ComponentProps<typeof ToggleItem>;
