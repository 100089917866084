// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toQueryParam } from '@api/internal';

export interface GetMoveInfoRequest {
  region: 'eu' | 'us';
  userAuth: string;
  seriesId: string;
}

export function getMoveInfoRequest(requestParams: GetMoveInfoRequest): Request {
  const queryParams = new URLSearchParams();
  queryParams.set('series_id', toQueryParam(requestParams.seriesId));

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/folders/move-info?${queryParams.toString()}`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
