import { mutate, useSWRConfig } from 'swr';

/**
  @returns An invalidator for the swr cache for the following hooks
  - packages/core-hooks/src/usePaginatedPresentationsPrivate.tsx
  - packages/core-hooks/src/usePaginatedPresentationsSharedWithMe.tsx
  - packages/core-hooks/src/usePaginatedPresentationsWorkspace.tsx
  - packages/core-hooks/src/usePaginatedSlideDecks.tsx
*/
export const useSeriesCache = () => {
  const { cache } = useSWRConfig();
  const invalidate = () =>
    Promise.all(
      Array.from((cache as Map<string, any>).keys())
        .filter(
          (cacheName) =>
            !EXCLUDED_CACHE_NAMES.some((rx) => rx.test(cacheName)) &&
            (/series/i.test(cacheName) ||
              /presentations\/me/i.test(cacheName) ||
              /presentations\/shared-with-me/i.test(cacheName) ||
              /presentations\/workspace/i.test(cacheName) ||
              /recent-presentations/i.test(cacheName)),
        )
        .map((cacheName) => mutate(cacheName)),
    );

  return {
    invalidate,
  };
};

/**
 * The invalidator will match all cache names that contain the word "series"
 * If you want to exclude a cache name from being invalidated, add a regex to this array
 */
const EXCLUDED_CACHE_NAMES: RegExp[] = [/slide-preview-series/i];
