import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { isFolderCollaboratorOrInvitee } from '../_utils';
import { RemoveCollaboratorCross } from './RemoveCollaboratorCross';
import { RemoveFolderCollaboratorHint } from './RemoveFolderCollaboratorHint';

export const RemoveCollaborator = ({
  collaborator,
  onRemoveEditAccess,
}: {
  collaborator: SeriesCollaborator;
  onRemoveEditAccess: () => Promise<void>;
}) => {
  if (isFolderCollaboratorOrInvitee(collaborator)) {
    return (
      <RemoveFolderCollaboratorHint
        tooltipText="You can not remove access to a single Menti in a shared folder."
        collaboratorUserId={collaborator.entity.id}
        resourceIdToRemove={String(collaborator.id)}
      />
    );
  }

  return (
    <RemoveCollaboratorCross
      collaboratorUserId={collaborator.entity.id}
      collaboratorDisplayName={collaborator.entity.name}
      onRemoveEditAccess={onRemoveEditAccess}
      isInvitee={collaborator.type === 'invitee'}
    />
  );
};
