import { useVoteCode, type UpdateSeries } from '@mentimeter/core-hooks';
import { TrackingContext, type Series } from '@mentimeter/http-clients';
import {
  ShareModalParticipationTab,
  getVoteUrl,
} from '@mentimeter/share-modal';
const VOTING_URL = process.env.NEXT_PUBLIC_VOTING_URL;

interface ParticipationDataHandlerProps {
  series: Series;
  onUpdateActiveSeries: UpdateSeries;
}

export function ParticipationDataHandler({
  series,
  onUpdateActiveSeries,
}: ParticipationDataHandlerProps) {
  const voteUrl = getVoteUrl(series.vote_key || '', VOTING_URL);
  const voteCode = useVoteCode(series.id);

  return (
    <ShareModalParticipationTab
      series={series}
      voteCode={voteCode}
      update={onUpdateActiveSeries}
      voteUrl={voteUrl}
      context={TrackingContext.MyPresentations}
    />
  );
}
