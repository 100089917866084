import React, { useState } from 'react';
import {
  FolderSolidIcon,
  InboxSolidIcon,
  UserSolidIcon,
  UsersSolidIcon,
} from '@mentimeter/ragnar-visuals';
import {
  gray600,
  whiteBase,
  yellow500,
  yellow800,
} from '@mentimeter/ragnar-colors';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Collapsable } from '@mentimeter/ragnar-ui/collapsable';
import { SelectorRow } from './SelectorRow';
import { FolderSharedIcon } from './FolderSharedIcon';
import type { FolderNodeType, FolderIconType } from './types';

export const FolderTree = ({
  currentLocationId,
  folders,
  onSelect,
  initialPath,
}: {
  currentLocationId: string;
  folders: FolderNodeType[];
  onSelect: (node: FolderNodeType) => void;
  initialPath: string;
}) => {
  const [selectedPath, setSelectedPath] = useState<string>('');

  const handleSelect = (node: FolderNodeType) => {
    setSelectedPath(node.path);
    onSelect(node);
  };

  return (
    <Box width="100%">
      {folders.map((node: FolderNodeType) => (
        <FolderNode
          currentLocationId={currentLocationId}
          node={node}
          level={1}
          onSelect={handleSelect}
          selectedPath={selectedPath}
          key={node.id}
          initialPath={initialPath}
        />
      ))}
    </Box>
  );
};

const FolderNode = ({
  currentLocationId,
  node,
  level,
  onSelect,
  selectedPath,
  initialPath = '',
}: {
  currentLocationId: string;
  node: FolderNodeType;
  level: number;
  onSelect: (node: FolderNodeType) => void;
  selectedPath: string;
  initialPath: string;
}) => {
  const { name, children, canMoveTo, path, iconName } = node;
  const haschildren = children && children.length > 0;

  const isCurrentLocation = initialPath === path;
  const [isOpen, setIsOpen] = useState(
    initialPath.startsWith(path) || isCurrentLocation,
  );

  const isSelected = selectedPath === path;
  const disabled = !canMoveTo && !node.selectedMessage;

  const handleSelectClick = () => {
    if (disabled) {
      return;
    }
    onSelect(node);
  };

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };

  const itemIsCurrentLocation = currentLocationId === String(node.id);

  return (
    <>
      <SelectorRow
        id={String(node.id)}
        badgeText={itemIsCurrentLocation ? 'Current location' : undefined}
        canExpand={haschildren}
        checked={isSelected}
        disabled={disabled}
        disabledTooltipText={disabled ? node.invalidMoveDescription : undefined}
        icon={
          <FolderNodeIcon
            iconName={iconName}
            isSelected={isSelected}
            disabled={disabled}
            shared={Boolean(node.shared)}
          />
        }
        isOpen={isOpen}
        label={name}
        level={level}
        onExpand={handleExpandClick}
        onSelect={handleSelectClick}
        showChevron={haschildren}
      />
      <Collapsable show={isOpen} width="100%">
        {children.map((childNode) => (
          <FolderNode
            currentLocationId={currentLocationId}
            node={childNode}
            selectedPath={selectedPath}
            onSelect={onSelect}
            level={level + 1}
            key={childNode.id}
            initialPath={initialPath}
          />
        ))}
      </Collapsable>
    </>
  );
};

const FolderNodeIcon = ({
  iconName,
  isSelected,
  disabled,
  shared,
}: {
  iconName: FolderIconType;
  isSelected: boolean;
  disabled: boolean;
  shared: boolean;
}) => {
  const disabledColor = disabled ? gray600 : undefined;

  switch (iconName) {
    case 'sharedWithMe':
      return <InboxSolidIcon color={disabledColor} aria-hidden />;
    case 'me':
      return <UserSolidIcon color={disabledColor} aria-hidden />;
    case 'workspace':
      return <UsersSolidIcon color={disabledColor} aria-hidden />;
    case 'folder':
      return shared ? (
        <FolderSharedIcon
          aria-hidden
          fill={disabled ? disabledColor : isSelected ? undefined : yellow500}
          fillContent={
            disabled ? whiteBase : isSelected ? whiteBase : yellow800
          }
        />
      ) : (
        <FolderSolidIcon
          aria-hidden
          color={disabled ? disabledColor : isSelected ? undefined : yellow500}
        />
      );
    default:
      return null;
  }
};
