import { useState } from 'react';
import type {
  ResourceEntity,
  SeriesCollaborator,
} from '@core-api/collaboration/types/response';
import { ShareSeriesPaywallNotificationDataHandler } from '@mentimeter/paywalls/share-series';
import { useButtonState } from '@mentimeter/ragnar-ui/button';
import { TrackingContext, type UserFeaturesT } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';
import { getHasCollaborationEnabled } from '@mentimeter/user';
import { AddCollaborators, type InviteItem } from './AddCollaborators';
import { getCanSeeCollaboratorsList } from './_utils';
import { addCollaboratorsRequest } from './addCollaboratorsRequest';
import { ExistingCollaboratorsList } from './collaborators-list/ExistingCollaboratorsList';
import { CollaborationNotification } from './CollaborationNotification';
import { WorkspaceItemNotification } from './WorkspaceItemNotification';

export type CollaborationResourceType = 'folder' | 'series';

const SkeletonItem = () => (
  <Box flexDirection="row" width="100%">
    <Skeleton variant="circle" mb={2} mr={2} />
    <Skeleton width="87%" variant="rectangle" mb={2} />
  </Box>
);

function SkeletonLoader() {
  return (
    <Box mt={3} width="100%">
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </Box>
  );
}

const Notification = ({
  resourceType,
  collaborationRights,
  isItemOwner,
  trackingContext,
}: {
  resourceType: CollaborationResourceType;
  collaborationRights: string;
  isItemOwner: boolean;
  trackingContext: TrackingContext;
}) => {
  if (resourceType === 'folder') {
    return (
      <CollaborationNotification
        collaborationRights={collaborationRights}
        isItemOwner={isItemOwner}
      />
    );
  }
  return (
    <ShareSeriesPaywallNotificationDataHandler
      trackingContext={trackingContext}
    >
      <CollaborationNotification
        collaborationRights={collaborationRights || ''}
        isItemOwner={isItemOwner}
        isFromResultsPage={trackingContext === TrackingContext.Results}
      />
    </ShareSeriesPaywallNotificationDataHandler>
  );
};

export const ManageCollaboratorsUi = ({
  hideMembers,
  isWorkspaceResource,
  collaborators,
  collaborationRights,
  isLoadingCollaborators,
  userFeatures,
  trackingContext,
  resourceId,
  resourceType,
  currentUserId,
  resourceOwnerId,
  updateCollaborators,
}: {
  hideMembers: boolean;
  isWorkspaceResource: boolean;
  collaborators: SeriesCollaborator[] | ResourceEntity[];
  collaborationRights: string;
  isLoadingCollaborators: boolean;
  userFeatures: UserFeaturesT | undefined;
  trackingContext: TrackingContext;
  resourceId: string;
  resourceType: CollaborationResourceType;
  currentUserId: number;
  resourceOwnerId: number;
  updateCollaborators: () => Promise<void>;
}) => {
  const [buttonState, setButtonState] = useButtonState();
  const [error, setError] = useState<string | null>(null);
  const [showSendInvite, setShowSendInvite] = useState(false);
  const [selectedItems, setSelectedItems] = useState<InviteItem[]>([]);
  const [newlyAddedCollaboratorEmails, setNewlyAddedCollaboratorEmails] =
    useState<string[]>([]);

  if (isWorkspaceResource)
    return <WorkspaceItemNotification resourceType={resourceType} />;

  const userCollaborationRole =
    resourceOwnerId === currentUserId ? 'Owner' : 'Collaborator';
  const hasCollaborationEnabled = getHasCollaborationEnabled(userFeatures);
  const canSeeCollaborators = getCanSeeCollaboratorsList(
    userCollaborationRole,
    collaborationRights,
    hasCollaborationEnabled,
  );
  const showExistingCollaboratorsList = !showSendInvite && canSeeCollaborators;

  const canAddCollaborators =
    hasCollaborationEnabled && collaborationRights !== 'no_one';
  const isLoadingExistingCollaborators =
    isLoadingCollaborators || !collaborationRights;

  return (
    <Box width="100%">
      <Notification
        resourceType={resourceType}
        collaborationRights={collaborationRights}
        isItemOwner={userCollaborationRole === 'Owner'}
        trackingContext={trackingContext}
      />
      {error && (
        <CalloutCard
          description={error}
          my={3}
          onDismiss={() => setError(null)}
          theme="negative"
        />
      )}
      {canAddCollaborators && (
        <AddCollaborators
          onAddCollaborators={async (
            message: string | null,
          ): Promise<boolean> => {
            const ok = await addCollaboratorsRequest({
              setButtonState,
              selectedItems,
              resourceId,
              updateCollaborators,
              setError,
              userCollaborationRole,
              trackingContext,
              userPlan: userFeatures?.type || 'registered',
              resourceType,
              message,
            });

            if (ok) {
              setNewlyAddedCollaboratorEmails(
                selectedItems.map(({ email, id }) => email || id),
              );
              setSelectedItems([]);
            }

            return ok;
          }}
          validateCollaboratorWorkspaceMembership={
            collaborationRights === 'workspace_members' && !hideMembers
          }
          buttonState={buttonState}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setShowSendInvite={setShowSendInvite}
          showSendInvite={showSendInvite}
          collaboratorEmails={collaborators.map((c) => c.entity.email)}
        />
      )}

      {showExistingCollaboratorsList && (
        <ExistingCollaboratorsList
          resourceType={resourceType}
          collaborators={collaborators}
          currentUserId={currentUserId}
          resourceOwnerId={resourceOwnerId}
          updateCollaborators={updateCollaborators}
          newlyAddedCollaboratorEmails={newlyAddedCollaboratorEmails}
          setNewlyAddedCollaboratorEmails={setNewlyAddedCollaboratorEmails}
          trackingContext={trackingContext}
          resourceId={resourceId}
          userCollaborationRole={userCollaborationRole}
          setError={setError}
        />
      )}
      {isLoadingExistingCollaborators && <SkeletonLoader />}
    </Box>
  );
};
