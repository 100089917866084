import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { ResultsChartIcon } from '@mentimeter/ragnar-visuals';
import { TRACKING_PLACEMENT } from 'features/dashboard/presentation-view/constants';
import { trackUser } from '@api/tracking/client';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { ContextMenu } from '../ContextMenu';
import { useContextMenuContext } from '../useContextMenuContext';

export function ViewResults({ item }: { item: SlideDeckOverview }) {
  const { trackingContext, userPresentationRole, trackingPlacement } =
    useContextMenuContext();

  const handleClick = async () => {
    trackUser({
      event: 'Opened results view',
      properties: {
        context: trackingContext,
        placement: trackingPlacement ?? TRACKING_PLACEMENT.ACTION_MENU,
        userPresentationRole,
        'workspace role': userPresentationRole,
        'shared presentation': item.workspaceId !== null,
        'series id': item.slideDeckPublicKey,
        'user shared folder': item.inUserSharedFolder,
      },
    });
  };

  return (
    <ContextMenu.Item onClick={handleClick} asChild>
      <Clickable
        href={buildPresentationPath({
          seriesId: item.slideDeckPublicKey,
          mode: 'results',
        })}
        alignItems="center"
        flexDirection="row"
        extend={() => ({
          '@media (hover: hover)': {
            ':hover': {
              opacity: 1,
            },
          },
        })}
      >
        <ContextMenu.ItemIcon>
          <ResultsChartIcon />
        </ContextMenu.ItemIcon>
        <ContextMenu.ItemLabel color="text">View results</ContextMenu.ItemLabel>
      </Clickable>
    </ContextMenu.Item>
  );
}
