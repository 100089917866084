import { TrashIcon } from '@mentimeter/ragnar-visuals';
import type { Dispatch, SetStateAction } from 'react';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import { Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { ContextMenu } from '../ContextMenu';
import { useContextMenuContext } from '../useContextMenuContext';

interface DeleteProps {
  item: SlideDeckOverview;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setDeleteSharedTemplateModalOpen: Dispatch<SetStateAction<boolean>>;
}
export function DeletePresentation({
  item,
  setDeleteModalOpen,
  setDeleteSharedTemplateModalOpen,
}: DeleteProps) {
  const { user } = useContextMenuContext();

  const handleOnClick = () => {
    const isSharedTemplate =
      item.isSharedWithOrganization ||
      (item.sharedWithGroups && item.sharedWithGroups?.length > 0);
    if (isSharedTemplate) {
      setDeleteSharedTemplateModalOpen(true);
    } else {
      setDeleteModalOpen(true);
    }
  };

  const isItemOwner = item.ownerId === user?.id;
  const disabled = !isItemOwner;
  const color = disabled ? 'negativeWeak' : 'negative';
  return (
    <Tooltip
      trigger={
        <ContextMenu.Item onSelect={handleOnClick} disabled={disabled}>
          <ContextMenu.ItemIcon>
            <TrashIcon color={color} />
          </ContextMenu.ItemIcon>
          <ContextMenu.ItemLabel color={color}>Delete</ContextMenu.ItemLabel>
        </ContextMenu.Item>
      }
      side="left"
    >
      {disabled && 'Only the owner of a workspace presentation can delete it'}
    </Tooltip>
  );
}
