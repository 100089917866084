'use client';
import type { SlideDeck as PresentationCollectionSlideDeck } from '@core-api/presentation-collection/types/response';
import { MigrationStatusContext } from '@mentimeter/compatibility/migration-status';
import type { Series } from '@mentimeter/http-clients/edge';
import { useMemo, type PropsWithChildren } from 'react';

interface MigrationContainerProps<
  SlideDeckT extends PresentationCollectionSlideDeck,
> {
  seriesResponse?: {
    series: Series | undefined;
    slideDeck: SlideDeckT | undefined;
  };
  isMigrated?: boolean;
}

export function MigrationContainer<
  SlideDeckT extends PresentationCollectionSlideDeck,
>({
  seriesResponse,
  isMigrated,
  children,
}: PropsWithChildren<MigrationContainerProps<SlideDeckT>>) {
  const { slideDeck } = seriesResponse ?? {};
  const migrationStatusContextValue = useMemo(
    () => ({
      isSlideDeckMigrated: isMigrated ?? Boolean(slideDeck),
    }),
    [isMigrated, slideDeck],
  );

  return (
    <MigrationStatusContext.Provider value={migrationStatusContextValue}>
      {children}
    </MigrationStatusContext.Provider>
  );
}
