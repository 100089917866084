// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PatchMoveRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The id of the presentation to be moved */
  seriesId: string;
  /** The id of the folder to which the presentation should be moved */
  folderId: number | null;
  /** The id of the workspace to which the presentation should be moved */
  workspaceId: number | null;
}

export function patchMoveRequest(requestParams: PatchMoveRequest): Request {
  const data = {
    folder_id: toSnakeKeys(requestParams.folderId),
    workspace_id: toSnakeKeys(requestParams.workspaceId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation-collection/${requestParams.seriesId}/move`;
  return new Request(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
