// This file is automatically generated. DO NOT EDIT.

import { type SeriesCollaborator } from '../../../../types/response/SeriesCollaborator';
import { getJSONResponse, type GetJSONResponseOptions } from '@api/internal';

export interface GetCollaboratorsResponse {
  data: Array<SeriesCollaborator>;
}

export async function getCollaboratorsResponse(
  response: Response,
  options?: GetJSONResponseOptions<GetCollaboratorsResponse>,
): Promise<GetCollaboratorsResponse> {
  return getJSONResponse<GetCollaboratorsResponse>(
    response,
    'getCollaboratorsResponse',
    options,
  );
}
