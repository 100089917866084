import type { Question } from '@mentimeter/http-clients';
import type {
  QuestionWithSlide as PresentationCollectionQuestionWithSlide,
  Slide as PresentationCollectionSlide,
} from '@mentimeter/presentation-collection-schema/api-types-overrides';
import { isProxyInstance } from '../../../../utils/is-proxy-instance';
import { getCommonSlideCompatibilityHandler } from '../../../common/get-common-slide-compatibility-handler';
import { presentationCollectionSlidePropertyKeys } from './constants';
import { mapPresentationCollectionSlidePropertyToQuestionProperty } from './map-presentation-collection-slide-property-to-question-property';

export function createPresentationCollectionSlideCompatibilityLayer(
  target: Question | PresentationCollectionSlide,
): PresentationCollectionQuestionWithSlide {
  if (isProxyInstance(target)) {
    return target as PresentationCollectionQuestionWithSlide;
  }

  return new Proxy(
    target,
    getCommonSlideCompatibilityHandler(
      presentationCollectionSlidePropertyKeys,
      mapPresentationCollectionSlidePropertyToQuestionProperty,
    ),
  ) as PresentationCollectionQuestionWithSlide;
}
