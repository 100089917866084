// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toQueryParam } from '@api/internal';

export interface GetPotentialCollaboratorsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** Search string */
  search?: string;
  page?: number;
  pageSize?: number;
}

export function getPotentialCollaboratorsRequest(
  requestParams: GetPotentialCollaboratorsRequest,
): Request {
  const queryParams = new URLSearchParams();
  if (requestParams.search !== undefined) {
    queryParams.set('search', toQueryParam(requestParams.search));
  }
  if (requestParams.page !== undefined) {
    queryParams.set('page', toQueryParam(requestParams.page));
  }
  if (requestParams.pageSize !== undefined) {
    queryParams.set('page_size', toQueryParam(requestParams.pageSize));
  }

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/collaboration/potential-collaborators?${queryParams.toString()}`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
