import { deletePresentationCollectionRequest } from '@core-api/presentation-collection/presentation-collection';
import type { FeatureOwner } from '@mentimeter/error-utils/feature-owners';
import { MentiError } from '@mentimeter/errors/sentry';
import userCache from '@mentimeter/user';
import { captureException } from '@sentry/nextjs';
import { useCallback } from 'react';

export const useDeletePresentations = (feature: FeatureOwner) => {
  const deletePresentations = useCallback(
    async (
      slideDecksPublicKeys: string[],
      onSuccess: (slideDecksPublicKeys: string[]) => void,
      onError: (error?: unknown) => void,
    ) => {
      try {
        const region = userCache.region;
        const userAuth = userCache.getToken();

        const response = await fetch(
          deletePresentationCollectionRequest({
            region,
            userAuth,
            slideDecksPublicKeys,
          }),
        );

        if (!response.ok) {
          onError();
          return;
        }

        onSuccess(slideDecksPublicKeys);
      } catch (error) {
        captureException(
          new MentiError('error delete presentations', {
            feature,
            cause: error,
          }),
        );
        onError(error);
      }
    },
    [feature],
  );

  return deletePresentations;
};
