import type { MemberT } from '@mentimeter/http-clients';
import { MoreIcon } from '@mentimeter/ragnar-visuals';
import { getNameInitials } from '@mentimeter/user';
import type { SharedStatus } from '@mentimeter/workspace';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import {
  DropdownMenuContainer,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import { Strong } from '@mentimeter/ragnar-ui/typography';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';
import { sortMembers } from './sortMembers';
import { getAccessSettingTooltip } from './accessSettingTooltip';
import { SelectPopover } from './SelectPopover';
import type { BaseOption } from './SelectPopover';

type AccessSetting = SharedStatus | 'selected-individuals';

const ACCESS_SETTING_LABEL: Record<AccessSetting, string> = {
  private: 'Only you',
  shared: 'Anyone in your workspace',
  'selected-individuals': 'Selected individuals',
};
type AccessSettingOption = BaseOption<AccessSetting>;

export function AccessSettings({
  userId,
  seriesOwnerId,
  isWorkspaceOwnerOrAdmin,
  members,
  isShared,
  forceIsShared,
  onSharedChange,
  onManageMemberInvitation,
  onRemoveMemberFromTeam,
}: {
  userId: number;
  seriesOwnerId: number;
  isWorkspaceOwnerOrAdmin: boolean;
  members: MemberT[];
  isShared: boolean;
  forceIsShared: boolean;
  onSharedChange: (sharedStatus: SharedStatus) => Promise<void>;
  onManageMemberInvitation: (memberId: string) => void;
  onRemoveMemberFromTeam: (memberId: string) => void;
}) {
  const hasOwnerRights = userId === seriesOwnerId;
  const canChangeAccessSettings = hasOwnerRights && !forceIsShared;
  const sortedMembers = sortMembers(members, seriesOwnerId, userId);
  const sharedStatus = isShared ? 'shared' : 'private';

  const selectedAccessSettingOption: AccessSettingOption = {
    id: sharedStatus,
    value: sharedStatus,
    label: ACCESS_SETTING_LABEL[sharedStatus],
  };

  const teamOptionSubtitle = getTeamOptionSubtitle(members);

  const accessSettingOptions: AccessSettingOption[] = [
    { id: 'private', value: 'private', label: ACCESS_SETTING_LABEL['private'] },
    {
      id: 'shared',
      value: 'shared',
      label: ACCESS_SETTING_LABEL['shared'],
      subtitle: teamOptionSubtitle,
    },
    {
      id: 'selected-individuals',
      value: 'selected-individuals',
      label: ACCESS_SETTING_LABEL['selected-individuals'],
      paywall: 'Upgrade to Pro for more control over who you share with',
    },
  ];

  return (
    <Box width="100%" flex="1 1 auto">
      <Strong color="textWeaker" mb={3}>
        Presentation access
      </Strong>
      <Box
        display="grid"
        gridTemplateColumns="1fr auto"
        alignItems="center"
        gap={2}
        bg="bgStrong"
        borderRadius={2}
        px={3}
        py={3}
        width="100%"
      >
        <TooltipOld
          referenceId="team-access-setting"
          description={getAccessSettingTooltip(forceIsShared, hasOwnerRights)}
        />
        <Box ml={2} flexDirection="row" gap={2} flexWrap="wrap">
          <SelectPopover
            id="team-access-setting"
            selectedOption={selectedAccessSettingOption}
            options={accessSettingOptions}
            onChange={onChangeAccessSetting}
            disabled={!canChangeAccessSettings}
            fontWeight="semiBold"
          />

          {sharedStatus === 'shared' && (
            <Text color="textWeaker">
              {members.length} {members.length > 1 ? 'people' : 'person'}
            </Text>
          )}
        </Box>
        <Text color="text" mr="space2">
          Can edit
        </Text>
      </Box>

      {isShared && (
        <Box
          width="100%"
          overflow="auto"
          flex="1 1 auto"
          px={3}
          pt={3}
          pb={2}
          display="grid"
          gridAutoRows="max-content"
          maxHeight="100%"
          gap={3}
        >
          {sortedMembers.map((member) => (
            <MemberWithActions
              key={member.id}
              member={member}
              userId={userId}
              seriesOwnerId={seriesOwnerId}
              userIsWorkspaceOwnerOrAdmin={isWorkspaceOwnerOrAdmin}
              onManageInvitation={() => onManageMemberInvitation(member.id)}
              onRemoveFromTeam={() => onRemoveMemberFromTeam(member.id)}
            />
          ))}
        </Box>
      )}
    </Box>
  );

  async function onChangeAccessSetting(
    accessSettingOptionValue: AccessSetting,
  ) {
    if (accessSettingOptionValue === 'selected-individuals') return;
    await onSharedChange(accessSettingOptionValue);
  }
}

function getTeamOptionSubtitle(members: MemberT[]) {
  if (members.length <= 1) return '1 person (you)';
  return `${members.length} people`;
}

function MemberWithActions({
  member,
  userId,
  seriesOwnerId,
  userIsWorkspaceOwnerOrAdmin,
  onManageInvitation,
  onRemoveFromTeam,
}: {
  member: MemberT;
  userId: number;
  seriesOwnerId: number;
  userIsWorkspaceOwnerOrAdmin: boolean;
  onManageInvitation: () => void;
  onRemoveFromTeam: () => void;
}) {
  const memberIsMe = member.id === String(userId);
  const memberIsSeriesOwner = member.id === String(seriesOwnerId);

  return (
    <Box
      px={2}
      width="100%"
      display="grid"
      gap={2}
      gridTemplateColumns="auto auto 1fr 90px"
      alignItems="center"
    >
      <Box
        top="-10px"
        height={20}
        width={16}
        borderLeftWidth={2}
        borderBottomWidth={2}
        borderColor="border"
        borderStyle="solid"
      />
      <Avatar
        height="2rem"
        width="2rem"
        initials={getNameInitials(member.name)}
        name={member.name}
        type={member.status === 'pending' ? 'pending' : 'user'}
        fontSize="75"
      />
      <Box flexDirection="row">
        <Text truncate>{member.email}&nbsp;</Text>
        {memberIsMe && <Text>(you)</Text>}
      </Box>
      <Box alignItems="flex-end">
        {memberIsSeriesOwner ? (
          <Text color="textWeaker">Owner</Text>
        ) : memberIsMe ? (
          <Text color="textWeaker">Can edit</Text>
        ) : (
          <DropdownMenuRoot>
            <DropdownMenuTrigger>
              <Clickable>
                <MoreIcon size={4} />
              </Clickable>
            </DropdownMenuTrigger>
            <DropdownMenuContainer>
              <DropdownMenuItem
                key="remove-from-presentation"
                id="remove-from-presentation"
                extend={() => ({ userSelect: 'none' })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Remove from presentation
                <Badge compact type="upgrade" />
              </DropdownMenuItem>
              <TooltipOld
                referenceId="remove-from-presentation"
                description="Upgrade to Pro to control individual access"
                placement="right"
              />

              {member.status === 'pending' ? (
                <DropdownMenuItem
                  key="manage-invitation"
                  onClick={onManageInvitation}
                >
                  Manage invitation
                </DropdownMenuItem>
              ) : (
                <>
                  <DropdownMenuItem
                    key="remove-from-team"
                    onClick={onRemoveFromTeam}
                    disabled={!userIsWorkspaceOwnerOrAdmin}
                  >
                    Remove from team
                  </DropdownMenuItem>
                  {!userIsWorkspaceOwnerOrAdmin && (
                    <TooltipOld
                      referenceId="remove-from-team"
                      description="Only the owner of the team can remove members"
                      placement="right"
                    />
                  )}
                </>
              )}
            </DropdownMenuContainer>
          </DropdownMenuRoot>
        )}
      </Box>
    </Box>
  );
}
