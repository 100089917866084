import useSWR, { mutate, useSWRConfig } from 'swr';
import userCache from '@mentimeter/user';
import {
  getFoldersRequest,
  getFoldersResponse,
  type GetFoldersResponse,
} from '@core-api/folders/folders/{dashboard_type}';
import type { Folder } from '@core-api/folders/types/response';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { SWRInfiniteConfiguration } from 'swr/infinite';
import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import { DashboardTypeEnum } from 'features/dashboard/presentation-view/types';

export const swrPresentationPageFolderConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  keepPreviousData: true,
};

export const swrPresentationPageConfig: SWRInfiniteConfiguration = {
  ...swrPresentationPageFolderConfig,
  revalidateAll: true,
};

const getPath = (dashboardType: DashboardTypeEnum) => {
  switch (dashboardType) {
    case DashboardTypeEnum.PRIVATE:
      return 'me';
    case DashboardTypeEnum.WORKSPACE:
      return 'workspace';
    case DashboardTypeEnum.SHARED_WITH_ME:
      return 'shared-with-me';
  }
};

export const useFoldersCache = () => {
  const { cache } = useSWRConfig();
  const invalidateFoldersCache = () =>
    Promise.all(
      Array.from((cache as Map<string, any>).keys())
        .filter(
          (cacheName) =>
            /folders\/me/i.test(cacheName) ||
            /folders\/shared-with-me/i.test(cacheName) ||
            /folders\/workspace/i.test(cacheName),
        )
        .map((cacheName) => mutate(cacheName)),
    );

  return {
    invalidateFoldersCache,
  };
};

export const useFolders = (
  dashboardEnum: DashboardTypeEnum,
): {
  foldersCount: number;
  folders: Folder[];
  isLoading: boolean;
  error: any;
} => {
  const dashboardType = getPath(dashboardEnum);
  const { data, error, isLoading } = useSWR<GetFoldersResponse>(
    `folders/${dashboardType}`,
    async () => {
      const region = userCache.region;
      const userAuth = userCache.getToken();

      const response = await fetch(
        getFoldersRequest({ region, userAuth, dashboardType }),
      );

      if (!response.ok && ![401, 404].includes(response.status)) {
        const e = new MentiError('Error fetching folders for move modal', {
          feature: 'folders',
        });
        captureException(e);
        throw e;
      }

      return getFoldersResponse(response);
    },
    swrPresentationPageFolderConfig,
  );

  const folders = useMemo(() => data?.data ?? [], [data]);

  return {
    foldersCount: folders.length ?? 0,
    folders,
    isLoading,
    error,
  };
};
