import { FolderArrowIcon } from '@mentimeter/ragnar-visuals';
import { Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { ContextMenu } from '../ContextMenu';

export interface MovePresentationProps {
  disabled?: boolean;
  onSelect: () => void;
}

export function MovePresentation({
  disabled = false,
  onSelect,
}: MovePresentationProps) {
  return (
    <Tooltip
      trigger={
        <ContextMenu.Item onSelect={onSelect} disabled={disabled}>
          <ContextMenu.ItemIcon>
            <FolderArrowIcon color={disabled ? 'textDisabled' : 'text'} />
          </ContextMenu.ItemIcon>
          <ContextMenu.ItemLabel color="text">Move to...</ContextMenu.ItemLabel>
        </ContextMenu.Item>
      }
      side="left"
    >
      {disabled && 'You cannot move this menti'}
    </Tooltip>
  );
}
