import React, { type ReactNode } from 'react';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useUser } from '@mentimeter/user';
import { gtmTrack } from '@mentimeter/google-tracking';
import { trackUser } from '@api/tracking/client';
import type {
  TrackingContext,
  TrackingPlacement,
} from '@mentimeter/http-clients';
import type { TrackingProperties, UpgradeEvent } from '../../types';
import { trackOpenPaywall } from '../tracking/track-open-paywall';
import { SelfUpgradePopoverContent } from './popover/SelfUpgradePopoverContent';

export function SelfUpgradePopover({
  title,
  description,
  width = 300,
  upgradeEvent,
  upgradeEventContext,
  trackingContext,
  trackingPlacement,
  trackingProperties,
  children,
}: {
  title: string;
  description: string;
  width?: number;
  upgradeEvent: UpgradeEvent;
  upgradeEventContext: string;
  trackingContext: TrackingContext;
  trackingPlacement: TrackingPlacement;
  trackingProperties?: TrackingProperties;
  children: ReactNode;
}) {
  const { data: workspace } = useWorkspace();
  const { user } = useUser();

  return (
    <SelfUpgradePopoverContent
      title={title}
      description={description}
      width={width}
      trackClickUpgrade={trackClickUpgrade}
      onOpen={onOpenPaywall}
    >
      {children}
    </SelfUpgradePopoverContent>
  );

  function trackClickUpgrade() {
    gtmTrack({
      event: 'clickInteraction',
      type: 'upgradeFromApp',
    });

    trackUser({
      event: upgradeEvent,
      properties: {
        context: upgradeEventContext,
        'current plan': user?.features.type || 'registered',
        'active team members': workspace?.memberStats.active || 0,
        ...trackingProperties,
      },
    });
  }

  function onOpenPaywall() {
    trackOpenPaywall({
      trackingContext,
      trackingPlacement,
      upgradeType: 'Self upgrade',
    });
  }
}
