import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { FeatureOwner } from '@mentimeter/error-utils/feature-owners';
import userCache from '@mentimeter/user';
import { patchMoveRequest } from '@core-api/presentation-collection/presentation-collection/{series_id}/move';

export async function movePresentation(
  seriesId: string,
  workspaceId: number | null,
  feature: FeatureOwner = 'move-modal',
): Promise<void> {
  const region = userCache.region;
  const sessionToken = userCache.getToken();
  const params = {
    region,
    userAuth: sessionToken,
    seriesId,
    workspaceId,
    folderId: null,
  };

  try {
    const response = await fetch(patchMoveRequest(params));
    if (!response.ok && ![401, 404].includes(response.status)) {
      const err = new MentiError('Error moving presentation', {
        feature,
      });
      captureException(err);
    }
  } catch (error) {
    const err = new MentiError('Fetch failed when moving presentation', {
      feature,
      cause: error,
    });
    captureException(err);
  }
}
