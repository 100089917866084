import { useMemo } from 'react';
import {
  getMoveInfoRequest,
  getMoveInfoResponse,
  type GetMoveInfoResponse,
} from '@core-api/folders/folders/move-info';
import { userCache } from '@mentimeter/user';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import useSWR from 'swr';
import { type FolderIconType, type FolderNodeType } from './types';

export function useFoldersMoveInfo(
  shouldFetch: boolean,
  seriesId: string,
  currentLocationId: string,
) {
  const { isLoading, data, error } = useSWR<GetMoveInfoResponse>(
    shouldFetch ? `/folders/move-info/${seriesId}` : null,
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      const response = await fetch(
        getMoveInfoRequest({ seriesId, region, userAuth: sessionToken }),
      );

      if (!response.ok && ![401, 404].includes(response.status)) {
        const e = new MentiError('Error fetching folders for move modal', {
          feature: 'move-modal',
        });
        captureException(e);
        throw e;
      }

      return getMoveInfoResponse(response);
    },
    {
      revalidateOnFocus: false,
    },
  );

  return useMemo(() => {
    const folderMap = new Map<string, FolderNodeType>();
    const folders = transformResponseData(folderMap, data?.data ?? []);

    const currentFolder = folderMap.get(currentLocationId);

    if (currentFolder) {
      currentFolder.selectedMessage = 'This Menti is already in this location.';
    }

    const initialPath = currentFolder?.path || '';

    return { isLoading, folders, initialPath, error };
  }, [isLoading, data, error, currentLocationId]);
}

type Dashboard = GetMoveInfoResponse['data'][number];
type Folder = Dashboard['folders'][number];

function transformResponseData(
  folderMap: Map<string, FolderNodeType>,
  responseNodes: Dashboard[] = [],
  parentPath: string = '',
): FolderNodeType[] {
  return responseNodes.map((responseNode: Dashboard) => {
    const {
      name,
      id,
      canMoveTo,
      invalidMoveDescription,
      folders = [],
    } = responseNode;
    const path = `${parentPath}/${id}`;
    const iconName = id as FolderIconType;

    const dashboardBaseLink = {
      me: '/app/folder/',
      workspace: '/app/workspace/folder/',
      sharedWithMe: '/app/shared-with-me/folder/',
    }[id];
    const link = {
      me: '/app/dashboard',
      workspace: '/app/workspace',
      sharedWithMe: '/app/shared-with-me',
    }[id];

    const folder = {
      path,
      id,
      name,
      canMoveTo,
      invalidMoveDescription,
      children: transformFolderData(
        folderMap,
        folders,
        path,
        dashboardBaseLink,
      ),
      iconName,
      link,
    } as FolderNodeType;

    folderMap.set(id.toString(), folder);

    if (
      !responseNode.canMoveTo &&
      id === 'sharedWithMe' &&
      folder.children.length > 0
    ) {
      folder.selectedMessage = 'Select a folder within Shared with me.';
    }

    return folder;
  });
}

function transformFolderData(
  folderMap: Map<string, FolderNodeType>,
  responseNodes: Folder[] = [],
  parentPath: string = '',
  dashboardBaseLink: string,
): FolderNodeType[] {
  return responseNodes.map((responseNode: Folder) => {
    const {
      name,
      id,
      canMoveTo,
      invalidMoveDescription,
      shared,
      children = [],
    } = responseNode;
    const path = `${parentPath}/${id}`;
    const iconName = 'folder';
    const link = `${dashboardBaseLink}${id}`;

    const folder = {
      path,
      id,
      name,
      canMoveTo,
      invalidMoveDescription,
      shared,
      children: transformFolderData(
        folderMap,
        children,
        path,
        dashboardBaseLink,
      ),
      iconName,
      link,
    } as FolderNodeType;

    folderMap.set(id.toString(), folder);

    return folder;
  });
}
