import { createContext, useContext } from 'react';

interface MobileContextMenuContextProps {
  onDismiss: () => void;
}

export const UseMobileContextMenuContext = createContext<
  MobileContextMenuContextProps | undefined
>(undefined);

export function useMobileContextMenuContext(): MobileContextMenuContextProps {
  const context = useContext(UseMobileContextMenuContext);
  if (context === undefined) {
    throw new Error(
      'useMobileContextMenuContext can only be called from a component that is a descendant of MobileContextMenu.',
    );
  }
  return context;
}
