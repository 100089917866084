// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PutResultsSharingRequest {
  region: 'eu' | 'us';
  userAuth: string;
  slideDeckId: string;
  resultsSharing: 'on' | 'open' | 'off';
}

export function putResultsSharingRequest(
  requestParams: PutResultsSharingRequest,
): Request {
  const data = {
    results_sharing: toSnakeKeys(requestParams.resultsSharing),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation-collection/slide-deck/${requestParams.slideDeckId}/results-sharing`;
  return new Request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
