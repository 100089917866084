const DASHBOARD = 'dashboard';
const SERIES = 'series';

export const seriesCacheKey = (seriesId: string) => [
  DASHBOARD,
  SERIES,
  seriesId,
];

export const cacheKeys = {
  seriesCacheKey,
};
