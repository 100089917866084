import { Form, TextInput } from '@mentimeter/ragnar-form';
import { useToast } from '@mentimeter/toast';
import { NAME_MAX_CHAR } from 'features/dashboard/presentation-view/constants';
import { useSeriesCache } from 'features/dashboard/use-series-cache';
import userCache from '@mentimeter/user';
import { putNameRequest } from '@core-api/presentation-collection/presentation-collection/{slide_deck_public_key}/name';
import { putNameRequest as putFoldersNameRequest } from '@core-api/folders/folders/{id}/name';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { useFoldersCache } from 'lib/useFolders';

export interface RenameItemProps {
  id: string;
  isFolder?: boolean;
  name: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const RenameModal = ({
  isFolder,
  id,
  name,
  onOpenChange,
  open,
}: RenameItemProps) => {
  const toast = useToast();
  const { invalidate: invalidateSeriesCache } = useSeriesCache();
  const { invalidateFoldersCache } = useFoldersCache();

  const handleRename = async ({ newName }: { newName: string }) => {
    onOpenChange(false);
    if (name === newName) {
      return;
    }
    const handleSuccess = async () => {
      await invalidateSeriesCache();
      toast.displayToast({
        description: `"${name}" renamed to "${newName}"`,
        autoDismiss: true,
      });
    };
    const handleError = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isFolder ? await invalidateFoldersCache() : await invalidateSeriesCache();
      toast.displayToast({
        description: `Could not rename "${name}"`,
        autoDismiss: true,
        variant: 'negative',
      });
    };
    if (isFolder) {
      try {
        const region = userCache.region;
        const userAuth = userCache.getToken();

        await fetch(
          putFoldersNameRequest({
            region,
            userAuth,
            id: Number(id),
            name: newName,
          }),
        );

        await invalidateFoldersCache();
        handleSuccess();
      } catch (error) {
        handleError();
      }
    } else {
      try {
        await fetch(
          putNameRequest({
            region: userCache.region,
            userAuth: userCache.getToken(),
            slideDeckPublicKey: id,
            name: newName,
          }),
        );
        handleSuccess();
      } catch {
        handleError();
      }
    }
  };

  const title = `Rename "${name}"`;

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalContainer>
        <Form
          validationSchema={null}
          validate={(values) => {
            const errors: Record<string, string> = {};
            if (values.newName.length <= 0) {
              errors.newName = 'Enter a new name';
            }
            return errors;
          }}
          validateOnBlur={false}
          initialValues={{ newName: name }}
          onSubmit={handleRename}
        >
          <ModalHeader dismissable={false}>{title}</ModalHeader>
          <ModalBody>
            <TextInput
              width="100%"
              autoFocus
              autoComplete="off"
              maxLength={NAME_MAX_CHAR}
              label=""
              name="newName"
              placeholder="Name"
            />
          </ModalBody>
          <ModalFooter>
            <ModalDismiss>
              <Button variant="secondary">Cancel</Button>
            </ModalDismiss>
            <Button variant="primary" type="submit">
              Rename
            </Button>
          </ModalFooter>
        </Form>
      </ModalContainer>
    </ModalRoot>
  );
};
