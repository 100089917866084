import { useCallback, useState } from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';

interface ReadOnlyInputT extends BoxT {
  value: string;
  onCopiedLink?: () => void;
  copyLabel?: string;
  copiedLabel?: string;
  ariaLabel?: string;
}

const CHANGE_BUTTON_STATE_DELAY = 2500;

export const ReadOnlyInput = ({
  value,
  onCopiedLink,
  copyLabel = 'Copy',
  copiedLabel = 'Copied!',
  ariaLabel,
}: ReadOnlyInputT) => {
  const [state, setState] = useState<'idle' | 'copying' | 'copied'>('idle');

  const copyLink = useCallback(() => {
    setState('copying');

    if (navigator.clipboard.writeText) {
      navigator.clipboard.writeText(value).then(() => {
        setState('copied');
        setTimeout(() => {
          setState('idle');
        }, CHANGE_BUTTON_STATE_DELAY);
      });
    }

    onCopiedLink?.();
  }, [onCopiedLink, value]);

  return (
    <Box width={1} flexDirection="row" gap={2}>
      <Box width="100%" flex="1 1 auto">
        <TextInputItem
          readOnly
          name="id"
          value={value}
          inputSize="compact"
          onCopy={onCopiedLink}
        />
      </Box>
      {window.isSecureContext && (
        <Button
          variant="primary"
          onClick={copyLink}
          aria-label={ariaLabel}
          extend={() => ({
            minWidth: '95px',
            ':active': {
              transform: 'translateY(2px)',
            },
          })}
        >
          {state === 'idle' ? copyLabel : copiedLabel}
        </Button>
      )}
    </Box>
  );
};
