import React, { type ReactNode } from 'react';
import type {
  TrackingPlacement,
  TrackingContext,
} from '@mentimeter/http-clients';
import { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { RequestUpgradePopover } from '../../lib/request-upgrade';
import { SelfUpgradePopover } from '../../lib/self-upgrade';
import { Paywall } from '../../lib/paywall/Paywall';
import type {
  PaywallSeriesTrackingProperties,
  UpgradeEvent,
} from '../../types';

interface ShareModalResultsAccessPaywallDataHandlerProps {
  trackingContext: TrackingContext;
  trackingPlacement: TrackingPlacement;
  trackingProperties: PaywallSeriesTrackingProperties;
  upgradeEvent: UpgradeEvent;
  upgradeContext: 'Presentation privacy';
  children: ReactNode;
}

export function ShareModalResultsAccessPaywallDataHandler({
  trackingContext,
  trackingPlacement,
  upgradeEvent,
  upgradeContext,
  children,
  trackingProperties,
}: ShareModalResultsAccessPaywallDataHandlerProps) {
  return (
    <Paywall
      enabled
      requiredPlan={UserFeaturesTypeEnum.BASIC}
      requestUpgradeContent={
        <RequestUpgradePopover
          title="Want to keep presentation results private?"
          description="To control who can request results after a presentation, your role needs to be upgraded by an admin."
          trackingContext={trackingContext}
          trackingPlacement={trackingPlacement}
        >
          {children}
        </RequestUpgradePopover>
      }
      selfUpgradeContent={
        <SelfUpgradePopover
          title="Presentation access"
          description="With a free account, participants can request the results after the presentation. Upgrade to control who can see the data."
          width={330}
          upgradeEvent={upgradeEvent}
          upgradeEventContext={upgradeContext}
          trackingContext={trackingContext}
          trackingPlacement={trackingPlacement}
          trackingProperties={trackingProperties}
        >
          {children}
        </SelfUpgradePopover>
      }
    >
      {children}
    </Paywall>
  );
}
