import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { styled } from '@mentimeter/ragnar-styled';
import { Label } from '../label';
import { Box } from '../box';
import { Tooltip, TriggerDescriptive } from '../tooltip';
import { ToggleItem, type ToggleItemProps } from './toggle-item';
import {
  toggleLabelVariants,
  toggleVariants,
  type ToggleVariantProps,
  type ToggleLabelVariantProps,
} from './Toggle.variant';

interface ToggleProps
  extends ToggleItemProps,
    ToggleVariantProps,
    Pick<ToggleLabelVariantProps, 'fontWeight'> {
  label: React.ReactNode;
  description?: string;
  id: string;
  className?: string;
  disabled?: boolean | undefined;
}

const ToggleComponent = ({
  label,
  description,
  id,
  name = '',
  onChange,
  onBlur,
  onFocus,
  checked = false,
  disabled,
  size = 'default',
  position,
  fontWeight,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  className,
  ...boxProps
}: ToggleProps) => {
  return (
    <Box
      className={clsx(toggleVariants({ position }), className)}
      {...boxProps}
    >
      <Box className="flex-row flex-auto items-center justify-start">
        <Label
          htmlFor={id}
          aria-label={ariaLabel}
          disabled={Boolean(disabled)}
          className={toggleLabelVariants({
            fontWeight,
          })}
        >
          {label}
        </Label>
        {description && (
          <Tooltip trigger={<TriggerDescriptive />}>{description}</Tooltip>
        )}
      </Box>

      <ToggleItem
        id={id}
        name={name}
        disabled={disabled}
        checked={checked}
        size={size}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        data-testid={dataTestId}
      />
    </Box>
  );
};

export const Toggle = styled(ToggleComponent)('width', 'kosmosSpacing');
