import { LinkClickable } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { isTouchDevice } from '@mentimeter/ragnar-device';
import type { ForwardedRef } from 'react';
import type React from 'react';
import { getNameInitials } from '@mentimeter/user';
import { forwardRef, useEffect, useState } from 'react';
import { ResultsChartIcon } from '@mentimeter/ragnar-visuals';
import dynamic from 'next/dynamic';
import { getRelativeTimeText } from 'lib/date';
import type { DashboardTypeEnum } from 'features/dashboard/presentation-view/types';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';
import { Box } from '@mentimeter/ragnar-ui/box';
import { IconButton } from '@mentimeter/ragnar-ui/icon-button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { HoverPreviewCard } from '../../shared/hover-card';
import { DashboardSlidePreview } from './DashboardSlidePreview';

const TemplateHelpersDataHandler = dynamic(
  () =>
    import('./TemplateHelpersDataHandler').then(
      (module) => module.TemplateHelpersDataHandler,
    ),
  { ssr: false },
);

interface PresentationCardProps {
  id?: string | undefined;
  seriesId: string;
  seriesName: string;
  seriesUpdatedAt: string;
  actions: React.ReactNode;
  sharedIcon?: React.ReactNode;
  sharingDetails?: React.ReactNode;
  showEditedAt?: boolean;
  hasResults?: boolean;
  showTemplateHelpers?: boolean;
  dashboardType: DashboardTypeEnum | 'recent';
  onClickPresentation: () => void;
  onClickViewResults: () => void;
  ownerAvatar:
    | {
        id: number;
        name: string;
        pictureUrl: string | undefined;
      }
    | undefined;
}

export const PresentationCard = forwardRef(
  (
    {
      id,
      seriesId,
      seriesName,
      seriesUpdatedAt,
      actions,
      sharedIcon,
      sharingDetails,
      showEditedAt = true,
      hasResults,
      ownerAvatar,
      showTemplateHelpers = false,
      dashboardType,
      onClickPresentation,
      onClickViewResults,
    }: PresentationCardProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const href = buildPresentationPath({
      mode: 'edit',
      seriesId,
      currentSearchParams: new URLSearchParams({
        source: `grid-card-${dashboardType.toLowerCase()}`,
      }),
    });

    const [showActions, setShowActions] = useState(false);

    return (
      <InteractivePresentationCard onInteraction={setShowActions}>
        <PresentationPreview
          id={id}
          seriesId={seriesId}
          name={seriesName}
          href={href}
          onClick={onClickPresentation}
        />
        <Box flexDirection="row" alignItems="center" width="100%" mt="space4">
          {ownerAvatar && <OwnerAvatar ownerAvatar={ownerAvatar} />}
          <Box flex="1 1 auto">
            <Box
              flexDirection="row"
              alignItems="center"
              width="100%"
              pr="space4"
            >
              <PresentationName
                ref={ref}
                name={seriesName}
                href={href}
                onClick={onClickPresentation}
              />

              {hasResults && (
                <ViewResults seriesId={seriesId} onClick={onClickViewResults} />
              )}
            </Box>

            {showTemplateHelpers && (
              <TemplateHelpersDataHandler seriesId={seriesId} />
            )}

            <PresentationInfo
              sharingDetails={sharingDetails}
              seriesUpdatedAt={seriesUpdatedAt}
              href={href}
              onClick={onClickPresentation}
              showEditedAt={showEditedAt}
              sharedIcon={sharedIcon}
            />
          </Box>

          <PresentationActions show={showActions}>
            {actions}
          </PresentationActions>
        </Box>
      </InteractivePresentationCard>
    );
  },
);

function OwnerAvatar({
  ownerAvatar,
}: {
  ownerAvatar: {
    id: number;
    name: string;
    pictureUrl: string | undefined;
  };
}) {
  const avatarDescription = `Created by ${ownerAvatar.name}`;

  return (
    <Tooltip
      trigger={
        <Box justifyContent="center" mr="space3" ml="space1">
          <Avatar
            itemId={String(ownerAvatar.id)}
            initials={getNameInitials(ownerAvatar.name).toUpperCase()}
            name={ownerAvatar.name}
            type="user"
            color="black"
            profilePictureUrl={ownerAvatar.pictureUrl}
          />
        </Box>
      }
    >
      {avatarDescription}
    </Tooltip>
  );
}

function InteractivePresentationCard({
  children,
  onInteraction,
}: {
  children: React.ReactNode;
  onInteraction: (isInteracting: boolean) => void;
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [isFocusing, setIsFocusing] = useState(false);
  const isTouch = isTouchDevice();

  useEffect(() => {
    if (isHovering || isFocusing || isTouch) {
      onInteraction(true);
    } else {
      onInteraction(false);
    }
  }, [isHovering, isFocusing, isTouch, onInteraction]);

  return (
    <HoverPreviewCard
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onFocus={(e) => {
        if (isHovering) {
          // Prevent focus taking over when user is engaging with buttons on the card.
          e.preventDefault();
        } else {
          setIsFocusing(true);
        }
      }}
      onBlur={() => {
        setIsFocusing(false);
      }}
    >
      {children}
    </HoverPreviewCard>
  );
}

interface PresentationPreviewProps {
  id?: string | undefined;
  seriesId: string;
  name: string;
  href: string;
  onClick: () => void;
}

function PresentationPreview({
  id,
  seriesId,
  name,
  href,
  onClick,
}: PresentationPreviewProps) {
  return (
    <LinkClickable
      id={id}
      href={href}
      onClick={onClick}
      width="100%"
      zIndex={1}
      aria-label={name}
      borderRadius={2}
      overflow="hidden"
      extend={() => ({
        '&:active': {
          opacity: 1,
        },
        '&:hover': {
          opacity: 1,
        },
      })}
      tabIndex={-1}
    >
      <DashboardSlidePreview seriesId={seriesId} />
    </LinkClickable>
  );
}

interface PresentationNameProps {
  name: string;
  href: string;
  onClick: () => void;
}

const PresentationName = forwardRef<HTMLAnchorElement, PresentationNameProps>(
  ({ name, href, onClick }: PresentationNameProps, ref) => {
    return (
      <LinkClickable
        href={href}
        onClick={onClick}
        data-testid="presentation-card-link"
        tabIndex={0}
        alignItems="center"
        flexDirection="row"
        flex="0 1 auto"
      >
        <Text ref={ref} title={name} truncate color="text">
          {name}
        </Text>
      </LinkClickable>
    );
  },
);

function PresentationActions({
  children,
  show,
}: {
  show: boolean;
  children: React.ReactNode;
}) {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      extend={() => ({
        opacity: show ? 1 : 0,
        transition: 'opacity .15s ease-out',
      })}
    >
      {children}
    </Box>
  );
}

interface PresentationInfoProps {
  sharingDetails: React.ReactNode;
  seriesUpdatedAt: string;
  href: string;
  onClick: () => void;
  showEditedAt: boolean;
  sharedIcon?: React.ReactNode;
}

function PresentationInfo({
  sharingDetails,
  seriesUpdatedAt,
  href,
  onClick,
  showEditedAt,
  sharedIcon,
}: PresentationInfoProps) {
  return (
    <LinkClickable
      href={href}
      onClick={onClick}
      data-testid="presentation-card-link"
      tabIndex={-1}
      mt="space0.5"
      alignItems="center"
      flexDirection="row"
    >
      {showEditedAt ? (
        <Text color="textWeaker" fontSize="87.5">
          {sharingDetails}
          <Box flexDirection="row" alignItems="center" gap="space2">
            {sharedIcon}
            <div>Edited {getRelativeTimeText(seriesUpdatedAt)}</div>
          </Box>
        </Text>
      ) : (
        sharingDetails
      )}
    </LinkClickable>
  );
}

function ViewResults({
  seriesId,
  onClick,
}: {
  seriesId: string;
  onClick: () => void;
}) {
  return (
    <Tooltip
      trigger={
        <IconButton
          variant="secondary"
          ml="space2"
          href={`/app/presentation/${seriesId}/results`}
          onClick={onClick}
          size="compact"
          p={0}
        >
          <ResultsChartIcon color="inherit" />
        </IconButton>
      }
    >
      View results
    </Tooltip>
  );
}
