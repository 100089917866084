import { Children, cloneElement, isValidElement } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import type { DropdownMenuItemProps } from '@mentimeter/ragnar-ui/menu';
import { useMobileContextMenuContext } from './useMobileContextMenuContext';

/**
 * This is a DropdownMenuItem-like component that strives to:
 *  - be a drop-in replacement for DropdownMenuItem as an ActionSheet descendant
 *  - provide some level of prop- and behaviour-compatibility with DropdownMenuItem
 *
 */
export function MobileMenuItem(props: DropdownMenuItemProps) {
  const { onDismiss } = useMobileContextMenuContext();

  if (props.asChild) {
    const child = Children.only(props.children);
    if (isValidElement(child)) {
      return cloneElement<any>(child, {
        flexDirection: 'row',
        alignItems: 'center',
        color: props.color ?? 'inherit',
        disabled: props.disabled,
        py: 2,
        px: 3,
        onClick: (e: React.MouseEvent) => {
          onDismiss();
          props.onSelect?.(e.nativeEvent);
        },
      });
    }
    return null;
  }

  return (
    <Box width="100%">
      <Clickable
        onClick={(e) => {
          onDismiss();
          return props.onSelect?.(e.nativeEvent);
        }}
        flexDirection="row"
        alignItems="center"
        color={props.color}
        disabled={props.disabled}
        py={2}
        px={3}
      >
        {props.children}
      </Clickable>
    </Box>
  );
}
