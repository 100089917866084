import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const toggleIconContainer = cva(
  [
    'relative',
    'p-1',
    'flex-row',
    'items-center',
    'rounded-full',
    'outline-offset-2',
    'outline',
    'outline-interactive-focused',
    'outline-0',
    'peer-focus-visible:outline-4',
    'transition-[background-color,outline-width] duration-100 ease-in-out',
  ],
  {
    variants: {
      size: {
        default: 'w-[48px] h-[28px]',
        compact: 'w-[36px] h-[20px]',
      },
      color: {
        default: 'bg-[--palette-gray-800] group-hover:bg-[--palette-gray-900]',
        checked:
          'bg-button-primary-checked group-hover:bg-button-primary-checked-hover',
        disabled: 'bg-disabled-strong group-hover:bg-disabled-strong',
      },
    },
    defaultVariants: {
      size: 'default',
      color: 'default',
    },
  },
);

export type ToggleIconContainerProps = VariantProps<typeof toggleIconContainer>;
