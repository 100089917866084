import { styled } from '@mentimeter/ragnar-styled';
import { RadioGroup } from '@radix-ui/react-dropdown-menu';
import type { ComponentProps } from 'react';

const DropdownMenuRadioGroupRadixStyled = styled(
  RadioGroup,
  {
    displayName: 'DropdownMenuRadioGroupRadixStyled',
  },
  true,
  ['asChild', 'value', 'onValueChange'],
)('color', 'kosmosSpacing', 'layout', 'flexbox');

export type DropdownMenuRadioGroupProps = ComponentProps<
  typeof DropdownMenuRadioGroupRadixStyled
>;

export const DropdownMenuRadioGroup = DropdownMenuRadioGroupRadixStyled;
