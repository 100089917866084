export const getPresentationCurrentLocationId = (
  item: {
    folderId?: number | null;
    workspaceId: number | null;
    ownerId: number;
  },
  userId: undefined | number,
): number | 'sharedWithMe' | 'me' | 'workspace' => {
  if (item.folderId) {
    return item.folderId;
  }

  if (item.workspaceId) {
    return 'workspace';
  }

  const isItemOwner = item.ownerId === userId;
  if (isItemOwner) {
    return 'me';
  }

  return 'sharedWithMe';
};
