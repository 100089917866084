import type { Slide as PresentationCollectionSlide } from '@mentimeter/presentation-collection-schema/api-types-overrides';
import { commonSlidePropertyKeys } from '../../../common/constants';

export const presentationCollectionSlidePropertyKeys = [
  'slideId',
  'staticContent',
  'speakerNotes',
  // 'legacyQuestionAdminKey',
  ...commonSlidePropertyKeys,
] as const satisfies Array<keyof PresentationCollectionSlide>;
