import type { MemberT } from '@mentimeter/http-clients';

export function sortMembers(
  members: MemberT[],
  seriesOwnerId: number,
  userId: number,
) {
  return members.slice().sort((a, b) => {
    // Prioritize the owner
    if (a.id === String(seriesOwnerId)) return -1;
    if (b.id === String(seriesOwnerId)) return 1;

    // Then prioritize "you"
    if (a.id === String(userId)) return -1;
    if (b.id === String(userId)) return 1;

    // Then prioritize active members and sort them alphabetically
    if (a.status !== 'pending' && b.status !== 'pending') {
      return a.email.localeCompare(b.email);
    }

    // Finally, sort pending members alphabetically
    if (a.status === 'pending' && b.status === 'pending') {
      return a.email.localeCompare(b.email);
    }

    // If a is non-pending and b is pending, a comes first
    if (a.status !== 'pending' && b.status === 'pending') return -1;

    // If a is pending and b is non-pending, b comes first
    if (a.status === 'pending' && b.status !== 'pending') return 1;

    return 0;
  });
}
