// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface DeleteResourceSharingsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The id of the sharing */
  sharingId: string;
}

export function deleteResourceSharingsRequest(
  requestParams: DeleteResourceSharingsRequest,
): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/resource-sharings/${requestParams.sharingId}`;
  return new Request(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
