import type { TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import type { FolderNodeType } from './types';

export function hasNoValidDestinations(
  folders: FolderNodeType[] = [],
): boolean {
  return folders.every(
    (f) => !f.canMoveTo && hasNoValidDestinations(f.children),
  );
}

export const getDashboardTypeFromPath = (pathname: string) => {
  if (pathname.includes('workspace')) return 'workspace';
  if (pathname.includes('shared-with-me')) return 'shared with me';

  return 'private';
};

export function trackMovedPresentation(
  trackingDetails: { context: TrackingContext | 'Editor'; placement: string },
  destinationPath: string,
  isSharedDestination: boolean,
) {
  trackUser({
    event: 'Moved presentation to folder',
    properties: {
      context: trackingDetails.context,
      placement: trackingDetails.placement,
      'shared folder': isSharedDestination,
      'target folder type': getDashboardTypeFromPath(destinationPath),
    },
  });
}
