import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useToast } from '@mentimeter/toast';
import { useMatch } from '@mentimeter/ragnar-device';
import type { TrackingContext } from '@mentimeter/http-clients';
import { TRACKING_PLACEMENT } from 'features/dashboard/presentation-view/constants';
import type { UserCollaborationRole } from '@mentimeter/share-modal';
import { trackUser } from '@api/tracking/client';
import { Button, useButtonState } from '@mentimeter/ragnar-ui/button';
import { Link } from '@mentimeter/ragnar-ui/link';
import {
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { P, Strong } from '@mentimeter/ragnar-ui/typography';
import { useDeletePresentations } from '../../useDeletePresentation';
import { useSeriesCache } from '../../use-series-cache';

export interface DeletePresentationProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  itemName: string;
  itemId: string;
  trackingContext: TrackingContext;
  inUserSharedFolder: boolean;
  userItemRole: UserCollaborationRole;
}

function SuccessMessage({ presentationName }: { presentationName: string }) {
  const isMobile = useMatch({ lessThan: 2 });
  const message = isMobile
    ? `${presentationName} was moved to trash.`
    : `${presentationName} was moved to trash and will be deleted in 30 days.`;
  return (
    <>
      {message}
      <Link href="/app/trash" color="inherit" pl={1}>
        Go to trash
      </Link>
    </>
  );
}

export function DeletePresentationModal({
  open,
  setOpen,
  itemId,
  itemName,
  trackingContext,
  inUserSharedFolder,
  userItemRole,
}: DeletePresentationProps) {
  const toast = useToast();
  const [buttonState, setButtonState] = useButtonState();
  const { invalidate } = useSeriesCache();

  const deletePresentations = useDeletePresentations('delete-presentation');

  const handleOnDelete = useCallback(async () => {
    setButtonState('loading');

    const handleSuccess = async () => {
      setButtonState('success');
      await invalidate();

      trackUser({
        event: 'Deleted presentation',
        properties: {
          'Presentations deleted': 1,
          Placement: TRACKING_PLACEMENT.ACTION_MENU,
          context: trackingContext,
          'deleted presentations': [itemId],
          'user shared folder': inUserSharedFolder,
          role: userItemRole,
        },
      });
      setOpen(false);
      toast.displayToast({
        description: <SuccessMessage presentationName={itemName} />,
        autoDismiss: true,
      });
    };

    const handleError = () => {
      setButtonState('error');
      toast.displayToast({
        description: `Could not delete ${itemName}`,
        autoDismiss: false,
        variant: 'negative',
      });
    };

    deletePresentations([itemId], handleSuccess, handleError);
  }, [
    setButtonState,
    deletePresentations,
    itemId,
    invalidate,
    trackingContext,
    inUserSharedFolder,
    userItemRole,
    setOpen,
    toast,
    itemName,
  ]);

  const title = `Are you sure you want to delete "${itemName}"?`;
  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <P>
            <Strong>{itemName}</Strong> can be restored from the trash for 30
            days.
          </P>
        </ModalBody>
        <ModalFooter>
          <ModalDismiss>
            <Button>No, cancel</Button>
          </ModalDismiss>
          <Button
            variant="negativeWeak"
            state={buttonState}
            onClick={handleOnDelete}
          >
            Yes, delete
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
}
