export function formatVoteCode(id: number) {
  const code = id.toString();

  // Length 8 is a special case where we want two groups of four digits
  const groupSize = code.length === 8 ? 4 : 2;
  const numberOfGroups = Math.ceil(code.length / groupSize);

  return Array.from(new Array(numberOfGroups))
    .map((_, i) => code.substring(i * groupSize, i * groupSize + groupSize))
    .join(' ');
}
