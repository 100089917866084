import { useMatch } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ShareModalSection } from '../../ShareModalSection';
import { ShareModalTitle } from '../../ShareModalTitle';
import { ShareModalText } from '../../ShareModalText';

interface EmbedResultsSectionProps {
  state: 'idle' | 'copying' | 'copied';
  onClick: () => void;
}

export function EmbedResultsSection({
  state,
  onClick,
}: EmbedResultsSectionProps) {
  const isMobile = useMatch({ lessThan: 3 });

  return (
    <ShareModalSection>
      <Box extend={() => ({ flex: '1 1 auto' })}>
        <ShareModalTitle id="embed-live-results" isMobile={isMobile}>
          Embed slides
        </ShareModalTitle>
        <ShareModalText>
          Use this code to embed your Menti online with live results
        </ShareModalText>
      </Box>
      <Box minWidth="100px" alignItems="end">
        <Button
          variant="subtle"
          state={state === 'copying' ? 'loading' : undefined}
          onClick={onClick}
        >
          {state === 'idle' ? 'Copy code' : 'Copied code!'}
        </Button>
      </Box>
    </ShareModalSection>
  );
}
