import { LinkIcon } from '@mentimeter/ragnar-visuals';
import { useCallback, useRef, useState } from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';

const CHANGE_BUTTON_STATE_DELAY = 2500;

export const ShareAndInviteLinkCopyButton = ({
  link,
  onClick,
}: { link: string; onClick: () => void }) => {
  const [state, setState] = useState<'idle' | 'copying' | 'copied'>('idle');
  const timer = useRef<NodeJS.Timeout | null>(null);

  const copyLink = useCallback(() => {
    if (state !== 'idle') return;
    setState('copying');
    onClick();

    if (navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link).then(() => {
        setState('copied');
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setState('idle');
        }, CHANGE_BUTTON_STATE_DELAY);
      });
    }
  }, [link, state, onClick]);

  return (
    <Button iconLeading={LinkIcon} variant="subtle" onClick={copyLink}>
      {state === 'copied' ? 'Copied!' : 'Copy link'}
    </Button>
  );
};
