import React from 'react';
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalPortal,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';

export interface ShareModalLayoutProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function ShareModalLayout({
  open,
  setOpen,
  children,
}: ShareModalLayoutProps & { children: React.ReactNode }) {
  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalPortal>
        <ModalOverlay>
          <ModalContent topAligned={true} size="small">
            <ModalHeader dismissLabel="Close share dialog">Share</ModalHeader>
            <ModalBody>{children}</ModalBody>
          </ModalContent>
        </ModalOverlay>
      </ModalPortal>
    </ModalRoot>
  );
}
