import React from 'react';
import { Box } from '../../box';
import { toggleIcon } from './ToggleIcon.variants';

interface ToggleIcon {
  checked: boolean;
  disabled: boolean;
  size?: 'default' | 'compact' | undefined;
}

export const ToggleIcon = ({ checked, disabled, size }: ToggleIcon) => {
  const color =
    checked && !disabled ? 'checked' : disabled ? 'disabled' : 'default';
  return <Box as="span" className={toggleIcon({ size, checked, color })} />;
};
