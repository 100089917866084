import React from 'react';
import type { CollaboratorType } from '@mentimeter/http-clients';
import { getNameInitials } from '@mentimeter/user';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TextEllipsis } from '@mentimeter/ragnar-ui/text-ellipsis';
import type { CollaborationResourceType } from '../ManageCollaboratorsUi';
import { ViewPermissionTooltip } from './ViewPermissionTooltip';

export const OwnerDetails = () => (
  <Text fontSize="87.5" fontWeight="semiBold">
    Owner
  </Text>
);

export const accessDetailsCopy = (resourceType: CollaborationResourceType) =>
  `These people can access this ${resourceType === 'folder' ? 'folder' : 'Menti'}`;

export const AccessDetails = ({
  resourceType,
}: {
  resourceType: CollaborationResourceType;
}) => {
  const text = accessDetailsCopy(resourceType);
  return (
    <Box
      mt="space6"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Text fontSize={1}>{text}</Text>
    </Box>
  );
};
export const pendingTypes = new Set<CollaboratorType>([
  'invitee',
  'folder-invitee',
]);

export const CollaboratorDetails = ({
  collaboratorUserName,
  collaboratorUserId,
  collaboratorUserEmail,
  collaboratorProfilePictureUrl,
  collaboratorIsCurrentUser,
  showViewPermissionTooltip,
  collaboratorType,
}: {
  collaboratorUserName: string;
  collaboratorUserId: string;
  collaboratorUserEmail: string;
  collaboratorProfilePictureUrl: string | null;
  collaboratorIsCurrentUser: boolean;
  showViewPermissionTooltip: boolean;
  collaboratorType: CollaboratorType;
}) => (
  <>
    <Avatar
      size="large"
      itemId={collaboratorUserEmail}
      type={pendingTypes.has(collaboratorType) ? 'pending' : 'user'}
      initials={getNameInitials(collaboratorUserName)}
      name={collaboratorUserName}
      profilePictureUrl={collaboratorProfilePictureUrl}
    />
    <Box
      ml={2}
      flex="1 1 auto"
      flexDirection="column"
      alignItems="flex-start"
      mr="space8"
    >
      <Box flexDirection="row" alignItems="center" width="100%">
        <TextEllipsis
          text={`${collaboratorUserName} ${collaboratorIsCurrentUser ? ' (me)' : ''}`}
          fontSize="87.5"
          fontWeight="semiBold"
          tooltipDescription={`${collaboratorUserName} ${collaboratorIsCurrentUser ? ' (me)' : ''}`}
          id={`user-email-${collaboratorUserName}`}
        />
        {showViewPermissionTooltip && (
          <ViewPermissionTooltip collaboratorUserId={collaboratorUserId} />
        )}
      </Box>

      <TextEllipsis
        text={collaboratorUserEmail}
        fontSize="87.5"
        tooltipDescription={collaboratorUserEmail}
        id={`user-email-${collaboratorUserEmail}`}
      />
    </Box>
  </>
);
