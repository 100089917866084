import { useEffect, useRef } from 'react';
import { TrackingContext } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import type {
  ResourceEntity,
  SeriesCollaborator,
} from '@core-api/collaboration/types/response';
import { CollaboratorsList } from '../CollaboratorsList';
import { FolderCollaboratorsList } from '../FolderCollaboratorsList';
import type { UserCollaborationRole } from '../CollaborationFolderDataHandler';
import type { CollaborationResourceType } from '../ManageCollaboratorsUi';
import { AccessDetails } from './CollaboratorDetails';

export const ExistingCollaboratorsList = ({
  resourceType,
  collaborators,
  currentUserId,
  resourceOwnerId,
  trackingContext,
  newlyAddedCollaboratorEmails,
  userCollaborationRole,
  resourceId,
  setError,
  setNewlyAddedCollaboratorEmails,
  updateCollaborators,
}: {
  resourceType: CollaborationResourceType;
  collaborators: SeriesCollaborator[] | ResourceEntity[];
  currentUserId: number;
  resourceOwnerId: number;
  trackingContext: TrackingContext;
  newlyAddedCollaboratorEmails: string[];
  userCollaborationRole: UserCollaborationRole;
  resourceId: string;
  setError: (arg: string | null) => void;
  setNewlyAddedCollaboratorEmails: (ids: string[]) => void;
  updateCollaborators: () => Promise<void>;
}) => {
  const collaboratorRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    const firstAddedCollaboratorEmail = newlyAddedCollaboratorEmails[0];
    if (
      firstAddedCollaboratorEmail &&
      collaboratorRefs.current[firstAddedCollaboratorEmail]
    ) {
      collaboratorRefs.current[firstAddedCollaboratorEmail]?.scrollIntoView({
        behavior: 'smooth',
      });
      setTimeout(() => {
        setNewlyAddedCollaboratorEmails([]);
      }, 2000);
    }
  }, [newlyAddedCollaboratorEmails, setNewlyAddedCollaboratorEmails]);

  const collaboratorItems =
    resourceType === 'folder' ? (
      <FolderCollaboratorsList
        collaboratorRefs={collaboratorRefs}
        resourceType={resourceType}
        collaborators={collaborators as ResourceEntity[]}
        currentUserId={currentUserId}
        ownerId={resourceOwnerId}
        currentFolderId={resourceId}
        updateCollaborators={updateCollaborators}
        newlyAddedCollaboratorEmails={newlyAddedCollaboratorEmails}
        trackingContext={trackingContext}
      />
    ) : (
      <CollaboratorsList
        collaboratorRefs={collaboratorRefs}
        resourceType={resourceType}
        collaborators={collaborators as SeriesCollaborator[]}
        updateCollaborators={updateCollaborators}
        newlyAddedCollaboratorEmails={newlyAddedCollaboratorEmails}
        currentUserId={currentUserId}
        ownerId={resourceOwnerId}
        setError={setError}
        userCollaborationRole={userCollaborationRole}
        context={trackingContext}
        placement={
          trackingContext === TrackingContext.Results
            ? 'Invite modal'
            : 'Collaboration tab'
        }
      />
    );

  return (
    <>
      <AccessDetails resourceType={resourceType} />
      <Box width="100%">{collaboratorItems}</Box>
    </>
  );
};
