/* eslint-disable menti-react/filename-convention--jsx */
import type { Series } from '@mentimeter/http-clients';
import {
  Channels,
  type ResultPayload,
  SeriesPrivateEvents,
  useSubscribe,
} from '@mentimeter/realtime';
import React from 'react';
import { useSWRConfig } from 'swr';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import {
  getIdentifiedChoiceResultsCacheKey,
  getIdentifiedScalesResultsCacheKey,
  getIdentifiedTextResponsesCacheKey,
  resultCacheKey,
  seriesHasResultsCacheKey,
} from './cache-keys';

interface Config {
  device_id: string;
  seriesId: Series['id'];
  shouldSubscribe?: boolean;
}

type InternalConfig = Pick<Config, 'device_id'>;

export const SubscribeNetworkCache = ({
  seriesId,
}: Pick<Config, 'seriesId'>) => {
  const { mutate } = useSWRConfig();
  useSubscribe(
    { channel: Channels.SERIES_PRIVATE, value: seriesId },
    SeriesPrivateEvents.UPDATE_NETWORK_CACHE,
    (event) => {
      if (event.data.cacheKey) {
        void mutate(event.data.cacheKey);
      }
      if (event.data.cacheKeys) {
        event.data.cacheKeys.forEach((cacheKey) => mutate(cacheKey));
      }
    },
  );

  return null;
};

const context = React.createContext<InternalConfig | undefined>(undefined);

const { Provider } = context;

export const CoreHooksProvider = ({
  children,
  shouldSubscribe = true,
  seriesId,
  device_id,
}: Config & {
  children: React.ReactNode;
}) => {
  return (
    <>
      {shouldSubscribe && <SubscribeNetworkCache seriesId={seriesId} />}
      <Provider value={{ device_id }}>{children}</Provider>
    </>
  );
};

export const useSubscribeToResults = (
  seriesId: string,
  {
    shouldSubscribe = true,
    shouldRevalidate = true,
  }: {
    shouldSubscribe?: boolean;
    shouldRevalidate?: boolean;
  } = {},
) => {
  const { mutate } = useSWRConfig();

  const invalidateIdentifiedResultsCaches = async (questionId: string) => {
    void mutate(getIdentifiedChoiceResultsCacheKey(questionId));
    void mutate(getIdentifiedScalesResultsCacheKey(questionId));
    void mutate(getIdentifiedTextResponsesCacheKey(questionId));
  };

  const invalidateAllRelevantCaches = (
    questionId: string,
    seriesId: string,
  ) => {
    void mutate(resultCacheKey(questionId));
    void invalidateIdentifiedResultsCaches(questionId);
    void mutate(seriesHasResultsCacheKey(seriesId));
  };

  const setCachesFromEventPayload = ({ payload }: ResultPayload) => {
    const { question_id, result } = payload;

    void mutate(resultCacheKey(question_id), result, {
      revalidate: shouldRevalidate,
    });
    void mutate(seriesHasResultsCacheKey(seriesId));
    // This event is not returning identities at the moment, invalidate cache to fetch again
    void invalidateIdentifiedResultsCaches(question_id);
  };

  useSubscribe(
    { channel: Channels.SERIES_PRIVATE, value: seriesId, shouldSubscribe },
    SeriesPrivateEvents.RESULT,
    (event) => {
      try {
        setCachesFromEventPayload({ payload: event.data.payload });
      } catch (error) {
        // Fall back to just mutating the cache key without the result payload
        invalidateAllRelevantCaches(event.data.payload.question_id, seriesId);

        if (error && typeof error === 'object') {
          Object.assign(error, { websocketMessage: event });
          captureException(
            new MentiError('Error parsing subscribe to result', {
              cause: error,
              feature: 'live',
            }),
          );
        }
      }
    },
  );
  useSubscribe(
    { channel: Channels.SERIES_PRIVATE, value: seriesId, shouldSubscribe },
    SeriesPrivateEvents.RESULT_HAS_CHANGED,
    (event) => {
      if (event.data.payload.question_id) {
        invalidateAllRelevantCaches(event.data.payload.question_id, seriesId);
      }
    },
  );
};

export const useCoreHookContext = () => {
  const value = React.useContext(context);
  if (!value) {
    throw new Error(
      'could not find context. ensure that call to hook is wrapped in a CoreHooksProvider',
    );
  }

  return value;
};
