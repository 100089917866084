import React from 'react';
import { UsersIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { accessDetailsCopy } from './collaborators-list/CollaboratorDetails';
import type { CollaborationResourceType } from './ManageCollaboratorsUi';

export const WorkspaceItemNotification = ({
  resourceType,
}: {
  resourceType: CollaborationResourceType;
}) => {
  const text = accessDetailsCopy(resourceType);
  return (
    <Box
      width="100%"
      alignItems="center"
      flex="1 1 auto"
      flexDirection="row"
      mt={3}
    >
      <Box
        height="40px"
        width="40px"
        borderRadius="100%"
        bg="primaryWeakest"
        justifyContent="center"
        mr={2}
        alignItems="center"
      >
        <UsersIcon size={3} />
      </Box>
      <Box ml={3}>
        <Text fontWeight="semiBold" mb={1}>
          {text}
        </Text>
        <Text>Everyone in your workspace can edit</Text>
      </Box>
    </Box>
  );
};
