import type { UserPresentationRole } from '@mentimeter/http-clients';
import { useRouter } from '@mentimeter/next-navigation';
import { CrossIcon, MoreIcon } from '@mentimeter/ragnar-visuals';
import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';
import { Actionsheet } from '@mentimeter/ragnar-ui/actionsheet';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';
import { UseContextMenuContext } from '../useContextMenuContext';
import type { ContextMenuProps } from '../useContextMenuContext';
import { UseMobileContextMenuContext } from './useMobileContextMenuContext';

/**
 * This is a DropdownMenu-like component that strives to:
 * - be a mobile alternative for DropdownMenu while being compatible with and similar to ContextMenu
 */
export function MobileContextMenu({
  trackingContext,
  item,
  user,
  children,
}: PropsWithChildren<ContextMenuProps>) {
  const [open, setOpen] = useState(false);
  const isItemOwner = item.ownerId === user?.id;
  const userPresentationRole: UserPresentationRole = isItemOwner
    ? 'Owner'
    : 'Collaborator';
  const router = useRouter();
  const handleOnDismiss = () => setOpen(false);

  return (
    <>
      <Clickable onClick={() => setOpen((open) => !open)}>
        <MoreIcon size={5} />
      </Clickable>
      <Actionsheet show={open} onDismiss={handleOnDismiss}>
        <Container>
          <Header title={item.name} onDismiss={handleOnDismiss} />
          <UseContextMenuContext.Provider
            value={{
              trackingContext,
              user,
              userPresentationRole,
              router,
            }}
          >
            <UseMobileContextMenuContext.Provider
              value={{ onDismiss: handleOnDismiss }}
            >
              {children}
            </UseMobileContextMenuContext.Provider>
          </UseContextMenuContext.Provider>
        </Container>
      </Actionsheet>
    </>
  );
}

function Container({ children }: { children: ReactNode }) {
  return (
    <Box
      alignItems="stretch"
      width="100vw"
      bg="bg"
      p={3}
      pb={4}
      extend={({ theme }) => ({
        borderTopLeftRadius: `${theme.radii[2]}px`,
        borderTopRightRadius: `${theme.radii[2]}px`,
      })}
    >
      {children}
    </Box>
  );
}

function Header({
  title,
  onDismiss,
}: {
  title: string;
  onDismiss: () => void;
}) {
  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      width="100%"
      py={3}
    >
      <Text fontWeight="semiBold" mr={2} truncate>
        {title}
      </Text>
      <Clickable aria-label="Close action menu" onClick={onDismiss}>
        <CrossIcon size={5} />
      </Clickable>
    </Box>
  );
}
