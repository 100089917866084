import {
  getPotentialCollaboratorsResponse,
  getPotentialCollaboratorsRequest,
  type GetPotentialCollaboratorsResponse,
} from '@core-api/collaboration/collaboration/potential-collaborators';
import userCache from '@mentimeter/user';
import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import type { Entity } from '@core-api/collaboration/types/response';

const PAGE_SIZE = 50;

export const usePotentialCollaboratorsPaginated = (searchQuery: string) => {
  const { data, error, isLoading, size, setSize, isValidating, mutate } =
    useSWRInfinite<GetPotentialCollaboratorsResponse>(
      (pageIndex, previousPageData) =>
        getKey(pageIndex, previousPageData, searchQuery),
      async () => {
        const params = {
          pageSize: PAGE_SIZE,
          page: size,
          search: encodeURIComponent(searchQuery),
          region: userCache.region,
          userAuth: userCache.getToken(),
        };

        const request = getPotentialCollaboratorsRequest(params);

        const response = await fetch(request);
        return await getPotentialCollaboratorsResponse(response);
      },
    );

  const members = useMemo(() => createEntitiesArray(data), [data]);
  const hasReachedLastPage =
    data &&
    (data.length === 0 ||
      (data[data.length - 1]?.data?.length ?? 0) < PAGE_SIZE);

  const isLoadingMoreData = isLoading || calculateIsLoadingMoreData(size, data);

  const loadMoreData = useCallback(async () => {
    if (isLoadingMoreData || hasReachedLastPage) return;

    await setSize((size) => size + 1);
  }, [hasReachedLastPage, isLoadingMoreData, setSize]);

  return {
    members,
    isLoadingInitialData: isLoading && members.length === 0,
    hasMoreSeriesToLoad: !isLoading && !hasReachedLastPage,
    loadMoreMembers: loadMoreData,
    isLoadingMore: isLoadingMoreData,
    error,
    mutate,
    isLoadingOrValidating: isLoading || isValidating,
    setSize,
  };
};

const getKey = (
  pageIndex: number,
  previousPageData: GetPotentialCollaboratorsResponse | null,
  searchQuery: string,
) => {
  if (previousPageData?.data && !previousPageData.data?.length) return null;

  return `/potential-collaborators$/${pageIndex}/${searchQuery}`;
};

const calculateIsLoadingMoreData = (
  page: number,
  data: GetPotentialCollaboratorsResponse[] | undefined,
) => {
  return page > 0 && Boolean(data && typeof data[page - 1] === 'undefined');
};

const createEntitiesArray = (
  data: GetPotentialCollaboratorsResponse[] | undefined,
): Entity[] => {
  return (
    data?.flatMap(({ data }: { data: Entity[] | undefined }) => {
      return data?.map((presentation) => presentation) || [];
    }) || []
  );
};
