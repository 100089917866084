import invariant from 'tiny-invariant';
import { useCompatibilitySeriesFetcher } from '@mentimeter/migration-utils';
import { createPresentationCollectionSeriesCompatibilityLayer } from '@mentimeter/compatibility/presentation-collection';
import type { SWRConfiguration } from 'swr';
import type { SeriesWithSlideDeck } from '@mentimeter/presentation-collection-schema/api-types-overrides';
import { getSeriesRequest } from '@core-api/presentation-collection/presentation-collection/series/{series_id}';
import { seriesCacheKey, useData } from '@mentimeter/core-hooks';
import { useCallback, useMemo } from 'react';

interface UsePresentationCollectionSeriesOptions {
  swrConfig?: SWRConfiguration;
  getSeriesCacheKey?: (seriesId: string) => string | Array<string>;
}

interface UsePresentationCollectionSlideDeckReturn {
  /**
   * **[Deprecated]** Use {@link UsePresentationCollectionSlideDeckReturn.slideDeck usePresentationCollectionSlideDeck().slideDeck} instead.
   * @deprecated
   */
  series: SeriesWithSlideDeck | undefined;
  slideDeck: SeriesWithSlideDeck | undefined;
  error: any;
  revalidate: () => Promise<{
    responseForBackwardCompat: SeriesWithSlideDeck | undefined;
  }>;
  /**
   * **[Deprecated]** Use {@link UsePresentationCollectionSlideDeckReturn.lazyGetSlideDeck usePresentationCollectionSlideDeck().lazyGetSlideDeck} instead.
   * @deprecated
   */
  lazyGetSeries: () => SeriesWithSlideDeck | undefined;
  lazyGetSlideDeck: () => SeriesWithSlideDeck | undefined;
}

export const usePresentationCollectionSlideDeck = (
  seriesId: string,
  options: UsePresentationCollectionSeriesOptions = {},
): UsePresentationCollectionSlideDeckReturn => {
  invariant(
    seriesId,
    'usePresentationCollectionSlideDeck(SWR): seriesId is required',
  );
  const getCacheKey = options?.getSeriesCacheKey ?? seriesCacheKey;
  const cacheKey = getCacheKey(seriesId);
  const shouldUseNewEndpoints = true;

  const fetcher = useCompatibilitySeriesFetcher<SeriesWithSlideDeck>(
    seriesId,
    getSeriesRequest,
    shouldUseNewEndpoints,
  );

  const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    focusThrottleInterval: 1000 * 60 * 2,
    ...options.swrConfig,
  };

  const { data, error, revalidate, lazyData } = useData<SeriesWithSlideDeck>(
    {
      cacheKey,
      seriesId,
      fetcher,
    },
    swrConfig,
  );

  const wrappedLazyData = useCallback(() => {
    const slideDeck = lazyData();
    return createPresentationCollectionSeriesCompatibilityLayer(slideDeck);
  }, [lazyData]);

  return useMemo(() => {
    const slideDeck =
      createPresentationCollectionSeriesCompatibilityLayer(data);
    return {
      slideDeck,
      series: slideDeck,
      error,
      revalidate,
      lazyGetSlideDeck: wrappedLazyData,
      lazyGetSeries: wrappedLazyData,
    };
  }, [data, error, revalidate, wrappedLazyData]);
};
