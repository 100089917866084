import { cva } from '@mentimeter/ragnar-tailwind-config';

export const toggleLabel = cva(
  ['block', 'cursor-pointer', 'has-[:disabled]:cursor-not-allowed'],
  {
    variants: {
      size: {
        default: 'w-[48px]',
        compact: 'w-[38px]',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);
