import { DuplicateIcon } from '@mentimeter/ragnar-visuals';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useToast } from '@mentimeter/toast';
import { useSeriesCache } from 'features/dashboard/use-series-cache';
import { TRACKING_PLACEMENT } from 'features/dashboard/presentation-view/constants';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import { postDuplicateRequest } from '@core-api/presentation-collection/presentation-collection/{slide_deck_public_key}/duplicate';
import userCache from '@mentimeter/user';
import { trackUser } from '@api/tracking/client';
import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import { useContextMenuContext } from '../useContextMenuContext';
import { ContextMenu } from '../ContextMenu';

export function Duplicate({ item }: { item: SlideDeckOverview }) {
  const { user, trackingContext } = useContextMenuContext();
  const { data: workspace } = useWorkspace();
  const { subscriptions } = useSubscriptions();
  const { planKey } = billingRules(subscriptions);
  const toast = useToast();
  const { invalidate: invalidateSeriesCache } = useSeriesCache();

  const isWorkspacePresentation = item.workspaceId !== null;

  const handleClick = async () => {
    try {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      await fetch(
        postDuplicateRequest({
          slideDeckPublicKey: item.slideDeckPublicKey,
          region,
          userAuth: sessionToken,
        }),
      );

      trackUser({
        event: 'Duplicated presentations',
        properties: {
          context: trackingContext,
          placement: TRACKING_PLACEMENT.ACTION_MENU,
          'shared presentation': isWorkspacePresentation,
          'presentation owner': item.ownerId === user?.id,
          'active team members': workspace?.memberStats?.active || 0,
          'current plan': planKey || user?.features.type || 'free',
          'role in workspace': user?.role || 'user',
        },
      });

      await invalidateSeriesCache();

      toast.displayToast({
        description: `Successfully duplicated ${item.name}`,
        autoDismiss: true,
      });
    } catch {
      toast.displayToast({
        description: `Could not duplicate "${item.name}"`,
        autoDismiss: false,
        variant: 'negative',
      });
    }
  };

  return (
    <ContextMenu.Item onSelect={handleClick}>
      <ContextMenu.ItemIcon>
        <DuplicateIcon />
      </ContextMenu.ItemIcon>
      <ContextMenu.ItemLabel color="text">Duplicate</ContextMenu.ItemLabel>
    </ContextMenu.Item>
  );
}
