import React from 'react';
import {
  type InputAccessibilityT,
  type CommonAccessibilityT,
  type LabelAccessibilityT,
  filterValidProps,
} from '@mentimeter/ragnar-styled';

interface ToggleSwitch
  extends CommonAccessibilityT,
    LabelAccessibilityT,
    InputAccessibilityT {
  checked?: boolean | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
}

export const ToggleSwitch = ({ id, checked, ...rest }: ToggleSwitch) => {
  return (
    <input
      id={id}
      type="checkbox"
      checked={Boolean(checked)}
      {...filterValidProps(rest)}
      className="peer absolute opacity-0 h-0 w-0"
    />
  );
};
