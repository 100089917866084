import type { Series } from '@mentimeter/http-clients';
import type {
  SeriesWithSlideDeck as PresentationCollectionSeriesWithSlideDeck,
  SlideDeck as PresentationCollectionSlideDeck,
} from '@mentimeter/presentation-collection-schema/api-types-overrides';
import { baseSeriesCompatibilityLayerHandler } from '../../../common/base-compatibility-layer-handler';
import { propertyToSeriesProperty as getMigratedSeriesProperty } from '../../../../backward-compatibility/converters/slide-deck/property-to-series-property';
import { isProxyInstance } from '../../../../utils/is-proxy-instance';
import {
  getOrSetCache,
  type WithCache,
} from '../../../../utils/get-or-set-cache';
import { createPresentationCollectionSlideCompatibilityLayer } from '../slide-compatibility-layer';

export const createPresentationCollectionSeriesCompatibilityLayer = (
  target: Series | PresentationCollectionSlideDeck | undefined,
): PresentationCollectionSeriesWithSlideDeck | undefined => {
  if (!target) {
    return undefined;
  }

  if (isProxyInstance(target)) {
    return target as PresentationCollectionSeriesWithSlideDeck;
  }

  return new Proxy(
    target,
    baseSeriesCompatibilityLayerHandler<PresentationCollectionSlideDeck>({
      getMigratedSeriesProperty:
        getMigratedPresentationCollectionSeriesProperty,
    }),
  ) as PresentationCollectionSeriesWithSlideDeck;
};

const getMigratedPresentationCollectionSeriesProperty = (
  target: PresentationCollectionSlideDeck,
  key: keyof Series | 'slides',
) => {
  switch (key) {
    case 'owner_id':
      return target.ownershipSettings?.ownerId;

    case 'results_sharing':
      return target.ownershipSettings?.resultsSharing;

    case 'slides':
      const slides = target.slides.map((slide) => {
        return createPresentationCollectionSlideCompatibilityLayer(slide);
      });
      const cacheKey = JSON.stringify(slides);
      return getOrSetCache(
        target as WithCache<PresentationCollectionSlideDeck>,
        'slides',
        cacheKey,
        slides,
      );

    case 'questions': {
      const questions = target.slides.map((slide) => {
        return createPresentationCollectionSlideCompatibilityLayer(slide);
      });

      const cacheKey = JSON.stringify(questions);
      return getOrSetCache(
        target as WithCache<PresentationCollectionSlideDeck>,
        'questions',
        cacheKey,
        questions,
      );
    }

    default:
      return getMigratedSeriesProperty(target, key);
  }
};
