/* eslint-disable menti-react/filename-convention--jsx */
import React, { type CSSProperties } from 'react';
import * as Yup from 'yup';
import { type AccessType, type Collaborator } from '@mentimeter/http-clients';
import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { getNameInitials } from '@mentimeter/user';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { InviteItem } from './AddCollaborators';
import type { UserCollaborationRole } from './CollaborationFolderDataHandler';

const emailValidationSchema = Yup.string().matches(/[^@]+@[^@]+\..+/);

export const isValidEmail = (email: string) => {
  try {
    emailValidationSchema.validateSync(email);
    return true;
  } catch (e) {
    return false;
  }
};

const AvatarSmall = ({ item }: { item: InviteItem }) => (
  <Avatar
    itemId={item.value}
    type={item.status === 'pending' ? 'pending' : 'user'}
    initials={getNameInitials(item.label || item.value, true)}
    name={item.label || item.value}
    fontSize="62.5"
    width="1.25rem"
    height="1.25rem"
    borderWidth="1px"
    borderStyle="solid"
    borderColor="bg"
    profilePictureUrl={item.profilePictureUrl}
  />
);

export const ListItem = ({
  item,
  style,
}: {
  item: InviteItem;
  style?: CSSProperties;
}) => (
  <Box
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    px={2}
    width="100%"
    style={style}
  >
    <AvatarSmall item={item} />
    <Text ml={2} color="textWeak" fontSize={1} truncate>
      {item.label ? item.label : item.value}
    </Text>
    {item.label && (
      <>
        <Box
          borderLeftWidth={1}
          borderStyle="solid"
          borderColor="borderWeak"
          height="12px"
          mx={2}
        />
        <Text color="meta" fontSize={1} truncate>
          {item.value}
        </Text>
      </>
    )}
  </Box>
);

export const getCanSeeCollaboratorsList = (
  userCollaborationRole: UserCollaborationRole,
  collaborationRights: string | undefined,
  hasCollaborationEnabled: boolean,
) => {
  return Boolean(
    collaborationRights && hasCollaborationEnabled && userCollaborationRole,
  );
};

export const getHasCollaborationRightAnyone = (
  collaborationRights: string | undefined,
  allowTeamVisibility: boolean,
) => {
  const hasWorkspaceRights = collaborationRights === 'workspace_members';
  const hasAnyoneRights = collaborationRights === 'anyone';
  return (hasWorkspaceRights && !allowTeamVisibility) || hasAnyoneRights;
};

export const CROSS_REGION_ERROR =
  'Invitation could not be sent. Collaboration is not available between different regions.';

export const isFolderCollaboratorOrInvitee = (
  collaborator: Collaborator | SeriesCollaborator,
) =>
  collaborator.type === 'folder-collaborator' ||
  collaborator.type === 'folder-invitee';

export const hasViewPermission = (
  access: AccessType,
  userInListIsItemOwner: boolean,
) => {
  return access !== 'edit' && !userInListIsItemOwner;
};

export const ACCESS_TOOLTIP_DESCRIPTION = `To edit Mentis in this folder, they need to upgrade.`;
