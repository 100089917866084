import type { SlideDeckOverview } from '@core-api/presentation-collection/types/response';
import type { GalleryFolder } from 'lib/types';
import type { TrackingContext } from '@mentimeter/http-clients';
import type {
  SortOptionsT,
  TableEventPropsT,
  TableStateT,
} from '@mentimeter/table';
import type React from 'react';

export interface SortKeys {
  updatedAt: 'updatedAt';
  createdAt: 'createdAt';
  name: 'name';
  ownerDisplayName: 'ownerDisplayName';
}

export type SortKey = keyof SortKeys;
export type GalleryItem = SlideDeckOverview | GalleryFolder;

export function isGalleryFolder(item: GalleryItem): item is GalleryFolder {
  const parentId = (item as GalleryFolder).parentId;
  return Boolean(parentId === null || Number(parentId));
}

export function isSlideDeck(item: GalleryItem): item is SlideDeckOverview {
  return Boolean((item as SlideDeckOverview).slideDeckPublicKey);
}

export type TableTrackingContextT =
  | TrackingContext.MyPresentations
  | TrackingContext.WorkspacePresentations
  | TrackingContext.SharedWithMe;

export enum DashboardTypeEnum {
  PRIVATE = 'private',
  WORKSPACE = 'workspace',
  SHARED_WITH_ME = 'sharedWithMe',
}

export interface TablePropsT extends TableEventPropsT {
  tableState: TableStateT<GalleryItem>;
  isWaitingForData: boolean;
  enableBulkActions?: boolean | undefined;
  dashboardType: DashboardTypeEnum;
  totalPresentations: number | undefined;
  trackingContext: TableTrackingContextT;
  sortBy?: SortKey | undefined;
  onSort?: ((sortOptions: SortOptionsT<GalleryItem>) => void) | undefined;
  presentationContextMenu: (item: SlideDeckOverview) => React.ReactNode;
  folderContextMenu: (item: GalleryFolder) => React.ReactNode;
}
