import * as React from 'react';
import { SelectItem, type SelectItemT } from '../select-item';
import { Label } from '../../label';
import { Tooltip, TriggerDescriptive } from '../../tooltip';

export interface SelectT extends SelectItemT {
  label?: string;
  description?: string;
  id: string;
  children?: React.ReactNode;
  options: Array<{
    key: string;
    value: string;
    label: string;
    disabled?: boolean;
  }>;
}

export const Select = ({
  label,
  description,
  id,
  name,
  options,
  ...rest
}: SelectT) => {
  return (
    <>
      {label && (
        <div className="flex flex-row items-start mb-2">
          <Label htmlFor={id} className="mb-0">
            {label}
          </Label>
          {description && (
            <Tooltip
              id={`${id}-label-description`}
              trigger={<TriggerDescriptive />}
            >
              {description}
            </Tooltip>
          )}
        </div>
      )}
      <SelectItem name={name} id={id} {...rest}>
        {options.map((o) => (
          <SelectItem.Option key={o.key} value={o.value} disabled={o.disabled}>
            {o.label}
          </SelectItem.Option>
        ))}
      </SelectItem>
    </>
  );
};
