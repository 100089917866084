import React from 'react';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';

export type Source = 'homeview' | 'editor';

export const CollaborationNotification = ({
  collaborationRights,
  isItemOwner,
  isFromResultsPage = false,
}: {
  collaborationRights: string;
  isItemOwner: boolean;
  isFromResultsPage?: boolean;
}) => {
  const isItemOwnerWithNoCollaboration =
    collaborationRights === 'no_one' && isItemOwner;
  const isItemOwnerAndWorkspaceMember =
    collaborationRights === 'workspace_members' && isItemOwner;

  if (isItemOwnerWithNoCollaboration) {
    return (
      <CalloutCard
        title="You can't invite right now"
        description="Inviting others isn't possible right now. Contact your plan owner for more information."
        my={3}
      />
    );
  } else if (isItemOwnerAndWorkspaceMember) {
    return (
      <CalloutCard
        title="Add collaborators from your workspace"
        description="Collaboration is currently only available for members of your workspace. To add more people to your workspace, contact your subscription owner for help."
        my={3}
      />
    );
  }
  // Place this as the last case we don't want to render this if other notifications are present.
  else if (isFromResultsPage && isItemOwner) {
    return (
      <CalloutCard
        description="Invite anyone with a Pro or Enterprise plan to manage results and edit the original Menti."
        my={3}
      />
    );
  } else {
    return null;
  }
};
