import type { PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Markdown } from '@mentimeter/ragnar-ui/markdown';
import type { CalloutCardT } from '@mentimeter/ragnar-ui/callout-card';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { ShareModalText } from './ShareModalText';
import { ShareModalTitle } from './ShareModalTitle';

export function ShareModalNotification({
  title,
  description,
  children,
  ...rest
}: PropsWithChildren<CalloutCardT>) {
  return (
    <CalloutCard theme="brand" as="article" {...rest}>
      <Box gap={2}>
        {!!title && <ShareModalTitle mb={0}>{title}</ShareModalTitle>}
        {!!description && (
          <ShareModalText>
            <Markdown>{description}</Markdown>
          </ShareModalText>
        )}
        {children}
      </Box>
    </CalloutCard>
  );
}
