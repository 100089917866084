import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';
import { ACCESS_TOOLTIP_DESCRIPTION } from '../_utils';

export const DisabledAccessTooltip = ({
  referenceId,
}: {
  referenceId: string;
}) => {
  return (
    <TooltipOld
      referenceId={referenceId}
      placement="top"
      description={ACCESS_TOOLTIP_DESCRIPTION}
      forceOneLine={false}
      width={200}
    />
  );
};
