import {
  WorkspaceRoleEnum,
  type InviteContinue,
  type InviteLanding,
  type SendInvitationsResponseT,
  type TrackingContext,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { Link } from '@mentimeter/next-navigation';
import { AlertIcon } from '@mentimeter/ragnar-visuals';
import { LocalStorage } from '@mentimeter/storage';
import { useToast } from '@mentimeter/toast';
import { useUser } from '@mentimeter/user';
import {
  mutateWorkspace,
  useOrganizationMembersUnpaginated,
  useWorkspace,
} from '@mentimeter/workspace-data-hooks';
import { useState } from 'react';
import * as Yup from 'yup';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ChipsInput } from '@mentimeter/ragnar-ui/chips-input';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { Strong } from '@mentimeter/ragnar-ui/typography';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { ChipT } from '@mentimeter/ragnar-ui/chip';
import { useSubmitInvites } from '../use-submit-invites';
import { INVITE_SELECTED_EMAILS_KEY } from '../invite-for-admin-roles/InviteMembersNotifications';
import { hintErrorText } from '../hint-error-text';
import { handleErrorMessage } from '../_utils';
import { InviteToast, ToastContent } from '../InviteToast';
import type { ShareAndInvitePlacement } from './types';

export function AddTeamMemberDataHandler({
  seriesId,
  seriesName,
  seriesWorkspaceId,
  onSubmitInvites,
  onSubmitInvitesSuccess,
  onInviting,
  customizedLanding,
  customizedContinue,
  trackingContext,
  trackingPlacement,
}: {
  seriesId: string;
  seriesName: string;
  seriesWorkspaceId: number | null;
  onSubmitInvites: () => void;
  onSubmitInvitesSuccess: (workspaceId?: number) => void;
  onInviting?: (isInviting: boolean) => void;
  customizedLanding?: InviteLanding;
  customizedContinue?: InviteContinue;
  trackingContext: TrackingContext;
  trackingPlacement: ShareAndInvitePlacement;
}) {
  const { user } = useUser();
  const { data: workspace } = useWorkspace();

  const toast = useToast();

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [inviteSuccessful, setInviteSuccessful] = useState(false);
  const [inviteInput, setInviteInput] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<ChipT[]>([
    ...parsedLocalEmails(),
  ]);

  const { mutate: mutateWorkspaceMembers } = useOrganizationMembersUnpaginated({
    skip: !workspace,
  });
  const { submitInvites, isLoading } = useSubmitInvites({
    onError: handleSubmitInvitesError,
    onSuccess: handleSubmitInvitesSuccess,
  });

  const containsInvalidInput = selectedOptions.some((m) => !validateEmail(m));
  const showHintError = !!error || containsInvalidInput;
  const validEmails = selectedOptions
    .filter((v) => v.isValid)
    .map((s) => s.value);
  const isInviting = inviteInput.length > 0 || selectedOptions.length > 0;
  const isOutOfMemberLiteSeats =
    workspace &&
    workspace.activeSubscription &&
    workspace.availableFreeSeats <= 0;

  if (isOutOfMemberLiteSeats) {
    return (
      <MemberLiteLimitReached
        canInviteMembers={
          user?.role === WorkspaceRoleEnum.OWNER ||
          user?.role === WorkspaceRoleEnum.ADMIN
        }
      />
    );
  }

  return (
    <>
      <Strong color="textWeaker" mb={3}>
        Email
      </Strong>
      <Box
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        alignItems="baseline"
        width="100%"
      >
        <ChipsInput
          id="invite-members-input"
          data-testid="invite-members-input"
          placeholder="Anyone in your workspace can edit"
          transformOption={(invite) => {
            setError(null);
            return transformInviteInput(invite, validateEmail);
          }}
          setSelectedOptions={(selectedOptions) => {
            setSelectedOptions(selectedOptions);
            onInviting?.(selectedOptions.length > 0);
            setInviteInput('');
            setError(null);
          }}
          selectedOptions={selectedOptions}
          onChange={(event) => {
            const targetElement = event.target as HTMLInputElement;
            if (!targetElement) return;

            setError(null);
            setInviteInput(targetElement.value);
            onInviting?.(
              selectedOptions.length > 0 || targetElement.value.length > 0,
            );
          }}
          flex="1 1 auto"
          width="100%"
          mb={0}
          hintError={showHintError}
          hintErrorText={hintErrorText(containsInvalidInput, 0, error ?? '')}
        />
        <Button
          ml={[0, 2]}
          disabled={showHintError}
          variant="primary"
          width={['100%', 'auto']}
          data-testid="send-members-invitation-button"
          onClick={handleSubmitInvites}
          state={
            inviteSuccessful ? 'success' : isLoading ? 'loading' : undefined
          }
        >
          Add to workspace
        </Button>
      </Box>
      {message && (
        <CalloutCard data-testid="invite-notification" mt={2} theme="info">
          <Text>{message}</Text>
        </CalloutCard>
      )}

      {workspace && isInviting && (
        <CalloutCard data-testid="invite-notification" mt={3} theme="brand">
          <Box flexDirection="row">
            <Box mr={2} top="3px">
              <AlertIcon size={2} color="primary" />
            </Box>
            <Box flex="1 1 auto">
              <Text mb={2}>
                If you add someone to your workspace and share this:
              </Text>
              <Text ml={3}>
                <ul>
                  <li style={{ marginBottom: '4px' }}>
                    They&apos;ll gain access to this Menti and every other file
                    shared in your workspace
                  </li>
                  <li style={{ marginBottom: '4px' }}>
                    This Menti will be shared with everyone in your workspace
                  </li>
                </ul>
              </Text>
            </Box>
          </Box>
        </CalloutCard>
      )}

      {!workspace && isInviting && (
        <CalloutCard data-testid="invite-notification" mt={3} theme="brand">
          <Box flexDirection="row">
            <Box mr={2} top="3px">
              <AlertIcon size={2} color="primary" />
            </Box>
            <Box flex="1 1 auto">
              <Text mb={2}>By adding {getInviteBlurb(validEmails)}:</Text>
              <Text ml={3}>
                <ul>
                  <li style={{ marginBottom: '4px' }}>
                    You will create a team
                  </li>
                  <li style={{ marginBottom: '4px' }}>
                    They will be added to your team and this presentation
                  </li>
                </ul>
              </Text>
              <Text mt={1}>
                <Link underline={false} href="/plans" fontWeight="semiBold">
                  Upgrade to Pro
                </Link>{' '}
                to edit with others without adding them to your team.
              </Text>
            </Box>
          </Box>
        </CalloutCard>
      )}
    </>
  );

  async function handleSubmitInvites() {
    if (!user) return;
    if (selectedOptions.length === 0) {
      setError('Please fill in an email address');
      return;
    }

    const selectedEmails = selectedOptions.map((member) => member.value);

    submitInvites({
      workspaceId: user.workspaceId,
      selectedEmails,
      seriesId,
      customizedLanding,
      customizedContinue,
      role: user.isFreeUser
        ? WorkspaceRoleEnum.USER
        : WorkspaceRoleEnum.MEMBER_LITE,
    });

    trackSendInvitationButtonClick({
      checkedGroups: [],
      selectedEmails,
      activeTeamMembers: workspace?.memberStats?.active || 0,
      currentPlan: user.features.type,
      roleInWorkspace: user.role || WorkspaceRoleEnum.USER,
      trackingContext,
      trackingPlacement,
    });

    setSelectedOptions([]);
    setInviteInput('');

    onSubmitInvites();
  }

  async function handleSubmitInvitesSuccess(data: SendInvitationsResponseT) {
    const { existing_members, new_invites, resent_invites } = data;

    if (existing_members.length > 0) {
      setMessage(
        existing_members.length === 1 && existing_members[0]
          ? `${existing_members[0]} already has access to this presentation`
          : `${existing_members.length} of the invitees already has access to this presentation`,
      );
    } else {
      setMessage(null);
    }

    setInviteSuccessful(true);
    setTimeout(() => {
      setInviteSuccessful(false);
    }, 3000);

    onSubmitInvitesSuccess(workspace?.id);

    showInvitesSentToast(new_invites, resent_invites, existing_members);

    const isCurrentlyShared = Boolean(seriesWorkspaceId);

    if (!isCurrentlyShared) {
      showPresentationMovedToast(
        seriesName,
        '/app/workspace',
        'your workspace',
      );
    }

    await mutateWorkspace();
    await mutateWorkspaceMembers();
  }

  function handleSubmitInvitesError(error: any) {
    setError(
      handleErrorMessage({
        errorMessage: error?.response?.data?.message,
      }),
    );
    setMessage(null);
  }

  function transformInviteInput(
    invite: ChipT,
    validateEmail: (option: ChipT) => boolean,
  ): ChipT {
    return {
      ...invite,
      value: invite.value.toLowerCase(),
      isValid: validateEmail(invite),
    };
  }

  function showInvitesSentToast(
    newInvites: string[],
    resentInvites: string[],
    existingMembers: string[],
  ) {
    toast.displayToast({
      autoDismiss: true,
      description: (
        <InviteToast
          newInvites={newInvites}
          resentInvites={resentInvites}
          existingMembers={existingMembers}
        />
      ),
    });
  }

  function showPresentationMovedToast(
    seriesName: string,
    href: string,
    text: string,
  ) {
    toast.displayToast({
      description: (
        <ToastContent>
          {seriesName} was moved to{' '}
          <Link href={href} color="inherit">
            {text}
          </Link>
        </ToastContent>
      ),
      autoDismiss: true,
    });
  }
}

function MemberLiteLimitReached({
  canInviteMembers,
}: {
  canInviteMembers: boolean;
}) {
  return (
    <CalloutCard
      data-testid="invite-notification"
      theme="brand"
      title="Out of Member lite seats"
    >
      <Text>
        To invite more free members to this presentation and workspace, the
        owner of the workspace has to contact our sales team.
      </Text>
      {canInviteMembers && (
        <Box mt="space2">
          <Text>
            If you have available licenses you can invite members from the{' '}
            <Link href="/app/manage-members">Manage members page.</Link>
          </Text>
        </Box>
      )}
    </CalloutCard>
  );
}

function getInviteBlurb(emails: string[]) {
  if (emails.length === 0) return <Text>someone</Text>;
  if (emails.length === 1)
    return <Text fontWeight="semiBold">{emails[0]}</Text>;

  return <Text fontWeight="semiBold">these {emails.length} people</Text>;
}

function parsedLocalEmails() {
  const localEmails =
    LocalStorage.getJSONItem<string>(INVITE_SELECTED_EMAILS_KEY) || '';
  return localEmails ? JSON.parse(localEmails) : [];
}

const emailValidationSchema = Yup.string().email(
  'Please fill in an email address',
);

function validateEmail(option: ChipT) {
  try {
    emailValidationSchema.validateSync(option.value);
    return true;
  } catch (e) {
    return false;
  }
}

function trackSendInvitationButtonClick({
  checkedGroups,
  selectedEmails,
  activeTeamMembers,
  currentPlan,
  roleInWorkspace,
  trackingContext,
  trackingPlacement,
}: {
  checkedGroups: Array<string>;
  selectedEmails: Array<string>;
  trackingContext: TrackingContext;
  activeTeamMembers: number;
  currentPlan: string;
  roleInWorkspace: WorkspaceRole;
  trackingPlacement: ShareAndInvitePlacement;
}) {
  trackUser({
    event: 'Clicked send invitation',
    properties: {
      context: trackingContext,
      placement: trackingPlacement,
      'group ids': checkedGroups,
      'number of invited users': selectedEmails.length,
      'active team members': activeTeamMembers,
      'current plan': currentPlan,
      'role in workspace': roleInWorkspace,
    },
  });
}
