import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const toggleVariants = cva(['mb-1', 'gap-3', 'items-center'], {
  variants: {
    position: {
      left: 'flex-row-reverse',
      right: 'flex-row',
    },
  },
  defaultVariants: {
    position: 'left',
  },
});

export type ToggleVariantProps = VariantProps<typeof toggleVariants>;

export const toggleLabelVariants = cva(['mb-0', 'leading-none', 'text-weak'], {
  variants: {
    fontWeight: {
      regular: 'font-normal',
      semiBold: 'font-semibold',
    },
  },
  defaultVariants: {
    fontWeight: 'regular',
  },
});

export type ToggleLabelVariantProps = VariantProps<typeof toggleLabelVariants>;
