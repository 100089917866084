import { useMatch } from '@mentimeter/ragnar-device';
import { Toggle } from '@mentimeter/ragnar-ui/toggle';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ShareModalNotification } from '../../ShareModalNotification';
import { ShareModalText } from '../../ShareModalText';

interface VotingAccessSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export function VotingAccessSwitch({
  checked,
  onChange,
}: VotingAccessSwitchProps) {
  const isMobile = useMatch({ lessThan: 3 });
  return (
    <ShareModalNotification title="Enable participation">
      <Box gap={isMobile ? undefined : 4}>
        <Toggle
          id="share-modal-participation-toggle"
          name="share-modal-participation-toggle"
          position="right"
          checked={checked}
          onChange={() => onChange(!checked)}
          label={
            <ShareModalText>
              Anyone with the link, voting code, or QR code can join and
              interact with your Menti.
            </ShareModalText>
          }
        />
      </Box>
    </ShareModalNotification>
  );
}
