import type { Question } from '@mentimeter/http-clients';
import type { Slide as PresentationCollectionSlide } from '@mentimeter/presentation-collection-schema/api-types-overrides';
import { getCommonSlideProperty } from '../../../../forward-compatibility/get-common-slide-property';
import type { CompatibilitySlide } from '../../../../compatibility-types';

export const mapPresentationCollectionSlidePropertyToQuestionProperty = (
  question: Question,
  key: keyof PresentationCollectionSlide,
) => {
  switch (key) {
    default:
      return getCommonSlideProperty(question, key as keyof CompatibilitySlide);
  }
};
