import { type TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';

export const trackAddedCollaborators = (props: {
  user_emails: string[];
  context: TrackingContext;
  role: string | undefined;
  folder: boolean;
  plan: string;
  placement: string | undefined;
}) => {
  const { user_emails, context, role, folder, plan, placement } = props;
  trackUser({
    event: 'Successful invite to collaborate',
    properties: {
      placement,
      invitees: user_emails.map((email) => ({
        email,
        permissions: null, //determined by plan
      })),
      'skipped invitees': [], //legacy to match event
      'failed invitees': [], //legacy to match event
      context,
      role,
      folder,
      invited_to: folder ? 'folder' : 'presentation',
      'number invited': user_emails.length,
      'current plan': plan,
    },
  });
};
