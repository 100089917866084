import type {
  InviteContinue,
  InviteLanding,
  MemberT,
  TrackingContext,
} from '@mentimeter/http-clients';
import { Link, fullMentimeterUrl } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { useToast } from '@mentimeter/toast';
import { useState } from 'react';
import { IndividualCollaborationUpgradePrompt } from '@mentimeter/paywalls/prompts';
import type { SharedStatus } from '@mentimeter/workspace';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { ToastContent } from '../InviteToast';
import { AccessSettings } from './AccessSettings';
import { AddTeamMemberDataHandler } from './AddTeamMember';
import { ShareAndInviteLinkCopyButton } from './ShareAndInviteLinkCopyButton';
import type { ShareAndInvitePlacement } from './types';
import { ErrorFallback } from './components/ErrorFallback';

export interface ShareAndInviteProps {
  seriesId: string;
  seriesName: string;
  seriesWorkspaceId: number | null;
  seriesOwnerId: number;
  userId: number;
  userIsWorkspaceOwnerOrAdmin: boolean;
  userIsMemberLite: boolean;
  workspaceInfo: null | {
    name: string | null | undefined;
    ownerName: string | undefined;
    members: MemberT[];
  };

  onSharedChange?: (sharedStatus: SharedStatus) => Promise<void>;
  onInviteSuccess: (workspaceId?: number) => void;

  onRemoveMemberFromTeam: (memberId: string) => void;
  onManageMemberInvitation: (memberId: string) => void;
  onClickShareAndInviteCopyLink: () => void;

  customizedLanding?: InviteLanding;
  customizedContinue?: InviteContinue;
  compact?: boolean;
  trackingPlacement: ShareAndInvitePlacement;
  trackingContext: TrackingContext;
  upgradeTrackingEvent: 'Plans from homeview' | 'Plans from editor';
}

export function ShareAndInvite({
  seriesId,
  seriesName,
  seriesWorkspaceId,
  seriesOwnerId,
  userId,
  userIsWorkspaceOwnerOrAdmin,
  userIsMemberLite,
  workspaceInfo,

  // Determines the location the user will land on after clicking the link in the invitation email. Default is "/app".
  customizedLanding,

  // Determines where the user will be sent after accepting the invite on the landing location.
  customizedContinue,

  // Triggered when share status is updated using the team access select
  onSharedChange,

  // After invites has been sent and the series has been shared using the add button, useful for revalidating local state
  onInviteSuccess,

  onRemoveMemberFromTeam,
  onManageMemberInvitation,
  onClickShareAndInviteCopyLink,

  // Makes some UI adjustments to fit the content in a less wide space
  compact = false,

  trackingPlacement,
  trackingContext,
  upgradeTrackingEvent,
}: ShareAndInviteProps) {
  const [isInviting, setIsInviting] = useState(false);

  const toast = useToast();

  const isShared = !!seriesWorkspaceId;
  const showAddTeamMember = isShared;
  const hideBecauseInviting = isInviting;
  const showAccessSettings = !hideBecauseInviting;
  const canUseClipBoard =
    Boolean(navigator.clipboard?.writeText) && window.isSecureContext;

  return (
    <Box width="100%" flex="1 1 auto" data-testid="share-and-invite-content">
      <ErrorBoundary
        feature="share-invite-modal"
        errorMessage="Could not load share and invite modal content"
        fallback={<ErrorFallback />}
      >
        <Box gap={4} width="100%" mb={2} flex="1 1 auto">
          {showAccessSettings && (
            <Box width="100%" flex="1 1 auto">
              <AccessSettings
                userId={userId}
                seriesOwnerId={seriesOwnerId}
                isWorkspaceOwnerOrAdmin={userIsWorkspaceOwnerOrAdmin}
                members={workspaceInfo?.members ?? []}
                isShared={isShared}
                forceIsShared={isInviting}
                onSharedChange={onAccessSettingsSharedChange}
                onManageMemberInvitation={onManageMemberInvitation}
                onRemoveMemberFromTeam={onRemoveMemberFromTeam}
              />
            </Box>
          )}

          {showAddTeamMember && (
            <Box width="100%" data-testid="add-team-member-section">
              <AddTeamMemberDataHandler
                seriesId={seriesId}
                seriesWorkspaceId={seriesWorkspaceId}
                seriesName={seriesName}
                customizedLanding={customizedLanding}
                customizedContinue={customizedContinue}
                onInviting={setIsInviting}
                onSubmitInvites={() => {
                  setIsInviting(false);
                }}
                onSubmitInvitesSuccess={onInviteSuccess}
                trackingContext={trackingContext}
                trackingPlacement={trackingPlacement}
              />

              {workspaceInfo && !userIsMemberLite && (
                <IndividualCollaborationUpgradePrompt
                  trackingEvent={upgradeTrackingEvent}
                />
              )}
            </Box>
          )}
        </Box>
        {canUseClipBoard && (
          <ShareAndInviteCopyLinkSection
            onClick={onClickShareAndInviteCopyLink}
            link={fullMentimeterUrl(
              buildPresentationPath({
                seriesId,
                mode: 'edit',
                currentSearchParams: new URLSearchParams({
                  source: 'share-invite-modal',
                }),
              }),
            )}
          />
        )}
      </ErrorBoundary>
    </Box>
  );

  async function onAccessSettingsSharedChange(sharedStatus: SharedStatus) {
    await onSharedChange?.(sharedStatus);

    if (sharedStatus === 'shared') {
      showPresentationMovedToast(
        seriesName,
        '/app/workspace',
        'your workspace',
      );
    } else if (sharedStatus === 'private') {
      showPresentationMovedToast(
        seriesName,
        '/app/dashboard',
        'My presentations',
      );
    }
  }

  function showPresentationMovedToast(
    seriesName: string,
    href: string,
    text: string,
  ) {
    toast.displayToast({
      description: (
        <ToastContent>
          {seriesName} was moved to{' '}
          <Link href={href} color="inherit">
            {text}
          </Link>
        </ToastContent>
      ),
      autoDismiss: true,
      variant: 'positive',
    });
  }
}

function ShareAndInviteCopyLinkSection({
  link,
  onClick,
}: { link: string; onClick: () => void }) {
  return (
    <Box
      width="calc(100% + 64px)"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bg="bgStrong"
      borderBottomLeftRadius="2xl"
      borderBottomRightRadius="2xl"
      px={32}
      left={-32}
      py={16}
      top={32}
      mt={-16}
    >
      <Text fontWeight="semiBold">Anyone with the link can view</Text>
      <ShareAndInviteLinkCopyButton link={link} onClick={onClick} />
    </Box>
  );
}
