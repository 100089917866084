import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { styled, type Rule } from '@mentimeter/ragnar-styled';
import { toggleLabel } from './toggleLabel.variants';

interface ToggeLabelStyle {
  disabled: boolean;
  size?: 'default' | 'compact' | undefined;
}

const ToggleLabelStyle: Rule<ToggeLabelStyle> = () => ({});

const FelaToggleLabel = styled('label')(ToggleLabelStyle, 'kosmosSpacing');

export type FelaToggleT = React.ComponentProps<typeof FelaToggleLabel>;

export const ToggleLabel = React.forwardRef((props: FelaToggleT, ref) => {
  const { size, className, ...rest } = props;

  return (
    <FelaToggleLabel
      {...rest}
      ref={ref}
      className={cn('group', toggleLabel({ size }), className)}
    />
  );
});
