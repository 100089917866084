import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button, useButtonState } from '@mentimeter/ragnar-ui/button';

export const NEW_MENTI_COPY = 'New Menti';
export const CREATE_NEW_MENTI_EMPTY_STATE_COPY = `Move an existing Menti to this folder, or select "${NEW_MENTI_COPY}" to add a Menti right here.`;
export const CREATE_NEW_HREF = '/app/new';

export const CreateNewMentiButton = ({
  href,
  onClick,
  ...rest
}: {
  href: string;
  onClick: () => void;
} & ButtonT) => {
  const [buttonState, setButtonState] = useButtonState();

  return (
    <Button
      data-testid="new-presentation-button"
      href={href}
      state={buttonState}
      variant="primary"
      onClick={() => {
        setButtonState('loading');
        onClick();
      }}
      {...rest}
    >
      {NEW_MENTI_COPY}
    </Button>
  );
};
